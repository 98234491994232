import './crm.css';
import './app-style.css';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Dashboard from '../Pages/Dashboard';
import Login from '../Pages/Login';
import ForgotPassword from '../Pages/ForgotPassword';
import Projects from '../Pages/CRM/Projects';
import Attendance from '../Pages/CRM/Attendance';
import Process from '../Pages/CRM/Process';
import Subscription from '../Pages/CRM/Subscription';
import Invoices from '../Pages/CRM/Invoices';
import Leads from '../Pages/CRM/Leads';
import Orders from '../Pages/CRM/Orders';
import Prospects from '../Pages/CRM/Prospects';
import Quotes from '../Pages/CRM/Quotes';
import Recovery from '../Pages/CRM/Recovery';
import Ganttchart from '../Pages/CRM/Ganttchart';
import Support from '../Pages/CRM/Support';
import Supportadd from '../Pages/CRM/Supportadd';
import Notification from '../Pages/CRM/Notification';
import Tasks from '../Pages/CRM/Tasks';
import Accounts from '../Pages/ERP/Accounts';
import User from '../Pages/Master/User';
import Product from '../Pages/Master/Product';
import Type from '../Pages/Master/Type';
import Customer from '../Pages/Master/Customer';
import Company from '../Pages/Master/Company';
import Branch from '../Pages/Master/Branch';
import ApplicationSetting from '../Pages/Master/ApplicationSetting';
import Customeraddress from '../Pages/Master/Customeraddress';
import Country from '../Pages/Master/Country';
import State from '../Pages/Master/State';
import City from '../Pages/Master/City';
import Icon from '../Pages/Master/Icon';
import Source from '../Pages/Master/Source';
import Taskboard from '../Pages/CRM/Taskboard';
import Unit from '../Pages/Master/Unit';
import QuatationName from '../Pages/Master/QuatationName';
import Role from '../Pages/Master/Role';
import Status from '../Pages/Master/Status';
import Category from '../Pages/Master/Category';
import SubCategory from '../Pages/Master/SubCategory';
import TaskType from '../Pages/Master/TaskType';
import MailAddress from '../Pages/Master/MailAddress';
import RolesResponsibility from '../Pages/Master/RolesResponsibility';
import Template from '../Pages/Master/Template';
import Module from '../Pages/Master/Module';
// import { ThemeCustomizerTypes } from "./ThemeCustomizer/ThemeCustomizerTypes";
import Loader from "./Loader";
import Taptop from "./Taptop";
import SideBar from "./SideBar-Layout/SideBar";
import Customizer from "./ThemeCustomizer";
import FooterLayout from "./Footer";
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import Header from './Header';
const DefaultLayout = () => {
  const islogin = JSON.parse(window.localStorage.getItem("Token"));
  const { sidebar_types } = useSelector((state) => state.ThemeCustomizer);
  // const sidebar_types = ''; 
  return (
    <>
      {islogin && <>
        <div>
          <BrowserRouter>
            <Loader />
            <Taptop />
            <div className={`page-wrapper ${sidebar_types}`}>
              <Header />
              <div className={`page-body-wrapper ${sidebar_types === "compact-wrapper" ? "sidebar-icon" : "horizontal-menu"}`}>
                <SideBar />
                <div>
                  <div className='page-body'>
                    <div className='pt-5 pt-sm-0'>
                      <Routes>
                        <Route path="/" exact element={<Dashboard />} />
                        <Route path="/Dashboard" exact element={<Dashboard />} />
                        <Route path="/Projects" exact element={<Projects />} />
                        <Route path="/Subscription" exact element={<Subscription />} />
                        <Route path="/Process/:id" exact element={<Process />} />
                        <Route path="/Invoices" exact element={<Invoices />} />
                        <Route path="/Leads" exact element={<Leads />} />
                        <Route path="/Orders" exact element={<Orders />} />
                        <Route path="/Attendance" exact element={<Attendance />} />
                        <Route path="/Prospects" exact element={<Prospects />} />
                        <Route path="/Quotes" exact element={<Quotes />} />
                        <Route path="/Recovery" exact element={<Recovery />} />
                        <Route path="/Notification" exact element={<Notification />} />
                        <Route path="/Support" exact element={<Support />} />
                        <Route path="/Supportadd/:id" exact element={<Supportadd />} />
                        <Route path="/Ganttchart/:id" exact element={<Ganttchart />} />
                        <Route path="/Accounts" exact element={<Accounts />} />
                        <Route path="/Tasks" exact element={<Tasks />} />
                        <Route path="/User" exact element={<User />} />
                        <Route path="/Product" exact element={<Product />} />
                        <Route path="/Type" exact element={<Type />} />
                        <Route path="/Company" exact element={<Company />} />
                        <Route path="/Branch" exact element={<Branch />} />
                        <Route path="/TaskType" exact element={<TaskType />} />
                        <Route path="/Customer" exact element={<Customer />} />
                        <Route path="/Customeraddress/:id" exact element={<Customeraddress />} />
                        <Route path="/Source" exact element={<Source />} />
                        <Route path="/Icon" exact element={<Icon />} />
                        <Route path="/Country" exact element={<Country />} />
                        <Route path="/State" exact element={<State />} />
                        <Route path="/City" exact element={<City />} />
                        <Route path="/Taskboard" exact element={<Taskboard />} />
                        <Route path="/ApplicationSetting" exact element={<ApplicationSetting />} />
                        <Route path="/Unit" exact element={<Unit />} />
                        <Route path="/QuatationName" exact element={<QuatationName />} />
                        <Route path="/Role" exact element={<Role />} />
                        <Route path="/Status" exact element={<Status />} />
                        <Route path="/Category" exact element={<Category />} />
                        <Route path="/MailAddress" exact element={<MailAddress />} />
                        <Route path="/SubCategory" exact element={<SubCategory />} />
                        <Route path="/RolesResponsibility" exact element={<RolesResponsibility />} />
                        <Route path="/Template" exact element={<Template />} />
                        <Route path="/Module" exact element={<Module />} />
                      </Routes>
                    </div>
                  </div>
                </div>
                
              </div>

              <Customizer />
              <FooterLayout />

            </div>
            <ToastContainer />
          </BrowserRouter>
        </div>
      </>}
      {!islogin && <>
        <div>
          <BrowserRouter>
            <Routes>
              <Route path="/" exact element={<Login />} />
              <Route path="/ForgotPassword" exact element={<ForgotPassword />} />
              <Route path="/Login" exact element={<Login />} />
            </Routes>
          </BrowserRouter>
        </div>
      </>}
    </>

  );
}

export default DefaultLayout;
