import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { Post, Get } from '../../Layout/ajax';
import { CModal } from '@coreui/react';
import * as Yup from "yup";
import Swal from "sweetalert2";
import { immediateToast } from "izitoast-react";
import { Link } from 'react-router-dom';
import { CompanyModal, BranchModal } from '../CRM/Modal';

const validation = Yup.object().shape({
    company: Yup.string().required(),
    branch: Yup.string().required(),
    // title: Yup.string().required(),
    firstname: Yup.string().required(),
    // lastname: Yup.string().required(),
    // designation: Yup.string().required(),
    email: Yup.string().email(),
    mobile: Yup.string().nullable().max(10).min(10).matches(/^[+]?[0-9]+$/),
    // notes: Yup.string().required(),
});
const Customer = () => {
    const [data, setData] = useState([]);
    const [customeradd, setcustomeradd] = useState(false);
    const [Branch, setBranch] = useState([]);
    const [Company, setCompany] = useState([]);
    const [customer, setcustomer] = useState(0);
    const [Manual, setManual] = useState(false);
    const [lastCustomerCode, setLastCustomerCode] = useState('');
    const [branchadd, setbranchadd] = useState(false);
    const [branchval, setbranchval] = useState(0);
    const [companyadd, setcompanyadd] = useState(false);
    const [companyval, setcompanyval] = useState(0);
    const showTost = (msg, theme) => {
        immediateToast(theme, {
            message: msg,
            timeout: 3000
        });
    }
    useEffect(() => {
        getpost(true);
        Get(`master/getApplicationSetting`)
            .then((res) => {
                setManual(res.data.Customer)
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
        getcompanypost();
        getbranchpost();
    }, []);

    const getcompanypost = async (val) => {
        Post(`customer/getCompany`, { active: true })
            .then((res) => {
                setCompany(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const getbranchpost = async (val) => {
        Post(`customer/getBranchs`, { active: true })
            .then((res) => {
                setBranch(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const getpost = async (val) => {
        await Post(`customer/getAllCustomer`, { active: val })
            .then((res) => {
                setData(res.data);
                setLastCustomerCode(res.lastCustomerCode?.CustomerCode)
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const [initValue, setinitValue] = useState({
        company: "",
        branch: "",
        title: "",
        firstname: "",
        lastname: "",
        designation: "",
        CustomerCode: "",
        mobile: "",
        email: "",
        notes: "",
    });
    const clear = () => {
        setinitValue({
            company: "",
            branch: "",
            title: "",
            firstname: "",
            lastname: "",
            designation: "",
            CustomerCode: "",
            mobile: "",
            email: "",
            notes: "",
        });
    }
    const get = async (val) => {
        if (val != 0) {
            Get(`customer/getCustomerById/${val}`)
                .then((res) => {
                    setinitValue({
                        company: res.data[0].Company?._id,
                        branch: res.data[0].Branch?._id,
                        title: res.data[0].Title,
                        firstname: res.data[0].FirstName,
                        lastname: res.data[0].LastName,
                        designation: res.data[0].Designation,
                        CustomerCode: res.data[0].CustomerCode,
                        mobile: res.data[0].Mobile,
                        email: res.data[0].Email,
                        notes: res.data[0].Notes,
                    });
                    setcustomeradd(true);
                    setcustomer(val);
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
    }
    const handleSubmit = async (val) => {
        await Post(`customer/addCustomer`, val)
            .then((res) => {
                if (res.success == false) {
                    showTost(res.msg, "warning");
                }
                else {
                    clear();
                    setcustomeradd(false);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            getpost(true);
                        }
                    });
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const handleEdit = async (val) => {
        val.id = customer;
        await Post(`customer/editCustomer`, val)
            .then((res) => {
                if (res.success == false) {
                    showTost(res.msg, "warning");
                }
                else {
                    clear();
                    setcustomeradd(false);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            getpost(true);
                        }
                    });
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const changeStatus = async (val, status) => {
        await Post(`customer/removeCustomer/${val}`, { active: status })
            .then((res) => {
                if (res.success == true) {
                    if (status == true) {
                        getpost(false);
                        showTost("Your data has been successfully activated", "success");
                    }
                    else {
                        getpost(true);
                        showTost("Your data has been successfully deactivated", "success");
                    }
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const columns = [
        {
            name: "Company",
            selector: (row) => row.Company?.Company,
        },
        {
            name: "Branch",
            selector: (row) => row.Branch?.Name,
        },
        {
            name: "Name",
            selector: (row) => <>{row.FirstName} {row.LastName}</>,
        },
        {
            name: "Mobile",
            selector: (row) => row.Mobile,
        },
        {
            name: "Email",
            selector: (row) => row.Email,
        },
        {
            name: "Status",
            selector: (row) => <input type='checkbox' value={row.is_active} checked={row.is_active == true ? "checked" : ""} onClick={() => { row.is_active == true ? changeStatus(row._id, false) : changeStatus(row._id, true) }} />,
        },
        {
            name: "",
            selector: (row) => <Link className='text-success' to={`/Customeraddress/${row._id}`}>View Addess</Link>,
        },
        {
            name: "",
            selector: (row) => row.is_active == true ? <i className='btn btn-sm btn-success fa fa-pencil' onClick={() => { get(row._id); }} /> : "",
        },
    ];

    return (
        <div className='content-wrapper'>

            <div className="mb-2 mt-2 row ">
                <div className="col-10">
                    <h2>Customer</h2>
                </div>
                <div className="col-2 mt-2">
                    <span className='btn btn-sm btn-warning float-right' onClick={() => { setcustomeradd(true); setcustomer(0); }}>
                        <i className="fa fa-plus mr-2" />
                        Add Customer
                    </span>
                </div>
                <div className="col-12">
                    <div className="custom-control custom-switch float-right">
                        <input type="checkbox" className="custom-control-input" defaultChecked id="customSwitch1" data-check_true={true} data-check_false={false} onChange={(event) => {
                            let ele = event.target;
                            let selectedId = ele.checked ? event.target.getAttribute("data-check_true") : event.target.getAttribute("data-check_false");
                            getpost(selectedId)
                        }} />
                        <label className="custom-control-label" for="customSwitch1"> Active/Inactive </label>
                    </div>
                </div>
            </div>
            <div className="row ">
                <div className="col-12 mt-3">
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                    />
                </div>
            </div>
            <CModal alignment="center" visible={customeradd} size='lg'>
                <div className="modal-header">
                    <h4 className="modal-title">{customer == 0 ? "Add Customer" : "Update Customer"}</h4>
                    <button type="button" className="close" onClick={() => { clear(); setcustomeradd(false); }}>&times;</button>
                </div>
                <div className="modal-body">
                    <Formik
                        onSubmit={customer == 0 ? handleSubmit : handleEdit}
                        initialValues={initValue}
                        validationSchema={validation}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            touched,
                            errors,
                            setFieldValue,
                        }) => {
                            return (
                                <Form
                                    onSubmit={handleSubmit}
                                    onChange={handleChange}>
                                    <div className='row'>
                                        {Manual == true ?
                                            <><div className="col-6 mb-2">
                                                <label>Customer Code</label>
                                                <input type="text" placeholder="Customer Code" value={values.CustomerCode} defaultValue={values.CustomerCode} name='CustomerCode' className={!!touched.CustomerCode && !!errors.CustomerCode ? "form-control is-invalid" : "form-control"} />
                                                {customer == 0 ? <div>
                                                    <span>Last Customer Code:</span>
                                                    <span className='fw-bold ms-1'>
                                                        {lastCustomerCode}
                                                    </span>
                                                </div> : ""}
                                            </div> <div className='col-6 mb-2'></div></> : ""}
                                        <div className="col-6 mb-2">
                                            <label><i className="fa fa-building-o text-danger mr-2" />Company</label>
                                            <div className='input-group'>
                                                <select disabled={customer == 0 ? false : true} defaultValue={values.company} className={!!touched.company && !!errors.company ? "form-control is-select" : "form-select"} name='company'>
                                                    <option>Select Company</option>
                                                    {
                                                        Company.map((x, i) => (
                                                            <option key={i} value={x._id}>{x.Company}</option>
                                                        ))
                                                    }
                                                </select>
                                                <span className="input-group-text">
                                                    {values.company ? (
                                                        <i className='fa fa-pencil' onClick={() => { setcompanyval(values.company); setcompanyadd(true); }} />
                                                    ) : (
                                                        <i className='fa fa-plus' onClick={() => { setcompanyval(0); setcompanyadd(true); }} />
                                                    )}
                                                    {companyadd && (
                                                        <CompanyModal
                                                            companyadd={companyadd}
                                                            setcompanyadd={setcompanyadd}
                                                            getcompanypost={getcompanypost}
                                                            showTost={showTost}
                                                            companyval={companyval}
                                                            setcompanyval={setcompanyval}
                                                        />
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-6 mb-2">
                                            <label><i className="fa fa-building-o text-danger mr-2" />Branch</label>
                                            <div className='input-group'>
                                                <select defaultValue={values.branch} className={!!touched.branch && !!errors.branch ? "form-control is-select" : "form-select"} name='branch'>
                                                    <option>Select Branch</option>
                                                    {
                                                        Branch.filter((x) => x.Company?._id == values.company).map((x, i) => (
                                                            <option key={i} value={x._id}>{x.Name}</option>
                                                        ))
                                                    }
                                                </select>
                                                <span className="input-group-text">
                                                    {values.branch ? (
                                                        <i className='fa fa-pencil' onClick={() => { setbranchval(values.branch); setbranchadd(true); }} />
                                                    ) : (
                                                        <i className='fa fa-plus' onClick={() => { setbranchval(0); setbranchadd(true); }} />
                                                    )}
                                                    {branchadd && (
                                                        <BranchModal
                                                            branchadd={branchadd}
                                                            setbranchadd={setbranchadd}
                                                            getbranchpost ={getbranchpost }
                                                            showTost={showTost}
                                                            branchval={branchval}
                                                            setbranchval={setbranchval}
                                                        />
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                        <div className='col-6 mb-2'>
                                            <label>Title</label>
                                            <select defaultValue={values.title} className={!!touched.title && !!errors.title ? "form-select is-invalid" : "form-select"} name='title'>
                                                <option value="">Select</option>
                                                <option value="Mr.">Mr.</option>
                                                <option value="Mrs.">Mrs.</option>
                                                <option value="Ms.">Ms.</option>
                                            </select>
                                        </div>
                                        <div className='col-6 mb-2'>
                                            <label>First Name</label>
                                            <input type='text' name="firstname" defaultValue={values.firstname} placeholder='Enter Firstname' className={!!touched.firstname && !!errors.firstname ? "form-control is-invalid" : "form-control"} />
                                        </div>
                                        <div className='col-6 mb-2'>
                                            <label>Last Name</label>
                                            <input type='text' name="lastname" defaultValue={values.lastname} placeholder='Enter Lastname' className={!!touched.lastname && !!errors.lastname ? "form-control is-invalid" : "form-control"} />
                                        </div>
                                        <div className='col-6 mb-2'>
                                            <label>Designation</label>
                                            <input type='text' name="designation" defaultValue={values.designation} placeholder='Enter Designation' className={!!touched.designation && !!errors.designation ? "form-control is-invalid" : "form-control"} />
                                        </div>
                                        <div className='col-6 mb-2'>
                                            <label>Mobile Number</label>
                                            <input type='text' name="mobile" defaultValue={values.mobile} placeholder='Enter Mobile' className={!!touched.mobile && !!errors.mobile ? "form-control is-invalid" : "form-control"} />
                                        </div>
                                        <div className='col-6 mb-2'>
                                            <label>Email</label>
                                            <input type='email' name="email" defaultValue={values.email} placeholder='Enter Email' className={!!touched.email && !!errors.email ? "form-control is-invalid" : "form-control"} />
                                        </div>
                                        <div className='col-12 mb-2'>
                                            <label>Notes</label>
                                            <textarea type='text' rows="3" name="notes" defaultValue={values.notes} placeholder='Enter Notes' className={!!touched.notes && !!errors.notes ? "form-control is-invalid" : "form-control"} />
                                        </div>
                                        <div className='col-12'>
                                            <button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </CModal>
        </div >
    );
}

export default Customer;