import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { Post, Get } from '../../Layout/ajax';
import { CModal } from '@coreui/react';
import { immediateToast } from "izitoast-react";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { CountryModal, StateModal, CityModal, CompanyModal } from '../CRM/Modal';

const validation = Yup.object().shape({
    company: Yup.string().required(),
    name: Yup.string().required(),
});
const Branch = () => {
    const showTost = (msg, theme) => {
        immediateToast(theme, {
            message: msg,
            timeout: 3000
        });
    }
    const [initValue, setinitValue] = useState({
        name: "",
        address: "",
        company: "",
        country: "",
        state: "",
        city: "",
    });
    const clear = () => {
        setinitValue({
            name: "",
            address: "",
            company: "",
            country: "",
            state: "",
            city: "",
        })
    }
    const [data, setData] = useState([]);
    const [Company, setCompany] = useState([]);
    const [Country, setCountry] = useState([]);
    const [State, setState] = useState([]);
    const [City, setCity] = useState([]);

    const [Branchadd, setBranchadd] = useState(false);
    const [Branch, setBranch] = useState(0);
    const [companyadd, setcompanyadd] = useState(false);
    const [companyval, setcompanyval] = useState(0);
    const [countryadd, setcountryadd] = useState(false);
    const [countryval, setcountryval] = useState(0);
    const [stateadd, setstateadd] = useState(false);
    const [stateval, setstateval] = useState(0);
    const [cityadd, setcityadd] = useState(false);
    const [cityval, setcityval] = useState(0);
    useEffect(() => {
        getpost(true);
        getcompanypost();
        getcountrypost();
        getstatepost();
        getcitypost();
    }, []);

    const getcompanypost = async (val) => {
        Post(`customer/getCompany`, { active: true })
            .then((res) => {
                setCompany(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const getcountrypost = async (val) => {
        Post(`master/getCountry`, { active: true })
            .then((res) => {
                setCountry(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const getstatepost = async (val) => {
        Post(`master/getState`, { active: true })
            .then((res) => {
                setState(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const getcitypost = async (val) => {
        Post(`master/getCity`, { active: true })
            .then((res) => {
                setCity(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const getpost = async (val) => {
        Post(`customer/getBranchs`, { active: val })
            .then((res) => {
                setData(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const getById = async (val) => {
        if (val != 0) {
            Get(`customer/getBranchById/${val}`)
                .then((res) => {
                    setinitValue({
                        name: res.data.Name,
                        address: res.data.Address,
                        company: res.data.Company,
                        country: res.data.Country,
                        state: res.data.State,
                        city: res.data.City,
                    });
                    setBranchadd(true);
                    setBranch(val);
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
    }
    const handleSubmit = async (val) => {
        await Post(`customer/addBranch`, val)
            .then((res) => {
                if (res.success == true) {
                    clear();
                    setBranchadd(false);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            getpost(true);
                        }
                    });
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const handleEdit = async (val) => {
        val.id = Branch;
        await Post(`customer/editBranch`, val)
            .then((res) => {
                if (res.success == true) {
                    clear();
                    setBranchadd(false);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            getpost(true);
                        }
                    });
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const changeStatus = async (val, status) => {
        await Post(`customer/changeBranchStatus`, { id: val, active: status })
            .then((res) => {
                if (res.success == true) {
                    if (status == true) {
                        getpost(false);
                        showTost("Your data has been successfully activated", "success");
                    }
                    else {
                        getpost(true);
                        showTost("Your data has been successfully deactivated", "success");
                    }
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const deleteBranch = async (val) => {
        Swal.fire({
            title: "Are you sure want to delete ?",
            icon: "error",
            confirmButtonText: "Delete",
            confirmButtonColor: "#FD711A",
            showCancelButton: true,
            cancelButtonColor: "#0F3E6A",
        }).then(async ({ isConfirmed }) => {
            if (isConfirmed) {
                await Get(
                    `customer/deleteBranch/${val}`
                )
                    .then(res => {
                        if (res.success == false) {
                            showTost(res.msg, "warning");
                        } else {
                            getpost(true);
                        }
                    })
                    .catch((err) => {
                        showTost("SOMETHING WENT WRONG ON SERVER", "error");
                    });
            }
        });
    };
    const columns = [
        {
            name: "Company",
            selector: (row) => row.Company?.Company,
        },
        {
            name: "Name",
            selector: (row) => row.Name,
        },
        {
            name: "Country",
            selector: (row) => row.Country?.Name,
        },
        {
            name: "State",
            selector: (row) => row.State?.Name,
        },
        {
            name: "City",
            selector: (row) => row.City?.Name,
        },
        {
            name: "Addresss",
            selector: (row) => row.Address,
        },
        {
            name: "Status",
            selector: (row) => <input type='checkbox' value={row.is_active} checked={row.is_active == true ? "checked" : ""} onClick={() => { row.is_active == true ? changeStatus(row._id, false) : changeStatus(row._id, true) }} />,
        },
        {
            name: "",
            selector: (row) => row.is_active == true ? <><i className='btn btn-sm btn-success fa fa-pencil mr-1' onClick={() => { getById(row._id); }} /><i className='btn btn-sm btn-danger fa fa-trash' onClick={() => { deleteBranch(row._id); }} /></> : "",
        }
    ];
    return (
        <div className='content-wrapper'>
            <div className="mb-2 mt-2 row">
                <div className="col-10">
                    <h2>Branch</h2>
                </div>
                <div className="col-2 mt-2">
                    <span className='btn btn-sm btn-warning float-right' onClick={() => { setBranchadd(true); setBranch(0); }}>
                        <i className="fa fa-plus mr-2" />
                        Add Branch
                    </span>
                </div>
                <div className="col-12">
                    <div className="custom-control custom-switch float-right">
                        <input type="checkbox" className="custom-control-input" defaultChecked id="customSwitch1" data-check_true={true} data-check_false={false} onChange={(event) => {
                            let ele = event.target;
                            let selectedId = ele.checked ? event.target.getAttribute("data-check_true") : event.target.getAttribute("data-check_false");
                            getpost(selectedId)
                        }} />
                        <label className="custom-control-label" for="customSwitch1"> Active/Inactive </label>
                    </div>
                </div>
            </div>
            <div className="row ">
                <div className="col-12 mt-3">
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                    />
                </div>
            </div>
            <CModal alignment="center" visible={Branchadd}>
                <div className="modal-header">
                    <h4 className="modal-title">{Branch == 0 ? "Add Branch" : "Update Branch"}</h4>
                    <button type="button" className="close" onClick={() => { clear(); setBranchadd(false) }}>&times;</button>
                </div>
                <div className="modal-body">
                    <Formik
                        onSubmit={Branch == 0 ? handleSubmit : handleEdit}
                        initialValues={initValue}
                        validationSchema={validation}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            touched,
                            errors,
                            setFieldValue,
                        }) => {
                            return (
                                <Form
                                    onSubmit={handleSubmit}
                                    onChange={handleChange}>
                                    <div className="form-group">
                                        <label><i className="fa fa-building-o text-danger mr-2" />Company</label>
                                        <div className='input-group'>
                                            <select disabled={Branch == 0 ? false : true} defaultValue={values.company} className={!!touched.company && !!errors.company ? "form-control is-select" : "form-select"} name='company'>
                                                <option>Select Company</option>
                                                {
                                                    Company.map((x, i) => (
                                                        <option key={i} value={x._id}>{x.Company}</option>
                                                    ))
                                                }
                                            </select>
                                            <span className="input-group-text">
                                                {values.company ? (
                                                    <i className='fa fa-pencil' onClick={() => { setcompanyval(values.company); setcompanyadd(true); }} />
                                                ) : (
                                                    <i className='fa fa-plus' onClick={() => { setcompanyval(0); setcompanyadd(true); }} />
                                                )}
                                                {companyadd && (
                                                    <CompanyModal
                                                        companyadd={companyadd}
                                                        setcompanyadd={setcompanyadd}
                                                        getcompanypost={getcompanypost}
                                                        showTost={showTost}
                                                        companyval={companyval}
                                                        setcompanyval={setcompanyval}
                                                    />
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Branch Name</label>
                                        <input type='text' name="name" defaultValue={values.name} placeholder='Enter Branch Name' className={!!touched.name && !!errors.name ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className="form-group">
                                        <label><i className="fa fa-globe text-danger mr-2" />Country</label>
                                        <div className='input-group'>
                                            <select onChange={(e) => {
                                                setFieldValue("country", e.target.value);
                                                setFieldValue("state", "");
                                                setFieldValue("city", "");
                                            }} defaultValue={values.country} className={!!touched.country && !!errors.country ? "form-control is-select" : "form-select"} name='country'>
                                                <option>Select Country</option>
                                                {Country.map((item, index) => (
                                                    <option key={index} value={item._id}>
                                                        {item.Name}
                                                    </option>
                                                ))}
                                            </select>
                                            <span className="input-group-text">
                                                {values.country ? (
                                                    <i className='fa fa-pencil' onClick={() => { setcountryval(values.country); setcountryadd(true); }} />
                                                ) : (
                                                    <i className='fa fa-plus' onClick={() => { setcountryval(0); setcountryadd(true); }} />
                                                )}
                                                {countryadd && (
                                                    <CountryModal
                                                        countryadd={countryadd}
                                                        setcountryadd={setcountryadd}
                                                        getcountrypost={getcountrypost}
                                                        showTost={showTost}
                                                        countryval={countryval}
                                                        setcountryval={setcountryval}
                                                    />
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label><i className="fa fa-map-marker text-warning mr-2" />State</label>
                                        <div className='input-group'>
                                            <select defaultValue={values.state} className={!!touched.state && !!errors.state ? "form-control is-select" : "form-select"} name='state'>
                                                <option>Select State</option>
                                                {State.filter((x) => x.Country?._id == values.country).map((x, i) => (
                                                    <option key={i} value={x._id}>{x.Name}</option>
                                                ))}
                                            </select>
                                            <span className="input-group-text">
                                                {values.state ? (
                                                    <i className='fa fa-pencil' onClick={() => { setstateval(values.state); setstateadd(true) }} />
                                                ) : (
                                                    <i className='fa fa-plus' onClick={() => { setstateval(0); setstateadd(true) }} />
                                                )}
                                                {stateadd && (
                                                    <StateModal
                                                        stateadd={stateadd}
                                                        setstateadd={setstateadd}
                                                        getstatepost={getstatepost}
                                                        showTost={showTost}
                                                        stateval={stateval}
                                                        setstateval={setstateval}
                                                    />
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label><i className="fa fa-fort-awesome text-success mr-2" />City</label>
                                        <div className='input-group'>
                                            <select onChange={handleChange} defaultValue={values.city} className={!!touched.city && !!errors.city ? "form-control is-select" : "form-select"} name='city'>
                                                <option>Select City</option>
                                                {City.filter((x) => x.State?._id === values.state).map((x, i) => (
                                                    <option key={i} value={x._id}>{x.Name}</option>
                                                ))}
                                            </select>
                                            <span className="input-group-text">
                                                {values.city ? (
                                                    <i className='fa fa-pencil' onClick={() => { setcityval(values.city); setcityadd(true) }} />
                                                ) : (
                                                    <i className='fa fa-plus' onClick={() => { setcityval(0); setcityadd(true) }} />
                                                )}
                                                {cityadd && (
                                                    <CityModal
                                                        cityadd={cityadd}
                                                        setcityadd={setcityadd}
                                                        getcitypost={getcitypost}
                                                        showTost={showTost}
                                                        cityval={cityval}
                                                        setcityval={setcityval}
                                                    />
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Address</label>
                                        <input type='text' name="address" defaultValue={values.address} placeholder='Enter Branch Address' className={!!touched.address && !!errors.address ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='col-12 mt-2'>
                                        <button type="submit" className='btn btn-sm font-weight-bold btn-success mt-2'>
                                            <i className="fa fa-check" /> Save
                                        </button>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </CModal>
        </div>
    )
}
export default Branch