import React, { useEffect, useState } from 'react';
import { Post, Get, FileUpload } from '../../Layout/ajax';
import { Gantt } from "gantt-task-react";
import "gantt-task-react/dist/index.css";
import { immediateToast } from "izitoast-react";
import { Link, useParams } from 'react-router-dom';
import moment from "moment";
import { data } from 'jquery';

const Ganttchart = () => {
    const [data, setData] = useState([]);
    const [contract, setcontract] = useState([]);
    useEffect(() => {
        getpost();
    }, []);
    const { id } = useParams();
    const showTost = (msg, theme) => {
        immediateToast(theme, {
            message: msg,
            timeout: 3000
        });
    }
    const getpost = async () => {
        Post(`contract/getAllProcess`, { contractId: id })
            .then((res) => {
                setData(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
        Post(`contract/getContractById/${id}`)
            .then((res) => {
                setcontract(res.data[0]);

            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const task = [
        {
            start: new Date(moment(contract.StartDate).format("YYYY"), moment(contract.StartDate).format("MM")-1, moment(contract.StartDate).format("DD")),
            end: new Date(moment(contract.ExpiryDate).format("YYYY"), moment(contract.ExpiryDate).format("MM")-1, moment(contract.ExpiryDate).format("DD")),
            name: contract.Name,
            id: "ProjectSample",
            progress: contract.progress,
            type: "project"
        },

    ];
    data.forEach((x, i) => {
        task.push({
            start: new Date(moment(x.startDate).format("YYYY"), moment(x.startDate).format("MM")-1, moment(x.startDate).format("DD")),
            end: new Date(moment(x.dueDate).format("YYYY"), moment(x.dueDate).format("MM")-1, moment(x.dueDate).format("DD")),
            name: x.Name,
            id: `Task${x._id}`,
            progress: x.progress,
            type: "task",
            project: "ProjectSample"
        })
        x?.subProcess.forEach((x, i) => task.push({
            start: new Date(moment(x.startDate).format("YYYY"), moment(x.startDate).format("MM")-1, moment(x.startDate).format("DD")),
            end: new Date(moment(x.dueDate).format("YYYY"), moment(x.dueDate).format("MM")-1, moment(x.dueDate).format("DD")),
            name: x.Name,
            id: `SubTask${i}`,
            progress: x.progress,
            dependencies: [`Task${x.processId}`],
            type: "task",
            project: `Task${x.processId}`
        }))
    }
    )

    return (
        <div className='content-wrapper'>
            <div className="row">
                <div className="mb-2 mt-2 row col-12">
                    <Link to={'/Projects'}><i className="fa fa-arrow-left text-dark" /></Link>
                    <h2>Gantt Chart</h2>
                </div>
                <div className="col-12 row">
                    <Gantt
                        tasks={task}
                        columnWidth={50}
                        barBackgroundColor="blue"
                        rowHeight={40}
                        fontSize={12}
                    />
                </div>
            </div>
        </div>
    );
};
export default Ganttchart;