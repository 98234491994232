import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { Post, Get, File, FileBlob } from '../../Layout/ajax';
import { CModal } from '@coreui/react';
import * as Yup from "yup";
import moment from "moment";
import Select from "react-select";
import { immediateToast } from "izitoast-react";
import Swal from "sweetalert2";
import { Bookmark } from 'react-feather';
import { current } from '@reduxjs/toolkit';
import { ButtonGroup } from 'reactstrap';
import { Btn, Spinner } from '../../AbstractElements';
import styles from './Quotes.module.scss';
import PDFViewer from '../../Components/PDFViewer';
import Pagination from "react-js-pagination";
import CKEditor from 'react-ckeditor-component';
import { CustomerModal, ProductModal, UserModal, UnitModal, ShipAddModal, BillAddModal } from './Modal.js';
const fmt = require('indian-number-format')

const validation = Yup.object().shape({
    customer: Yup.string().required(),
    assign: Yup.string().required(),
    products: Yup.array()
        .of(
            Yup.object().shape({
                quantity: Yup.string().required(),
                price: Yup.string().required(),
                product: Yup.string().required(),
                unit: Yup.string().required(),
                TotalAmount: Yup.string().required(),
                FinalAmount: Yup.string().required(),
            })),
});
const templatevalidation = Yup.object().shape({
    template: Yup.string().required(),
});
const Invoices = () => {
    const $ = window.$;
    const [data, setData] = useState([]);
    const [addinvoice, setaddinvoice] = useState(false);
    const [print, setprint] = useState(false);
    const [invoice, setinvoice] = useState(0);
    const [customer, setcustomer] = useState([]);
    const [sales, setsales] = useState([]);
    const [ShippingAddress, setShippingAddress] = useState([]);
    const [BillingAddress, setBillingAddress] = useState([]);
    const [template1, settemplate] = useState([]);
    const [productlist, setproductlist] = useState([]);
    const [unitlist, setunitlist] = useState([]);
    const [formkey, setFormKey] = useState(Math.random() * 1000000);
    const [productadd, setproductadd] = useState(false);
    const [unitadd, setunitadd] = useState(false);
    const [customeradd, setcustomeradd] = useState(false);
    const [isGridView, setIsGridView] = useState(false);
    const [downloadSpinner, setDownloadSpinner] = useState(false);
    const [pdfBlob, setPdfBlob] = useState(null);
    const [pdfmodal, setPdfModal] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [pageNo, setPageNo] = useState(1);
    const [perPage, setPerPage] = useState(6);
    const [useradd, setuseradd] = useState(false);
    const [filtername, setfiltername] = useState("");
    const [Manual, setManual] = useState(false);
    const [productval, setproductval] = useState(0);
    const [customerval, setcustomerval] = useState(0);
    const [userval, setuserval] = useState(0);
    const [unitval, setunitval] = useState(0);
    const [lastInvoiceCode, setLastInvoiceCode] = useState('');
    const [Shipadd, setShipadd] = useState(false);
    const [ShipAddval, setShipAddval] = useState(0);
    const [BillAdd, setBillAdd] = useState(false);
    const [BillAddval, setBillAddval] = useState(0);
    const NexPage = (NexPage) => {
        setPageNo(NexPage);
    };
    useEffect(() => {
        getpost(false);
    }, [pageNo, perPage, filtername]);
    const showTost = (msg, theme) => {
        immediateToast(theme, {
            message: msg,
            timeout: 3000
        });
    }
    useEffect(() => {
        getpost(false);
        Get(`master/getApplicationSetting`)
            .then((res) => {
                setManual(res.data);
                setinitValue(prevValue => ({
                    ...prevValue,
                    TermsAndCondition: res.data.TermsAndCondition
                }));
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
        Post(`template/getAll`, { active: true })
            .then((res) => {
                settemplate(res.filter(
                    (x) => x.TemplateFor == "Invoice"
                ))
                var set = res.filter(x => x.TemplateFor == "Invoice" && x.is_default == true).map(x => x._id)
                setinittemplateValue({
                    template: set[0]
                });
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
        getcustomerpost();
        getuserpost();
        getproductpost();
        getunitpost();
    }, []);
    const getcustomerpost = async () => {
        Post(`customer/getAllCustomer`, { active: true })
            .then((res) => {
                setcustomer(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const getuserpost = async () => {
        Post(`users/getAllUser`, { active: true })
            .then((res) => {
                setsales(res)
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const getunitpost = async (val) => {
        Post(`master/getUnit`, { active: true })
            .then((res) => {
                setunitlist(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const getproductpost = async (val) => {
        Post(`master/getProduct`, { active: true })
            .then((res) => {
                setproductlist(res.data)
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const genrateKeys = () => {
        setFormKey(Math.random() * 1000000);
    }
    const getpost = async (val) => {
        let skipa = perPage * (pageNo - 1);
        await Post(`invoice/getAll`, { active: val, skip: skipa, per_page: perPage, filter: filtername })
            .then((res) => {
                setData(res.data.data)
                setTotalCount(res.data.count);
                setLastInvoiceCode(res.lastInvoiceCode?.InvoiceCode);
            })
            .catch((err) => {
                setTotalCount(0);
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const defaultvalue = {
        quantity: "",
        price: "",
        CGST: 0,
        SGST: 0,
        product: "",
        note: "",
        unit: "",
        TotalAmount: "",
        FinalAmount: "",
        DicountPercentage: "",
        DiscountAmmount: "",
        AfterDiscountAmmount: ""
    };
    const [inittemplateValue, setinittemplateValue] = useState({
        template: "",
    });
    const [initValue, setinitValue] = useState({
        customer: "",
        shippingAddress: "",
        billingAddress: "",
        AfterTaxPrice: "0",
        CGST: "0",
        SGST: "0",
        discount: "0",
        totalTax: "0",
        BeforeTaxPrice: "0",
        finalPrice: "0",
        Amount: "0",
        RoundOff: "0",
        InvoiceName: "",
        TermsofDelivery: "",
        PaymentofMode: "",
        Descriptionofwork: "",
        OtherChargeName: "",
        OtherCharge: 0,
        invoiceDate: new Date().toISOString().split('T')[0],
        deliverydate: new Date().toISOString().split('T')[0],
        InvoiceCode: "",
        sales: "",
        note: "",
        TermsAndCondition: "",
        products: [defaultvalue],
        OrderId: "",
        QuatationId: "",
        Dispatchdocno: "",
        Dispatchthr: "",
        Destination: "",
        Deliverynote: "",
        assign: "",
    });

    const AddMore = (values) => {
        let newobject = {
            ...values,
            products: [...values.products, defaultvalue]

        };
        setinitValue(newobject);
    };

    const onDelete = (index, values) => {
        let productarr = [...values.products];
        productarr.splice(index, 1)
        let newobject = {
            ...values,
            products: productarr

        };
        setinitValue(newobject);
    };

    const TotalCalculator = (detvalues) => {
        let values = { ...detvalues };
        values.AfterTaxPrice = 0;
        values.CGST = 0;
        values.SGST = 0;
        values.totalTax = 0;
        values.BeforeTaxPrice = 0;
        values.finalPrice = 0;
        values.Amount = 0;
        values.RoundOff = 0;
        detvalues.products.forEach((product, index) => {
            let price = parseFloat(product.price);
            let quantity = parseFloat(product.quantity);
            let AfterTaxPrice = quantity * price;
            let cgst = (AfterTaxPrice * parseFloat(product.CGST)) / 100 || 0;
            let sgst = (AfterTaxPrice * parseFloat(product.SGST)) / 100 || 0;
            let totalTax = cgst + sgst;
            let finalPrice = AfterTaxPrice + totalTax;
            let discountPercentage = parseFloat(product.DicountPercentage) || 0;
            if (isNaN(price) || isNaN(quantity) || isNaN(cgst) || isNaN(sgst)) {
                return;
            }
            let discountAmount = (finalPrice * discountPercentage) / 100 || 0;
            values.products[index].TotalAmount = AfterTaxPrice;

            values.products[index].FinalAmount = finalPrice;
            values.products[index].DiscountAmmount = discountAmount;
            values.products[index].AfterDiscountAmmount = finalPrice - discountAmount;
            values.BeforeTaxPrice += AfterTaxPrice;
            // values.AfterTaxPrice += AfterTaxPrice + totalTax;
            values.AfterTaxPrice += finalPrice - discountAmount;
            values.CGST += cgst;
            values.SGST += sgst;
            values.totalTax += totalTax;
        });
        let charge = parseFloat(values.OtherCharge) || 0;
        let discount = parseFloat(values.discount) || 0;
        let finalcharge = values.AfterTaxPrice + charge;
        let final = (finalcharge * discount) / 100;
        values.Amount = (finalcharge - final).toFixed(2);
        values.finalPrice = Math.round(finalcharge - final);
        values.RoundOff = (values.finalPrice - values.Amount).toFixed(2);
        setinitValue(values);
        genrateKeys();
    }
    const clear = () => {
        setinitValue({
            customer: "",
            shippingAddress: "",
            billingAddress: "",
            AfterTaxPrice: "0",
            CGST: "0",
            SGST: "0",
            discount: "0",
            totalTax: "0",
            BeforeTaxPrice: "0",
            finalPrice: "0",
            RoundOff: "0",
            Amount: "0",
            InvoiceName: "",
            TermsofDelivery: "",
            PaymentofMode: "",
            Descriptionofwork: "",
            OtherChargeName: "",
            OtherCharge: 0,
            invoiceDate: new Date().toISOString().split('T')[0],
            deliverydate: "",
            InvoiceCode: "",
            sales: "",
            note: "",
            TermsAndCondition: "",
            products: [defaultvalue],
            OrderId: "",
            QuatationId: "",
            Dispatchdocno: "",
            Dispatchthr: "",
            Destination: "",
            Deliverynote: "",
            assign: "",
        });
    }
    const invoicebyid = async (val) => {
        if (val != 0) {
            Get(`invoice/getById/${val}`)
                .then((res) => {
                    getcustomer(res.data[0].Customer?._id)
                    setinitValue({
                        customer: res.data[0].Customer?._id,
                        shippingAddress: res.data[0].ShippingAddress?._id,
                        billingAddress: res.data[0].BillingAddress?._id,
                        AfterTaxPrice: res.data[0].AfterTaxPrice,
                        CGST: res.data[0].CGST,
                        SGST: res.data[0].SGST,
                        discount: res.data[0].Discount,
                        totalTax: res.data[0].TotalTax,
                        finalPrice: res.data[0].FinalPrice,
                        RoundOff: res.data[0].RoundOff,
                        Amount: res.data[0].Amount,
                        BeforeTaxPrice: res.data[0].BeforeTaxPrice,
                        Descriptionofwork: res.data[0].Descriptionofwork,
                        InvoiceName: res.data[0].InvoiceName,
                        TermsofDelivery: res.data[0].TermsofDelivery,
                        PaymentofMode: res.data[0].PaymentofMode,
                        OtherChargeName: res.data[0].OtherChargeName,
                        OtherCharge: res.data[0].OtherCharge,
                        sales: res.data[0].Sales?._id,
                        InvoiceCode: res.data[0].InvoiceCode,
                        invoiceDate: moment(res.data[0].InvoiceDate).format("YYYY-MM-DD"),
                        deliverydate: moment(res.data[0].deliverydate).format("YYYY-MM-DD"),
                        note: res.data[0].Note,
                        OrderId: res.data[0].OrderId,
                        QuatationId: res.data[0].QuatationId,
                        Dispatchdocno: res.data[0].Dispatchdocno,
                        Dispatchthr: res.data[0].Dispatchthr,
                        Destination: res.data[0].Destination,
                        Deliverynote: res.data[0].Deliverynote,
                        assign: res.data[0].Assign,
                        products: res.data[0].Products.map((x, i) => ({
                            note: x.Note,
                            CGST: x.CGST,
                            price: x.Price,
                            quantity: x.Quantity,
                            SGST: x.SGST,
                            unit: x.Unit,
                            TotalAmount: x.TotalAmount,
                            FinalAmount: x.FinalAmount,
                            product: x.Product,
                            DicountPercentage: x.DicountPercentage,
                            DiscountAmmount: x.DiscountAmmount,
                            AfterDiscountAmmount: x.AfterDiscountAmmount
                        })),
                        TermsAndCondition: res.data[0].TermsAndCondition
                    });
                    setaddinvoice(true)
                    setinvoice(val);
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
    }
    const handleSubmit = async (val) => {
        if (invoice == 0)
            await Post(`invoice/add`, val)
                .then((res) => {
                    clear();
                    setaddinvoice(false);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            getpost(false);
                        }
                    });
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        else {
            val.id = invoice;
            await Post(`invoice/edit`, val)
                .then((res) => {
                    clear();
                    setaddinvoice(false);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            getpost(false);
                        }
                    });
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
    };
    const deleteInvoice = async (val) => {
        Swal.fire({
            title: "Are you sure want to delete ?",
            icon: "error",
            confirmButtonText: "Delete",
            confirmButtonColor: "#FD711A",
            showCancelButton: true,
            cancelButtonColor: "#0F3E6A",
        }).then(async ({ isConfirmed }) => {
            if (isConfirmed) {
                await Get(
                    `invoice/deleteInvoice/${val}`
                )
                    .then(res => {
                        if (res.success == false) {
                            showTost(res.msg, "warning");
                        } else {
                            getpost(false);
                        }
                    })
                    .catch((err) => {
                        showTost("SOMETHING WENT WRONG ON SERVER", "error");
                    });
            }
        });
    };
    const download = async (val) => {
        setDownloadSpinner(true);
        await FileBlob(`invoice/Invoicepdfcreate`, { id: invoice, template_id: val.template }, "Invoice.pdf")
            .then((resblob) => {
                setDownloadSpinner(false);
                setprint(false);
                setPdfModal(true);
                setTimeout(() => {
                    setPdfBlob(resblob);
                }, 2000);
            })
            .catch((err) => {
                setDownloadSpinner(false);
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const getcustomer = async (val) => {
        if (val !== "") {
            await Get(`customer/getCustomerById/${val}`)
                .then((res) => {
                    let shipdefault = res.data[0]?.ShippingAddress.find(x => x.is_default == true)
                    let billdefault = res.data[0]?.BillingAddress.find(x => x.is_default == true)
                    setinitValue({
                        ...initValue,
                        shippingAddress: shipdefault?._id,
                        billingAddress: billdefault?._id,
                    })
                    setShippingAddress(res.data[0]?.ShippingAddress)
                    setBillingAddress(res.data[0]?.BillingAddress)
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
        else {
            setShippingAddress([])
            setBillingAddress([])
        }
    }
    const conevrtToRecovery = async (invoiceId) => {
        await Post(`invoice/invoiceToRecovery/${invoiceId}`,)
            .then((res) => {
                if (res.success == true) {
                    getpost(false);
                    showTost("Recovery created successfully", "success");
                } else {
                    showTost(res.msg, "warning");
                }
            })
            .catch((err) => {
                showTost("Error duplicating quation", "error");
            });
    }
    return (
        <div className='content-wrapper page'>
            <div className="row mb-2 mt-2">
                <div className="col-md-4">
                    <h2>Invoices</h2>
                </div>
                <div className="col-md-8 mt-md-0 mt-2">
                    <div className="float-right ">
                        <div className='d-flex mb-2'>
                            <span className='btn btn-sm btn-primary mr-2' onClick={() => { setaddinvoice(true); setinvoice(0); }}>
                                <i className="fa fa-plus mr-2" /> Create Invoice
                            </span>
                            <span>
                                <ButtonGroup>
                                    <Btn size="sm" color={isGridView ? "outline-info" : "info"} onClick={() => setIsGridView(false)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-grid"><rect x="3" y="3" width="7" height="7"></rect><rect x="14" y="3" width="7" height="7"></rect><rect x="14" y="14" width="7" height="7"></rect><rect x="3" y="14" width="7" height="7"></rect></svg>
                                    </Btn>
                                    <Btn size="sm" color={!isGridView ? "outline-info" : "info"} onClick={() => setIsGridView(true)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-list"><line x1="8" y1="6" x2="21" y2="6"></line><line x1="8" y1="12" x2="21" y2="12"></line><line x1="8" y1="18" x2="21" y2="18"></line><line x1="3" y1="6" x2="3" y2="6"></line><line x1="3" y1="12" x2="3" y2="12"></line><line x1="3" y1="18" x2="3" y2="18"></line></svg>
                                    </Btn>
                                </ButtonGroup>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="float-right">
                        <input type="text" placeholder="Search Company Name" onKeyPress={(event) => {
                            if (event.nativeEvent.key === 'Enter') {
                                setPageNo(1);
                                setfiltername(event.target.value)
                            }
                        }} className="form-control w-auto" />
                    </div>
                </div>
            </div>

            <div className='bookmark-tabcontent mt-3'>
                <div className={"details-bookmark text-center " + (isGridView ? "list-bookmark" : '')} id="list-bookmark1">
                    <div className="row" id="bookmarkData">
                        {data.length == 0 ? <div className="mt-5">
                            No Record Found
                        </div> : data.map(item => (
                            <div className="col-xl-3 col-md-4 xl-50">
                                <div className="card card-with-border bookmark-card o-hidden">
                                    <div className="details-website">
                                        <div className="desciption-data">
                                            <div className="title-bookmark">
                                                <div className='row mb-3'>
                                                    <div className='col-md-8'>
                                                        <h6 className="weburl_0"><i className="fa fa-building mr-2" />{item.Customer?.Company?.Company} - {item.Customer?.Branch?.Name} ({item.Customer?.FirstName} {item.Customer?.LastName})</h6>
                                                    </div>
                                                    <div className='col-md-4 d-flex justify-content-end'>
                                                        <div className="d-flex">
                                                            <span className="ml-auto">
                                                                <span onClick={() => conevrtToRecovery(item._id)} className='btn btn-sm mr-1 bg-light px-0'>
                                                                    <div class="text-body">
                                                                        <i class="fa fa-files-o mr-1"></i>
                                                                        Recovery
                                                                    </div>
                                                                </span>
                                                                <i className='fa fa-print btn btn-success btn-sm rounded-circle p-1 fs-12' onClick={() => { setinvoice(item._id); setprint(true); }} />
                                                                <i className='btn btn-sm btn-success fa fa-pencil ml-1 p-1' onClick={() => { invoicebyid(item._id); }} />
                                                                <i className='btn btn-danger btn-sm fa fa-trash ml-1 p-1' onClick={() => { deleteInvoice(item._id); }} />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row mb-1'>
                                                    <h6 className='col-4 font-weight-normal f-13 text-secondary'>Invoice No </h6>
                                                    <h6 className="col-8 f-13 text-capitalize text-dark">{item.InvoiceCode}</h6>
                                                </div>
                                                <div className='row mb-1'>
                                                    <h6 className='col-4 font-weight-normal f-13 text-secondary'>Invoice Date </h6>
                                                    <h6 className="col-8 f-13 font-weight-normal text-capitalize text-dark">{moment(item.InvoiceDate).format("DD-MMM")}</h6>
                                                </div>
                                                <div className='row mb-1'>
                                                    <h6 className='col-4 font-weight-normal f-13 text-secondary'>Amount </h6>
                                                    <h6 className="col-8 f-13 text-capitalize text-dark">{fmt.format(item.FinalPrice)}</h6>
                                                </div>
                                                {item.CreatedBy?.name &&
                                                    <div className='row mb-1'>
                                                        <h6 className='col-4 font-weight-normal f-13 text-secondary'>Added By </h6>
                                                        <h6 className="col-8 f-13 font-weight-normal text-capitalize ">{item.CreatedBy?.name}</h6>
                                                    </div>
                                                }
                                                {item.Assign?.name &&
                                                    <div className='row mb-1'>
                                                        <h6 className='col-4 font-weight-normal f-13 text-secondary'>Assign To</h6>
                                                        <h6 className="col-8 f-13 font-weight-normal text-capitalize">{item.Assign?.name}</h6>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    {data.length == 0 ? "" :
                        <Pagination
                            activePage={pageNo}
                            itemsCountPerPage={perPage}
                            totalItemsCount={totalCount}
                            pageRangeDisplayed={5}
                            linkclassName={"page-link"}
                            onChange={NexPage}
                            itemClass={"page-item"}
                            linkClass={"page-link"}
                        />
                    }
                </div>
            </div>


            <CModal alignment="center" size="xl" visible={addinvoice}>
                <div className="modal-header">
                    <h4 className="modal-title">{invoice == 0 ? "Create Invoice" : "Update Invoice"}</h4>
                    <button type="button" className="close" onClick={() => { clear(); setaddinvoice(false) }}>&times;</button>
                </div>
                <div className="modal-body">
                    <Formik
                        onSubmit={handleSubmit}
                        initialValues={initValue}
                        validationSchema={validation}
                        key={formkey}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            touched,
                            errors,
                            setFieldValue,
                        }) => {
                            return (
                                <Form
                                    onSubmit={handleSubmit}
                                    onChange={handleChange}>

                                    <div className='row'>
                                        <div className='col-lg-6 col-md-12'>
                                            <div className='row'>
                                                {Manual?.Invoice == true ?
                                                    <div className="col-6 mb-2">
                                                        <label>Invoice Code</label>
                                                        <input type="text" placeholder="Invoice Code" value={values.InvoiceCode} defaultValue={values.InvoiceCode} name='InvoiceCode' className={!!touched.InvoiceCode && !!errors.InvoiceCode ? "form-control is-invalid" : "form-control"} />
                                                        {invoice == 0 ? <div>
                                                            <span>Last Invoice Code:</span>
                                                            <span className='fw-bold ms-1'>{lastInvoiceCode}</span>
                                                        </div> : ""}
                                                    </div> : ""}
                                                <div className="col-6 mb-2">
                                                    <label>Invoice Name</label>
                                                    <input type="text" placeholder="Invoice Name" value={values.InvoiceName} defaultValue={values.InvoiceName} name='InvoiceName' className={!!touched.InvoiceName && !!errors.InvoiceName ? "form-control is-invalid" : "form-control"} />
                                                </div>
                                                <div className="col-12 mb-2">
                                                    <label>Description of work</label>
                                                    <textarea rows="3" type="text" placeholder="Description of Work" defaultValue={values.Descriptionofwork} name='Descriptionofwork' className={!!touched.Descriptionofwork && !!errors.Descriptionofwork ? "form-control is-invalid" : "form-control"} />
                                                </div>
                                                <div className="col-6 mb-2">
                                                    <label>Customer</label>
                                                    <div className='input-group d-flex'>
                                                        <Select
                                                            name="customer"
                                                            value={customer.map((x) => ({
                                                                value: x._id,
                                                                label: `${x.Company?.Company} (${x.FirstName} ${x.LastName})`,
                                                            })).find((option) => option.value === values.customer) || null}
                                                            onChange={(e) => {
                                                                setFieldValue("customer", e.value);
                                                                getcustomer(e.value);
                                                            }}
                                                            options={customer.map((x) => ({
                                                                value: x._id,
                                                                label: `${x.Company?.Company} (${x.FirstName} ${x.LastName})`,
                                                            }))}
                                                            className={touched.customer && errors.customer ? "is-invalid w-75" : "w-75"}
                                                        />
                                                        <span className="input-group-text p-0">
                                                            {values.customer &&
                                                                <button type="button" className='btn border btn-sm bg-light p-2 brdrRdius0'
                                                                    onClick={() => { setcustomerval(values.customer); setcustomeradd(true) }}>
                                                                    <div class="text-body"><i className='fa fa-pencil' /></div>
                                                                </button>
                                                            }
                                                            <button type="button" className='btn border btn-sm bg-light p-2 brdrRdius0'
                                                                onClick={() => { setcustomerval(0); setcustomeradd(true) }}>
                                                                <div class="text-body"><i className='fa fa-plus' /></div>
                                                            </button>
                                                            {customeradd && (
                                                                <CustomerModal
                                                                    setcustomeradd={setcustomeradd}
                                                                    customeradd={customeradd}
                                                                    getcustomerpost={getcustomerpost}
                                                                    showTost={showTost}
                                                                    Manual={Manual}
                                                                    setcustomerval={setcustomerval}
                                                                    customerval={customerval}
                                                                />)}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-6 mb-2">
                                                    <label>Sales</label>
                                                    <div className='input-group'>
                                                        <select defaultValue={values.sales} className={!!touched.sales && !!errors.sales ? "form-select is-invalid" : "form-select"} name='sales'>
                                                            <option value="">Select Sales</option>
                                                            {sales
                                                                .map((x, i) => (
                                                                    <option key={i} value={x._id}>{x.name}</option>
                                                                ))}
                                                        </select>
                                                        <span className="input-group-text">
                                                            {values.sales ? (
                                                                <i className='fa fa-pencil' onClick={() => { setuserval(values.sales); setuseradd(true) }} />
                                                            ) : (
                                                                <i className='fa fa-plus' onClick={() => { setuserval(0); setuseradd(true) }} />
                                                            )}
                                                            {useradd && (
                                                                <UserModal
                                                                    useradd={useradd}
                                                                    setuseradd={setuseradd}
                                                                    getuserpost={getuserpost}
                                                                    showTost={showTost}
                                                                    userval={userval}
                                                                    setuserval={setuserval}
                                                                />
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-6 mb-2">
                                                    <label>Shipping Address</label>
                                                    <select defaultValue={values.shippingAddress} className={!!touched.shippingAddress && !!errors.shippingAddress ? "form-select is-invalid" : "form-select"} name='shippingAddress'>
                                                        <option value="">Select Shipping Address</option>
                                                        {ShippingAddress
                                                            .map((x, i) => {
                                                                if (initValue.shippingAddress == x._id) {
                                                                    return <option selected key={i} value={x._id}>{x.Address}</option>;
                                                                }
                                                                return <option key={i} value={x._id}>{x.Address}</option>;
                                                            })}
                                                    </select>
                                                </div>
                                                <div className="col-6 mb-2">
                                                    <label>Billing Address</label>
                                                    <select defaultValue={values.billingAddress} className={!!touched.billingAddress && !!errors.billingAddress ? "form-select is-invalid" : "form-select"} name='billingAddress'>
                                                        <option value="">Select Billing Address</option>
                                                        {BillingAddress
                                                            .map((x, i) => {
                                                                if (initValue.billingAddress == x._id) {
                                                                    return <option selected key={i} value={x._id}>{x.Address}</option>;
                                                                }
                                                                return <option key={i} value={x._id}>{x.Address}</option>;
                                                            })}
                                                    </select>
                                                </div>
                                                {/* <div className="col-6 mb-2">
                                                    <label>Shipping Address</label>
                                                    <div className='input-group'>
                                                        <select defaultValue={values.shippingAddress} onChange={handleChange} name='shippingAddress' disabled={!values.customer}
                                                            className={!!touched.shippingAddress && !!errors.shippingAddress ? "form-select is-invalid" : "form-select"}>
                                                            <option>Select Shipping Address</option>
                                                            {ShippingAddress
                                                                .map((x, i) => {
                                                                    if (initValue.shippingAddress == x._id) {
                                                                        return <option selected key={i} value={x._id}>{x.Address}</option>;
                                                                    }
                                                                    return <option key={i} value={x._id}>{x.Address}</option>;
                                                                })}
                                                        </select>                                                                                                                {values.shippingAddress ? (
                                                            <span className="input-group-text" onClick={() => { setShipAddval(values.shippingAddress); setShipadd(true) }}><i className='fa fa-pencil' /></span>
                                                        ) : (
                                                            <span className="input-group-text" onClick={() => {
                                                                if (!values.customer) {
                                                                    showTost("Please select a Customer first.", "warning");
                                                                    return;
                                                                } setShipAddval(0); setShipadd(true)
                                                            }}><i className='fa fa-plus' /></span>
                                                        )}
                                                        {Shipadd && (
                                                            <ShipAddModal
                                                                Shipadd={Shipadd}
                                                                setShipadd={setShipadd}
                                                                getcustomer={getcustomer}
                                                                showTost={showTost}
                                                                ShipAddval={ShipAddval}
                                                                setShipAddval={setShipAddval}
                                                                selectedCustomerId={values.customer}
                                                            />
                                                        )}
                                                        {!values.customer && touched.shippingAddress && (
                                                            <div className="invalid-feedback">
                                                                Please select a Customer first.
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-6 mb-2">
                                                    <label>Billing Address</label>
                                                    <div className='input-group'>
                                                        <select defaultValue={values.billingAddress} onChange={handleChange} name='billingAddress' disabled={!values.customer}
                                                            className={!!touched.billingAddress && !!errors.billingAddress ? "form-select is-invalid" : "form-select"}>
                                                            <option>Select Billing Address</option>
                                                            {BillingAddress
                                                                .map((x, i) => {
                                                                    if (initValue.billingAddress == x._id) {
                                                                        return <option selected key={i} value={x._id}>{x.Address}</option>;
                                                                    }
                                                                    return <option key={i} value={x._id}>{x.Address}</option>;
                                                                })}
                                                        </select>                                                                                                                {values.billingAddress ? (
                                                            <span className="input-group-text" onClick={() => { setBillAddval(values.billingAddress); setBillAdd(true) }}><i className='fa fa-pencil' /></span>
                                                        ) : (
                                                            <span className="input-group-text" onClick={() => {
                                                                if (!values.customer) {
                                                                    showTost("Please select a Customer first.", "warning");
                                                                    return;
                                                                } setBillAddval(0); setBillAdd(true)
                                                            }}><i className='fa fa-plus' /></span>
                                                        )}
                                                        {BillAdd && (
                                                            <BillAddModal
                                                                BillAdd={BillAdd}
                                                                setBillAdd={setBillAdd}
                                                                getcustomer={getcustomer}
                                                                showTost={showTost}
                                                                BillAddval={BillAddval}
                                                                setBillAddval={setBillAddval}
                                                                selectedCustomerId={values.customer}
                                                            />
                                                        )}
                                                        {!values.customer && touched.billingAddress && (
                                                            <div className="invalid-feedback">
                                                                Please select a Customer first.
                                                            </div>
                                                        )}
                                                    </div>
                                                </div> */}
                                                <div className='col-lg-6 mt-2'>
                                                    <label><i className="fa fa-user text-secondary mr-2" />Assign to :</label>
                                                    <div className='input-group'>
                                                        <select name="assign" defaultValue={values.assign} onChange={handleChange} className={!!touched.assign && !!errors.assign ? "form-select is-invalid" : "form-select"}>
                                                            <option>Select Assign</option>
                                                            {sales.map((x, i) => (
                                                                <option key={i} value={x._id}>{x.name}</option>
                                                            ))}
                                                        </select>
                                                        <span className="input-group-text">
                                                            {values.assign ? (
                                                                <i className='fa fa-pencil' onClick={() => { setuserval(values.assign); setuseradd(true) }} />
                                                            ) : (
                                                                <i className='fa fa-plus' onClick={() => { setuserval(0); setuseradd(true) }} />
                                                            )}
                                                            {useradd && (
                                                                <UserModal
                                                                    useradd={useradd}
                                                                    setuseradd={setuseradd}
                                                                    getuserpost={getuserpost}
                                                                    showTost={showTost}
                                                                    userval={userval}
                                                                    setuserval={setuserval}
                                                                />
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-6 mb-2">
                                                    <label>Invoice Date</label>
                                                    <input type="date" placeholder="Invoice Date" defaultValue={values.invoiceDate} name='invoiceDate' className={!!touched.invoiceDate && !!errors.invoiceDate ? "form-control is-invalid" : "form-control"} />
                                                </div>
                                                <div className="col-6 mb-2">
                                                    <label>Payment of Mode</label>
                                                    <select defaultValue={values.PaymentofMode} className={!!touched.PaymentofMode && !!errors.PaymentofMode ? "form-select is-invalid" : "form-select"} name='PaymentofMode'>
                                                        <option value="">Select Payment of Mode</option>
                                                        <option value="Cash">Cash</option>
                                                        <option value="Credit">Credit</option>
                                                        <option value="Debite">Debite</option>
                                                        <option value="Online">Online</option>
                                                        <option value="Cheques">Cheques</option>
                                                    </select>
                                                </div>
                                                <div className="col-lg-6 col-md-12 mb-2">
                                                    <label>Order No.</label>
                                                    <input type="text" value={values.OrderId} defaultValue={values.OrderId} className={!!touched.OrderId && !!errors.OrderId ? "form-control is-invalid" : "form-control"} name='OrderId' placeholder="Order No." />
                                                </div>
                                                <div className="col-6 mb-2">
                                                    <label>Terms of Delivery</label>
                                                    <textarea rows="3" type="text" placeholder="Terms of Delivery" value={values.TermsofDelivery} defaultValue={values.TermsofDelivery} name='TermsofDelivery' className={!!touched.TermsofDelivery && !!errors.TermsofDelivery ? "form-control is-invalid" : "form-control"} />
                                                </div>
                                                <div className="col-lg-6 col-md-12 mb-2">
                                                    <label>Delivery Note</label>
                                                    <textarea rows="3" type="text" placeholder="Delivery Note" value={values.Deliverynote} defaultValue={values.Deliverynote} name='Deliverynote' className={!!touched.Deliverynote && !!errors.Deliverynote ? "form-control is-invalid" : "form-control"} />
                                                </div>
                                                <div className="col-lg-6 col-md-12 mb-2">
                                                    <label>Quotation No.</label>
                                                    <input type="text" value={values.QuatationId} defaultValue={values.QuatationId} className={!!touched.QuatationId && !!errors.QuatationId ? "form-control is-invalid" : "form-control"} name='QuatationId' placeholder="Quotation No." />
                                                </div>
                                                <div className="col-lg-6 col-md-12 mb-2">
                                                    <label>Dispatch Document No.</label>
                                                    <input type="text" value={values.Dispatchdocno} defaultValue={values.Dispatchdocno} className={!!touched.Dispatchdocno && !!errors.Dispatchdocno ? "form-control is-invalid" : "form-control"} name='Dispatchdocno' placeholder="Dispatch Document No." />
                                                </div>
                                                <div className="col-lg-6 col-md-12 mb-2">
                                                    <label>Delivery Date</label>
                                                    <input type="date" placeholder="Delivery Date"
                                                        value={values.deliverydate} name='deliverydate' min={values.invoiceDate} className={!!touched.deliverydate && !!errors.deliverydate ? "form-control is-invalid" : "form-control"} />
                                                </div>
                                                <div className="col-lg-6 col-md-12 mb-2">
                                                    <label>Dispatch through</label>
                                                    <input type="text" placeholder="Dispatch through" value={values.Dispatchthr} defaultValue={values.Dispatchthr} name='Dispatchthr' className={!!touched.Dispatchthr && !!errors.Dispatchthr ? "form-control is-invalid" : "form-control"} />
                                                </div>
                                                <div className="col-lg-6 col-md-12 mb-2">
                                                    <label>Destination</label>
                                                    <input type="text" placeholder="Destination" value={values.Destination} defaultValue={values.Destination} name='Destination' className={!!touched.Destination && !!errors.Destination ? "form-control is-invalid" : "form-control"} />
                                                </div>
                                                <div className="col-6 mb-2">
                                                    <label>Total CGST</label>
                                                    <input type="text" readOnly={true} placeholder="CGST" value={values.CGST} defaultValue={values.CGST} name='CGST' className={!!touched.CGST && !!errors.CGST ? "form-control is-invalid" : "form-control"} />
                                                </div>
                                                <div className="col-6 mb-2">
                                                    <label>Total SGST</label>
                                                    <input type="text" readOnly={true} placeholder="SGST" value={values.SGST} defaultValue={values.SGST} name='SGST' className={!!touched.SGST && !!errors.SGST ? "form-control is-invalid" : "form-control"} />
                                                </div>
                                                <div className="col-6 mb-2">
                                                    <label>Total Tax</label>
                                                    <input type="text" readOnly={true} placeholder="Total Tax" value={values.totalTax} defaultValue={values.totalTax} name='totalTax' className={!!touched.totalTax && !!errors.totalTax ? "form-control is-invalid" : "form-control"} />
                                                </div>
                                                <div className="col-6 mb-2">
                                                    <label>Total Price(Before Tax)</label>
                                                    <input type="text" readOnly={true} placeholder="Total Price(Before Tax)" value={values.BeforeTaxPrice} defaultValue={values.BeforeTaxPrice} name='BeforeTaxPrice' className={!!touched.BeforeTaxPrice && !!errors.BeforeTaxPrice ? "form-control is-invalid" : "form-control"} />
                                                </div>
                                                <div className="col-6 mb-2">
                                                    <label>Total Price(After Tax)</label>
                                                    <input type="text" readOnly={true} placeholder="Total Price(After Tax)" value={values.AfterTaxPrice} defaultValue={values.AfterTaxPrice} name='AfterTaxPrice' className={!!touched.AfterTaxPrice && !!errors.AfterTaxPrice ? "form-control is-invalid" : "form-control"} />
                                                </div>
                                                <div className="col-6 mb-2">
                                                    <label>Other ChargeName</label>
                                                    <input type="text" placeholder="Other ChargeName" value={values.OtherChargeName} defaultValue={values.OtherChargeName} name='OtherChargeName' className={!!touched.OtherChargeName && !!errors.OtherChargeName ? "form-control is-invalid" : "form-control"} />
                                                </div>
                                                <div className="col-6 mb-2">
                                                    <label>Other Charge</label>
                                                    <input type="text" placeholder="Other Charge" onBlur={() => { TotalCalculator(values); }} value={values.OtherCharge} defaultValue={values.OtherCharge} name='OtherCharge' className={!!touched.OtherCharge && !!errors.OtherCharge ? "form-control is-invalid" : "form-control"} />
                                                </div>
                                                <div className="col-6 mb-2">
                                                    <label>Discount(%)</label>
                                                    <input type="text" placeholder="Discount" onBlur={() => { TotalCalculator(values); }} value={values.discount} defaultValue={values.discount} name='discount' className={!!touched.discount && !!errors.discount ? "form-control is-invalid" : "form-control"} />
                                                </div>
                                                <div className="col-6 mb-2">
                                                    <label>Amount</label>
                                                    <input type="text" readOnly={true} placeholder="Amount" value={values.Amount} defaultValue={values.Amount} name='Amount' className={!!touched.Amount && !!errors.Amount ? "form-control is-invalid" : "form-control"} />
                                                </div>
                                                <div className="col-6 mb-2">
                                                    <label>Round Off</label>
                                                    <input type="text" readOnly={true} placeholder="Round Off" value={values.RoundOff} defaultValue={values.RoundOff} name='RoundOff' className={!!touched.RoundOff && !!errors.RoundOff ? "form-control is-invalid" : "form-control"} />
                                                </div>
                                                <div className="col-6 mb-2">
                                                    <label>Final Amount</label>
                                                    <input type="text" readOnly={true} placeholder="Final Amount" value={values.finalPrice} defaultValue={values.finalPrice} name='finalPrice' className={!!touched.finalPrice && !!errors.finalPrice ? "form-control is-invalid" : "form-control"} />
                                                </div>
                                                <div className="col-12 mb-2">
                                                    <label>Note</label>
                                                    <textarea rows="3" type="text" placeholder="Notes" defaultValue={values.note} name='note' className={!!touched.note && !!errors.note ? "form-control is-invalid" : "form-control"} />
                                                </div>
                                                <div className='col-12'>
                                                    <div><h4>Terms And Condition</h4></div>
                                                    <CKEditor
                                                        name="TermsAndCondition"
                                                        id="TermsAndCondition"
                                                        content={values.TermsAndCondition}
                                                        events={{
                                                            change: (eve) => {
                                                                const data = eve.editor.getData();
                                                                setFieldValue("TermsAndCondition", data);
                                                            }
                                                        }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-12'>
                                            <div><h4>Products</h4></div>
                                            {values.products.map((each, index) => (
                                                <div key={index} className={`border border-1 border-info mt-1 p-2 rounded-sm`}>
                                                    <div className='col-12 m-0 p-0 row'>
                                                        <div className="col-6 mb-2">
                                                            <label>Product</label>
                                                            <div className='input-group'>
                                                                <select
                                                                    defaultValue={each.product}
                                                                    className={!!touched.each?.product && !!errors.each?.product ? " form-select is-invalid" : " form-select"}
                                                                    name={`products[${index}].product`}
                                                                    value={each?.product}
                                                                    onChange={handleChange}
                                                                    onBlur={() => {
                                                                        let name = productlist.filter(f => f._id == each?.product)
                                                                        each.note = name[0]?.Description;
                                                                        // each.price = name[0]?.SalePrice;
                                                                        values.products[index] = each;
                                                                        setinitValue(values);
                                                                        genrateKeys();
                                                                    }}
                                                                >
                                                                    <option value="">Select Product</option>
                                                                    {productlist
                                                                        .map((x, i) => {
                                                                            if (each.product == x._id) {
                                                                                return <option selected key={i} value={x._id}>{x.Name}</option>;
                                                                            }
                                                                            return <option key={i} value={x._id}>{x.Name}</option>;
                                                                        })}
                                                                </select>
                                                                <span className="input-group-text">
                                                                    {each?.product ? (
                                                                        <i className='fa fa-pencil' onClick={() => { setproductval(each?.product); setproductadd(true) }} />
                                                                    ) : (
                                                                        <i className='fa fa-plus' onClick={() => { setproductval(0); setproductadd(true) }} />
                                                                    )}
                                                                    {productadd && (
                                                                        <ProductModal
                                                                            setproductadd={setproductadd}
                                                                            productadd={productadd}
                                                                            getproductpost={getproductpost}
                                                                            showTost={showTost}
                                                                            setproductval={setproductval}
                                                                            productval={productval}
                                                                        />)}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 mb-2">
                                                            <label>Note</label>
                                                            <textarea type="text" rows="3" placeholder="Notes"
                                                                value={each?.note}
                                                                onChange={handleChange}
                                                                defaultValue={each.note}
                                                                name={`products[${index}].note`}
                                                                onBlur={() => {
                                                                    TotalCalculator(values);
                                                                }}
                                                                className={!!touched.each?.note && !!errors.each?.note ? " form-control is-invalid" : " form-control"}
                                                            />
                                                        </div>
                                                        <div className="col-4 mb-2">
                                                            <label>Quantity</label>
                                                            <input type="text" placeholder="Quantity"
                                                                defaultValue={each?.quantity}
                                                                value={each?.quantity}
                                                                onChange={handleChange}
                                                                name={`products[${index}].quantity`}
                                                                onBlur={() => {
                                                                    TotalCalculator(values);
                                                                }}
                                                                className={!!touched.each?.quantity && !!errors.each?.quantity ? " form-control is-invalid" : " form-control"}
                                                            />
                                                        </div>
                                                        <div className="col-4 mb-2">
                                                            <label>Price</label>
                                                            <input type="text" placeholder="Price"
                                                                defaultValue={each?.price}
                                                                onChange={handleChange}
                                                                value={each.price}
                                                                name={`products[${index}].price`}
                                                                onBlur={() => {
                                                                    TotalCalculator(values);
                                                                }}
                                                                className={!!touched.each?.price && !!errors.each?.price ? " form-control is-invalid" : " form-control"} />
                                                        </div>
                                                        <div className="col-4 mb-2">
                                                            <label>Unit</label>
                                                            <div className='input-group'>
                                                                <select
                                                                    defaultValue={each.unit}
                                                                    onChange={handleChange}
                                                                    value={each?.unit}
                                                                    className={!!touched.products?.unit && !!errors.products?.unit ? " form-select is-invalid" : " form-select"}
                                                                    onBlur={() => {
                                                                        TotalCalculator(values);
                                                                    }}
                                                                    name={`products[${index}].unit`}>
                                                                    <option value="">Select Unit</option>
                                                                    {unitlist
                                                                        .map((x, i) => {
                                                                            if (each.unit == x._id) {
                                                                                return <option selected key={i} value={x._id}>{x.Name}</option>;
                                                                            }
                                                                            return <option key={i} value={x._id}>{x.Name}</option>;
                                                                        })}
                                                                </select>
                                                                <span className="input-group-text">
                                                                    {each?.unit ? (
                                                                        <i className='fa fa-pencil' onClick={() => { setunitval(each?.unit); setunitadd(true) }} />
                                                                    ) : (
                                                                        <i className='fa fa-plus' onClick={() => { setunitval(0); setunitadd(true) }} />
                                                                    )}
                                                                    {unitadd && (
                                                                        <UnitModal
                                                                            setunitadd={setunitadd}
                                                                            unitadd={unitadd}
                                                                            getunitpost={getunitpost}
                                                                            showTost={showTost}
                                                                            setunitval={setunitval}
                                                                            unitval={unitval}
                                                                        />)}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-4 mb-2">
                                                            <label>Amount</label>
                                                            <input type="text" placeholder="Amount"
                                                                defaultValue={each.TotalAmount}
                                                                onChange={handleChange}
                                                                value={each?.TotalAmount}
                                                                name={`products[${index}].TotalAmount`}
                                                                readOnly={true}
                                                                onBlur={() => {
                                                                    TotalCalculator(values);
                                                                }}
                                                                className={!!touched.products?.TotalAmount && !!errors.products?.TotalAmount ? " form-control is-invalid" : " form-control"}
                                                            />
                                                        </div>
                                                        <div className="col-4 mb-2">
                                                            <label>CGST(%)</label>
                                                            <input type="text" placeholder="CGST"
                                                                defaultValue={each.CGST}
                                                                value={each?.CGST}
                                                                name={`products[${index}].CGST`}
                                                                onChange={handleChange}
                                                                onBlur={() => {
                                                                    TotalCalculator(values);
                                                                }}
                                                                className={!!touched.products?.CGST && !!errors.products?.CGST ? " form-control is-invalid" : " form-control"}
                                                            />
                                                        </div>
                                                        <div className="col-4 mb-2">
                                                            <label>SGST(%)</label>
                                                            <input type="text" placeholder="SGST"
                                                                defaultValue={each.SGST}
                                                                value={each?.SGST}
                                                                onChange={handleChange}
                                                                name={`products[${index}].SGST`}
                                                                onBlur={() => {
                                                                    TotalCalculator(values);
                                                                }}
                                                                className={!!touched.products?.SGST && !!errors.products?.SGST ? " form-control is-invalid" : " form-control"}
                                                            />
                                                        </div>
                                                        <div className="col-4 mb-2">
                                                            <label>Final Amount</label>
                                                            <input type="text" placeholder="Final Amount"
                                                                defaultValue={each.FinalAmount}
                                                                onChange={handleChange}
                                                                value={each?.FinalAmount}
                                                                name={`products[${index}].FinalAmount`}
                                                                readOnly={true}
                                                                onBlur={() => {
                                                                    TotalCalculator(values);
                                                                }}
                                                                className={!!touched.products?.FinalAmount && !!errors.products?.FinalAmount ? " form-control is-invalid" : " form-control"}
                                                            />
                                                        </div>
                                                        <div className="col-4 mb-2">
                                                            <label>Dicount(%)</label>
                                                            <input type="text" placeholder="Dicount Percentage"
                                                                defaultValue={each.DicountPercentage}
                                                                value={each?.DicountPercentage}
                                                                name={`products[${index}].DicountPercentage`}
                                                                onChange={handleChange}
                                                                onBlur={() => {
                                                                    TotalCalculator(values);
                                                                }}
                                                                className={!!touched.products?.DicountPercentage && !!errors.products?.DicountPercentage ? " form-control is-invalid" : " form-control"}
                                                            />
                                                        </div>
                                                        <div className="col-4 mb-2">
                                                            <label>Discount Amount</label>
                                                            <input type="text" placeholder="Discount Amount"
                                                                defaultValue={each.DiscountAmmount}
                                                                onChange={handleChange}
                                                                value={each?.DiscountAmmount}
                                                                name={`products[${index}].DiscountAmmount`}
                                                                readOnly={true}
                                                                onBlur={() => {
                                                                    TotalCalculator(values);
                                                                }}
                                                                className={!!touched.products?.DiscountAmmount && !!errors.products?.DiscountAmmount ? " form-control is-invalid" : " form-control"}
                                                            />
                                                        </div>
                                                        <div className="col-8 mb-2">
                                                            <label>After Discount Amount</label>
                                                            <input type="text" placeholder="After Discount Amount"
                                                                defaultValue={each.AfterDiscountAmmount}
                                                                onChange={handleChange}
                                                                value={each?.AfterDiscountAmmount}
                                                                name={`products[${index}].AfterDiscountAmmount`}
                                                                readOnly={true}
                                                                onBlur={() => {
                                                                    TotalCalculator(values);
                                                                }}
                                                                className={!!touched.products?.AfterDiscountAmmount && !!errors.products?.AfterDiscountAmmount ? " form-control is-invalid" : " form-control"}
                                                            />
                                                        </div>
                                                        <div className="col-4 text-end mt-3">
                                                            <i className='fa fa-trash btn btn-sm btn-danger mt-4' onClick={() => { genrateKeys(); onDelete(index, values) }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                            <div className="row mb-1">
                                                <div className='col-12'>
                                                    <div className='mt-1'>
                                                        <button onClick={() => { genrateKeys(); AddMore(values) }} className='btn btn-primary btn-sm float-right'>
                                                            <i className='fa fa-plus mr-2'></i> Add Product
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="submit" className='btn btn-sm font-weight-bold btn-success mt-2'>
                                        <i className="fa fa-check" /> Save
                                    </button>
                                </Form>
                            )
                        }}
                    </Formik>
                </div>
            </CModal>

            <CModal alignment="center" visible={print}>
                <div className="modal-header">
                    <h4 className="modal-title">Select Template For Invoice</h4>
                    <button type="button" className="close" onClick={() => setprint(false)}>&times;</button>
                </div>
                <div className="modal-body">
                    <Formik
                        onSubmit={download}
                        initialValues={inittemplateValue}
                        validationSchema={templatevalidation}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            touched,
                            errors,
                            setFieldValue,
                        }) => {
                            return (
                                <Form
                                    onSubmit={handleSubmit}
                                    onChange={handleChange}>
                                    <div className="col-12 mb-1">
                                        <select
                                            defaultValue={values.template}
                                            className={!!touched.template && !!errors.template ? " form-select is-invalid" : " form-select"}
                                            name='template'>
                                            {template1.map((x, i) => {
                                                return (
                                                    <option key={i} value={x._id}>{x.Name}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    <div className='col-12 mt-2'>
                                        {downloadSpinner ?
                                            <div className={`loader-box ${styles.DownloadQuoteLoader}`}>
                                                <Spinner className={'loader-7'} />
                                            </div>
                                            :
                                            <button type="submit" className='btn btn-sm font-weight-bold btn-success'>
                                                <i className="fa fa-check" /> Save
                                            </button>
                                        }
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>
                </div>
            </CModal>
            <CModal alignment="center" visible={pdfmodal} size='lg'>
                <div className="modal-header">
                    <h4 className="modal-title">Viewer</h4>
                    <button type="button" className="close" onClick={() => { setPdfModal(false); }}>&times;</button>
                </div>
                <div className="modal-body" style={{ marginLeft: '75px' }}>
                    <div>
                        {pdfBlob && <PDFViewer blob={pdfBlob} />}
                        {!pdfBlob && <p>Loading...</p>}
                    </div>
                </div>
            </CModal>
        </div>
    );
}

export default Invoices;