import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Formik } from "formik";
import Select from "react-select";
import { Form } from "react-bootstrap";
import moment from "moment";
import { Post, Get } from '../../Layout/ajax';
import { CModal } from '@coreui/react';
import { immediateToast } from "izitoast-react";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { UserModal, StatusModal, TaskTypeModal } from './Modal.js';
import Taskconfiguration from './Taskconfiguration';

const validation = Yup.object().shape({
    Name: Yup.string().required(),
    Priority: Yup.string().required(),
    Status: Yup.string().required(),
});
const validationComment = Yup.object().shape({
    Taskcomment: Yup.string().required(),
});
const validationreason = Yup.object().shape({
    Reason: Yup.string().required(),
});
const Tasks = () => {
    const navigate = useNavigate();
    const [comment, setcomment] = useState(false);
    const [reason, setreason] = useState(false);
    const [initValueComment, setinitValueComment] = useState({
        Taskcomment: "",
    })
    const [initValuereason, setinitValuereason] = useState({
        Reason: "",
    })
    const [addtaskcomment, setaddtaskcomment] = useState([]);
    const [TaskType, setTaskType] = useState([]);
    const [initValue, setinitValue] = useState({
        Name: "",
        TaskType: "",
        subTaskName: "",
        Priority: "",
        Assign: "",
        Status: "",
        Description: "",
        StartDate: null,
        EndDate: null,
        Reporter: [],
    });
    const location = useLocation();
    const [addtask, setaddtask] = useState(false);
    const [users, setusers] = useState([]);
    const [Status, setStatus] = useState([]);
    const [inbox, setinbox] = useState([]);
    const [outbox, setoutbox] = useState([]);
    const [filteruser, setfilteruser] = useState(JSON.parse(window.localStorage.getItem('Id')));
    const [filterstatus, setfilterstatus] = useState("");
    const [formkey, setFormKey] = useState(Math.random() * 1000000);
    const [statusadd, setstatusadd] = useState(false);
    const [statusval, setstatusval] = useState(0);
    const [useradd, setuseradd] = useState(false);
    const [userval, setuserval] = useState(0);
    const [TaskTypeadd, setTaskTypeAdd] = useState(false);
    const [TaskTypeVal, setTasktypeVal] = useState(0);
    const [task, settask] = useState(0);
    const showTost = (msg, theme) => {
        immediateToast(theme, {
            message: msg,
            timeout: 3000
        });
    }
    const taskcommit = async (val) => {
        settask(val);
        await Post(`task/getAlltaskcomment`, { "taskId": val })
            .then((res) => {
                setaddtaskcomment(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    useEffect(() => {
        getuserpost();
        getStatus();
        getTaskType();
    }, []);
    useEffect(() => {
        getpost(true);
    }, [filteruser, filterstatus]);
    
    const getTaskType = async (val) => {
        Post(`master/getTaskType`, { active: true })
            .then((res) => {
                setTaskType(res.data?.filter(f => f.GroupName == "Tasks"));
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const getpost = async (val) => {
        await Post(`task/getAll`, { active: val, user: filteruser, status: filterstatus })
            .then((res) => {
                setinbox(res.data.Inbox);
                setoutbox(res.data.Outbox);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const getStatus = async (val) => {
        Post(`master/getStatus`, { active: true })
            .then((res) => {
                if (location.state) {
                    setfilterstatus(location.state);
                }
                setStatus(res.data?.filter(f => f.GroupName == "Tasks"));
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const getuserpost = async (val) => {
        Post(`users/getAllUser`, { active: true })
            .then((res) => {
                setusers(res)
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const clear = () => {
        setinitValue({
            Name: "",
            TaskType: "",
            subTaskName: "",
            Priority: "",
            Assign: "",
            Status: "",
            Description: "",
            StartDate: null,
            EndDate: null,
            Reporter: [],
        });
    }
    const getbyid = async (val) => {
        if (val != 0) {
            Get(`task/getById/${val}`)
                .then((res) => {
                    setinitValue({
                        Name: res.data.Name,
                        TaskType: res.data.TaskType,
                        subTaskName: res.data.subTaskName,
                        Priority: res.data.Priority,
                        Description: res.data.Description,
                        Assign: res.data.Assign,
                        Status: res.data.Status,
                        StartDate: moment(res.data.StartDate).format("YYYY-MM-DDTHH:mm"),
                        EndDate: moment(res.data.EndDate).format("YYYY-MM-DDTHH:mm"),
                        Reporter: res.data.Reporter,
                    });
                    setaddtask(true);
                    settask(val);
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
    }
    const removetask = async (val) => {
        Swal.fire({
            title: "Are you sure want to delete ?",
            icon: "error",
            confirmButtonText: "Delete",
            confirmButtonColor: "#FD711A",
            showCancelButton: true,
            cancelButtonColor: "#0F3E6A",
        }).then(async ({ isConfirmed }) => {
            if (isConfirmed) {
                await Post(
                    `task/remove/${val}`
                )
                    .then(res => {
                        if (res.success == false) {
                            showTost(res.msg, "warning");
                        } else {
                            getpost(true);
                        }
                    })
                    .catch((err) => {
                        showTost("SOMETHING WENT WRONG ON SERVER", "error");
                    });
            }
        });
    };
    const handleSubmit = async (val) => {
        await Post(`task/add`, val)
            .then((res) => {
                if (res.success == true) {
                    clear();
                    setaddtask(false);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            getpost(true);
                        }
                    });
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const handleEdit = async (val) => {
        val.id = task;
        await Post(`task/edit`, val)
            .then((res) => {
                if (res.success == true) {
                    clear();
                    setaddtask(false);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            getpost(true);
                        }
                    });
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const Commentclear = () => {
        setinitValueComment({
            Taskcomment: "",
        });
    }
    const commenthandleSubmit = async (val) => {
        val.taskId = task;
        await Post(`task/addtaskcomment`, val)
            .then((res) => {
                if (res.success == true) {
                    showTost("Your data has been Successfully added", "success");
                    taskcommit(task); getpost(true);
                    genrateKeys();
                    Commentclear();
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const reasonhandleSubmit = async (val) => {
        val.taskId = task;
        await Post(`task/taskreason`, val)
            .then((res) => {
                if (res.success == true) {
                    showTost("Your data has been Successfully added", "success");
                    getpost(true);
                    genrateKeys();
                    setinitValuereason({
                        Reason: "",
                    });
                    setreason(false)
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const genrateKeys = () => {
        setFormKey(Math.random() * 1000000);
    }
    const [configuration, setconfiguration] = useState(false)
    const changestatus = (detvalues) => {
        let values = { ...detvalues };
        let name = Status.filter(f => f._id == values.Status)
        values.Assign = name[0]?.Assign;
        setinitValue(values);
        genrateKeys();
    }
    return (
        <div className='content-wrapper'>
            <div className="row my-2">
                <div className="col-6">
                    <h2>Tasks</h2>
                </div>
                <div className="col-6">
                    <div className="float-right">
                        <div className='d-flex'>
                            <span className='btn btn-sm btn-warning mr-2 float-right' onClick={() => { setaddtask(true); settask(0); }}>
                                <i className="fa fa-plus mr-2" />
                                Add Task
                            </span>
                            <span className='btn btn-sm btn-warning float-right' onClick={() => { setconfiguration(true); }}>
                                Configuration
                            </span>
                        </div>
                    </div>
                </div>
                <Taskconfiguration
                    Status={Status}
                    configuration={configuration}
                    setconfiguration={setconfiguration}
                    getpost={getpost}
                    getStatus={getStatus}
                    groupName="Tasks"
                />
                <div className="col-12">
                    <span className="float-right">
                        <div className="row">
                            <div className="col-auto">
                                {JSON.parse(window.localStorage.getItem('RoleName')) == "SuperAdmin" || JSON.parse(window.localStorage.getItem('RoleName')) == "Admin" ?
                                    <select className="form-select" value={filteruser} onChange={(e) => setfilteruser(e.target.value)} >
                                        {users
                                            .map((x, i) => (
                                                <option key={i} value={x._id}>{x.name}</option>
                                            ))}
                                    </select>
                                    : ""}
                            </div>
                            <div className="col-auto">
                                <select className="form-select" value={filterstatus} onChange={(e) => setfilterstatus(e.target.value)} >
                                    <option value="">All</option>
                                    {Status
                                        .map((x, i) => (
                                            <option key={i} value={x._id}>{x.Name}</option>
                                        ))}
                                </select>
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div className="row overall-report m-0">
                <div className="col-lg-6 col-md-12 pr-3">
                    <div className='row my-2'>
                        <div title="Tasks for Me" className="mb-3 px-0"><h4> Inbox </h4></div>
                        {inbox.map((x, i) =>
                            <div className='boxShadow mb-3 p-3'>
                                <div className="row">
                                    <div className="col-8">
                                        <h6 className="card-title f-16 mb-2 text-capitalize">{x.Name}</h6>
                                        <div className="row mb-1">
                                            <h6 className='col-4 font-weight-normal f-13 text-secondary'>Task Type </h6>
                                            <h6 className='col-8 f-13 text-capitalize'> {x.TaskType?.Name}</h6>
                                        </div>
                                        <div className="row mb-1">
                                            <h6 className='col-4 font-weight-normal f-13 text-secondary'>Sub Task </h6>
                                            <h6 className='col-8 f-13 text-capitalize'> {x.subTaskName}</h6>
                                        </div>
                                        <div className="row mb-1">
                                            <h6 className='col-4 font-weight-normal f-13 text-secondary'>Status </h6>
                                            <h6 className='col-8 f-13' style={{ color: x.Status?.Color }}> {x.Status?.Name}</h6>
                                        </div>
                                        {
                                            x.EndDate === null ? " " : moment(x.EndDate).isBefore(moment()) && x.Reason ? (
                                                <div className='row mb-1'>
                                                    <h6 className='col-4 font-weight-normal f-13 text-secondary'>End Date</h6>
                                                    <h6 className='col-8 text-danger f-13'>
                                                        {moment(x.EndDate).format('lll')}
                                                    </h6>
                                                </div>
                                            ) : moment(x.EndDate).isBefore(moment()) ? (
                                                <div className='row mb-1'>
                                                    <h6 className='col-4 font-weight-normal f-13 text-secondary'>End Date</h6>
                                                    <h6 className='col-8 text-danger f-13'>
                                                        {moment(x.EndDate).format('lll')}
                                                        <i className="fa fa-clock-o btn btn-light btn-sm ml-1" title="add late reason" onClick={() => { settask(x._id); setreason(true) }} />
                                                    </h6>
                                                </div>
                                            ) : (
                                                <div className='row mb-1'>
                                                    <h6 className='col-4 font-weight-normal f-13 text-secondary'>End Date</h6>
                                                    <h6 className="col-8 font-weight-normal f-13 text-secondary">
                                                        {moment(x.EndDate).format('lll')}
                                                    </h6>
                                                </div>
                                            )
                                        }
                                        <div className="row mb-1">
                                            <h6 className='col-4 font-weight-normal f-13 text-secondary'>Assign to</h6>
                                            <h6 className='col-8 text-dark f-13 text-capitalize'> {x.Assign?.name}</h6>
                                        </div>
                                        <div className="row mb-1">
                                            <h6 className='col-4 font-weight-normal f-13 text-secondary'>Created By</h6>
                                            <h6 className='col-8 font-weight-normal f-13 text-capitalize text-secondary'> {x.addedBy?.name}</h6>
                                        </div>
                                        {x.Reason ? (
                                            <div className="row mb-1 ">
                                                <h6 className="col-4 font-weight-normal f-13 text-secondary">Reason</h6>
                                                <h6 className="col-8 font-weight-normal f-13 text-capitalize text-secondary">{x.Reason}</h6>
                                            </div>
                                        ) : null}
                                        {x.ProspectId?._id ? (
                                            <div className="d-flex row mb-1" onClick={() =>
                                                navigate('/Prospects', { state: { ProspectId: x.ProspectId?._id } })}>
                                                <h6 className="col-4 font-weight-normal f-13 text-secondary">Task Comapny</h6>
                                                <h6 className="col-8 font-weight-normal f-13 text-capitalize text-secondary">{x.ProspectId?.Company} </h6>
                                            </div>
                                        ) : " "
                                        }
                                        {x.LeadId?._id ? (
                                            <div className="d-flex row mb-1" onClick={() => navigate('/Leads', { state: { leadId: x.LeadId?._id } })}>
                                                <h6 className="col-4 font-weight-normal f-13 text-secondary">Task Comapny</h6>
                                                <h6 className="col-8 font-weight-normal f-13 text-capitalize text-secondary">{x.LeadId?.Company} </h6>
                                            </div>
                                        ) : " "
                                        }
                                        {x.LastComment?.TaskComment ? (
                                            <div className="row mb-1">
                                                <h6 className='col-4 font-weight-normal f-13 text-secondary'>Last Comment</h6>
                                                <h6 className='col-8 font-weight-normal f-13 text-capitalize text-secondary'> {x.LastComment?.TaskComment}</h6>
                                            </div>
                                        ) : " "
                                        }
                                    </div>

                                    <div className="col-4">
                                        {JSON.parse(window.localStorage.getItem('RoleName')) == "SuperAdmin" || JSON.parse(window.localStorage.getItem('RoleName')) == "Admin" ?
                                            <div className="d-flex">
                                                <span className="ml-auto">
                                                    <i className={x.LastComment == null ? 'fa fa-comment-o btn btn-light btn-sm' : 'fa fa-comment-o btn btn-danger btn-sm'} onClick={() => { taskcommit(x._id); settask(x._id); setcomment(true) }} />
                                                    <i className='btn btn-sm btn-success fa fa-pencil ml-2' onClick={() => getbyid(x._id)} />
                                                    <i className='btn btn-danger btn-sm fa fa-trash ml-2' onClick={() => removetask(x._id)} /></span>
                                            </div> : moment(x.EndDate).isBefore(moment()) ? "" : <div className="d-flex">
                                                <span className="ml-auto">
                                                    <i className={x.LastComment == null ? 'fa fa-comment-o btn btn-light btn-sm' : 'fa fa-comment-o btn btn-danger btn-sm'} onClick={() => { taskcommit(x._id); settask(x._id); setcomment(true) }} />
                                                    <i className='btn btn-sm btn-success fa fa-pencil ml-2' onClick={() => getbyid(x._id)} />
                                                    <i className='btn btn-danger btn-sm fa fa-trash ml-2' onClick={() => removetask(x._id)} /></span>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="col-lg-6 col-md-12 pl-3">
                    <div className='row my-2'>
                        <div title="Tasks for Me" className="mb-3 pl-0"><h4> Outbox </h4></div>
                        {outbox.map((x, i) =>
                            <div className='boxShadow mb-3 p-3'>
                                <div className="row">
                                    <div className="col-8">
                                        <h6 className="card-title f-16 mb-2 text-capitalize">{x.Name}</h6>
                                        <div className="row mb-1">
                                            <h6 className='col-4 font-weight-normal f-13 text-secondary'>Task Type </h6>
                                            <h6 className='col-8 f-13 text-capitalize'> {x.TaskType?.Name}</h6>
                                        </div>
                                        <div className="row mb-1">
                                            <h6 className='col-4 font-weight-normal f-13 text-secondary'>Sub Task </h6>
                                            <h6 className='col-8 f-13 text-capitalize'> {x.subTaskName}</h6>
                                        </div>
                                        <div className="row mb-1">
                                            <h6 className='col-4 font-weight-normal f-13 text-secondary'>Status </h6>
                                            <h6 className='col-8 f-13 text-capitalize' style={{ color: x.Status?.Color }}> {x.Status?.Name}</h6>
                                        </div>
                                        {
                                            x.EndDate === null ? " " : moment(x.EndDate).isBefore(moment()) && x.Reason ?
                                                <div className='row mb-1'>
                                                    <h6 className='col-4 font-weight-normal f-13 text-secondary'>End Date</h6>
                                                    <h6 className='col-8 text-danger f-13'>{moment(x.EndDate).format('lll')}</h6>
                                                </div>
                                                :
                                                moment(x.EndDate).isBefore(moment()) ?
                                                    <div className='row mb-1'>
                                                        <h6 className='col-4 font-weight-normal f-13 text-secondary'>End Date</h6>
                                                        <h6 className='col-8 text-danger f-13'>{moment(x.EndDate).format('lll')}<i className="fa fa-clock-o btn btn-light btn-sm ml-1" title="add late reason" onClick={() => { settask(x._id); setreason(true) }} /></h6>
                                                    </div>
                                                    :
                                                    <div className='row mb-1'>
                                                        <h6 className='col-4 font-weight-normal f-13 text-secondary'>End Date</h6>
                                                        <h6 className='col-8 font-weight-normal f-13 text-secondary'> {moment(x.EndDate).format('lll')}</h6>
                                                    </div>
                                        }
                                        <div className="row mb-1">
                                            <h6 className='col-4 font-weight-normal f-13 text-secondary'>Assign to</h6>
                                            <h6 className='col-8 f-13 text-dark text-capitalize'> {x.Assign?.name}</h6>
                                        </div>
                                        <div className="row mb-1">
                                            <h6 className='col-4 font-weight-normal f-13 text-secondary'>Created By</h6>
                                            <h6 className='col-8 font-weight-normal f-13 text-capitalize text-secondary'> {x.addedBy?.name}</h6>
                                        </div>
                                        {x.Reason ? (
                                            <div className="row mb-1">
                                                <h6 className="col-4 font-weight-normal f-13 text-secondary">Reason</h6>
                                                <h6 className="col-8 font-weight-normal f-13 text-capitalize text-secondary">{x.Reason}</h6>
                                            </div>
                                        ) : null
                                        }
                                        {x.ProspectId?._id ? (
                                            <div className='row mb-1' onClick={() => navigate('/Prospects', { state: { ProspectId: x.ProspectId?._id } })}>
                                                <h6 className="col-4 font-weight-normal f-13 text-secondary">Task Comapny </h6>
                                                <h6 className="col-8 font-weight-normal f-13 text-capitalize text-secondary">{x.ProspectId?.Company} </h6>
                                            </div>
                                        ) : " "
                                        }
                                        {x.LeadId?._id ? (
                                            <div className='row mb-1' onClick={() => navigate('/Leads', { state: { leadId: x.LeadId?._id } })}>
                                                <h6 className="col-4 font-weight-normal f-13 text-secondary">Task Comapny</h6>
                                                <h6 className="col-8 font-weight-normal f-13 text-capitalize text-secondary">{x.LeadId?.Company}</h6>
                                            </div>
                                        ) : " "
                                        }
                                        {x.LastComment?.TaskComment ? (
                                            <div className="row mb-1">
                                                <h6 className='col-4 font-weight-normal f-13 text-secondary'>Last Comment</h6>
                                                <h6 className='col-8 font-weight-normal f-13 text-capitalize text-secondary'> {x.LastComment?.TaskComment}</h6>
                                            </div>
                                        ) : " "
                                        }
                                    </div>
                                    <div className="col-4">
                                        {JSON.parse(window.localStorage.getItem('RoleName')) == "SuperAdmin" || JSON.parse(window.localStorage.getItem('RoleName')) == "Admin" ?
                                            <div className="d-flex">
                                                <span className="ml-auto">
                                                    <i className={x.LastComment == null ? 'fa fa-comment-o btn btn-light btn-sm' : 'fa fa-comment-o btn btn-danger btn-sm'} onClick={() => { taskcommit(x._id); settask(x._id); setcomment(true) }} />
                                                    <i className='btn btn-sm btn-success fa fa-pencil ml-2' onClick={() => getbyid(x._id)} />
                                                    <i className='btn btn-danger btn-sm fa fa-trash ml-2' onClick={() => removetask(x._id)} /></span>
                                            </div> : moment(x.EndDate).isBefore(moment()) ? "" : <div className="d-flex">
                                                <span className="ml-auto">
                                                    <i className={x.LastComment == null ? 'fa fa-comment-o btn btn-light btn-sm' : 'fa fa-comment-o btn btn-danger btn-sm'} onClick={() => { taskcommit(x._id); settask(x._id); setcomment(true) }} />
                                                    <i className='btn btn-sm btn-success fa fa-pencil ml-2' onClick={() => getbyid(x._id)} />
                                                    <i className='btn btn-danger btn-sm fa fa-trash ml-2' onClick={() => removetask(x._id)} /></span>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <CModal alignment="center" size="lg" visible={addtask}>
                <div className="modal-header">
                    <h4 className="modal-title">{task == 0 ? "Add Task" : "Update Task"}</h4>
                    <button type="button" className="close" onClick={() => { clear(); setaddtask(false) }}>&times;</button>
                </div>
                <div className="modal-body">
                    <Formik
                        onSubmit={task == 0 ? handleSubmit : handleEdit}
                        initialValues={initValue}
                        validationSchema={validation}
                        key={formkey}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            touched,
                            errors,
                            setFieldValue,
                        }) => {
                            return (
                                <Form
                                    onSubmit={handleSubmit}
                                    onChange={handleChange}>
                                    <div className="col-12 mb-2">
                                        <div className="row">
                                            <div className='col-12 mt-2'>
                                                <label>Name</label>
                                                <input type='text' name="Name" defaultValue={values.Name} placeholder='Enter Task' className={!!touched.Name && !!errors.Name ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className='col-6 mt-2'>
                                                <label>Task Type</label>
                                                <div className='input-group'>
                                                    <select name="TaskType" defaultValue={values.TaskType} onChange={handleChange} className={!!touched.TaskType && !!errors.TaskType ? "form-select is-invalid" : "form-select"}>
                                                        <option>Select Task Type</option>
                                                        {TaskType
                                                            .map((x, i) => (
                                                                <option key={i} value={x._id}>{x.Name}</option>
                                                            ))}
                                                    </select>
                                                    <span className="input-group-text">
                                                        {values.TaskType ? (
                                                            <i className='fa fa-pencil' onClick={() => { setTasktypeVal(values.TaskType); setTaskTypeAdd(true); }} />
                                                        ) : (
                                                            <i className='fa fa-plus' onClick={() => { setTasktypeVal(0); setTaskTypeAdd(true); }} />
                                                        )}
                                                        {TaskTypeadd && (
                                                            <TaskTypeModal
                                                                TaskTypeadd={TaskTypeadd}
                                                                setTaskTypeAdd={setTaskTypeAdd}
                                                                getTaskType={getTaskType}
                                                                showTost={showTost}
                                                                TaskTypeVal={TaskTypeVal}
                                                                setTasktypeVal={setTasktypeVal}
                                                            />
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className='col-6 mt-2'>
                                                <label>Sub Task Name</label>
                                                <input type='text' name="subTaskName" defaultValue={values.subTaskName} placeholder='Enter Task' className={!!touched.subTaskName && !!errors.subTaskName ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className='col-6 mt-2'>
                                                <label>Status</label>
                                                <div className='input-group'>
                                                    <select name="Status" defaultValue={values.Status} onChange={handleChange} onBlur={() => { changestatus(values) }} className={!!touched.Status && !!errors.Status ? "form-select is-invalid" : "form-select"}>
                                                        <option>Select Status</option>
                                                        {Status
                                                            .map((x, i) => (
                                                                <option key={i} value={x._id}>{x.Name}</option>
                                                            ))}
                                                    </select>
                                                    <span className="input-group-text">
                                                        {values.Status ? (
                                                            <i className='fa fa-pencil' onClick={() => { setstatusval(values.Status); setstatusadd(true); }} />
                                                        ) : (
                                                            <i className='fa fa-plus' onClick={() => { setstatusval(0); setstatusadd(true); }} />
                                                        )}
                                                        {statusadd && (
                                                            <StatusModal
                                                                statusadd={statusadd}
                                                                setstatusadd={setstatusadd}
                                                                getStatus={getStatus}
                                                                showTost={showTost}
                                                                statusval={statusval}
                                                                setstatusval={setstatusval}
                                                            />
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className='col-6 mt-2'>
                                                <label><i className="fa fa-user text-secondary mr-2" />Assign to :</label>
                                                <div className='input-group'>
                                                    <select name="Assign" defaultValue={values.Assign} className={!!touched.Assign && !!errors.Assign ? "form-select is-invalid" : "form-select"}>
                                                        <option>Select Assign</option>
                                                        {users
                                                            .map((x, i) => (
                                                                <option key={i} value={x._id}>{x.name}</option>
                                                            ))}
                                                    </select>
                                                    <span className="input-group-text">
                                                        {values.Assign ? (
                                                            <i className='fa fa-pencil' onClick={() => { setuserval(values.Assign); setuseradd(true) }} />
                                                        ) : (
                                                            <i className='fa fa-plus' onClick={() => { setuserval(0); setuseradd(true) }} />
                                                        )}
                                                        {useradd && (
                                                            <UserModal
                                                                useradd={useradd}
                                                                setuseradd={setuseradd}
                                                                getuserpost={getuserpost}
                                                                showTost={showTost}
                                                                userval={userval}
                                                                setuserval={setuserval}
                                                            />
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className='col-6 mt-2'>
                                                <label>Priority</label>
                                                <select name="Priority" defaultValue={values.Priority} className={!!touched.Priority && !!errors.Priority ? "form-select is-invalid" : "form-select"}>
                                                    <option value="">Select Priority</option>
                                                    <option>High</option>
                                                    <option>Normal</option>
                                                    <option>Low</option>
                                                </select>
                                            </div>
                                            <div className='col-6 mt-2'>
                                                <label>Start Date</label>
                                                <input type="datetime-local" placeholder="Date" defaultValue={values.StartDate} name='StartDate' className={!!touched.StartDate && !!errors.StartDate ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className='col-6 mt-2'>
                                                <label>End Date</label>
                                                <input type="datetime-local" placeholder="Date" defaultValue={values.EndDate} name='EndDate' className={!!touched.EndDate && !!errors.EndDate ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className='col-6 mt-2'>
                                                <label>Report To</label>
                                                <Select
                                                    isMulti
                                                    name="Reporter"
                                                    value={
                                                        users.filter(
                                                            (x) => {
                                                                if (values.Reporter && values.Reporter.length) {
                                                                    return values.Reporter.indexOf(x._id) != -1;
                                                                } else {
                                                                    return values.Reporter == x._id;
                                                                }
                                                            }
                                                        )
                                                            .map((x) => ({
                                                                value: x._id,
                                                                label: x.name,
                                                            }))}
                                                    onChange={(e) =>
                                                        setFieldValue(
                                                            "Reporter",
                                                            e.map((x) => x.value)
                                                        )
                                                    }
                                                    options={users.map((x) => ({
                                                        value: x._id,
                                                        label: x.name,
                                                    }))}
                                                    className={`slectname ${touched.Reporter && errors.Reporter ? "is-invalid" : ""
                                                        }`}
                                                // className={touched.Reporter && errors.Reporter ? "is-invalid" : ""}
                                                />
                                                {/* <Select
                                                    isMulti
                                                    name="Reporter"
                                                    defaultValue={values.Reporter}
                                                    onChange={(e) => {
                                                        setFieldValue("Reporter", e);
                                                    }}
                                                    options={users.map((user) => ({ value: user._id, label: user.name }))}
                                                    className={touched.Reporter && errors.Reporter ? "is-invalid" : ""}
                                                /> */}
                                            </div>
                                            <div className='col-12 mt-2'>
                                                <label>Description</label>
                                                <textarea type='text' rows="3" defaultValue={values.Description} name="Description" placeholder='Enter Description' className={!!touched.Description && !!errors.Description ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className='col-12 mt-2'>
                                                <button type="submit" className='btn font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </CModal>
            <CModal alignment="center" visible={reason}>
                <div className="modal-header">
                    <h4 className="modal-title">Add Reason</h4>
                    <button type="button" className="close" onClick={() => setreason(false)}>&times;</button>
                </div>
                <div className="modal-body">
                    <div class="row">
                        <Formik
                            onSubmit={reasonhandleSubmit}
                            initialValues={initValuereason}
                            validationSchema={validationreason}
                            key={formkey} >
                            {({
                                handleSubmit,
                                handleChange,
                                values,
                                touched,
                                errors,
                                setFieldValue,
                            }) => {
                                return (
                                    <Form
                                        onSubmit={handleSubmit}
                                        onChange={handleChange}>
                                        <div className="col-12 mb-1">
                                            <label>Reason</label>
                                            <input type="text" placeholder='Add Reason' name="Reason" defaultValue={values.Reason}
                                                className={!!touched.Reason && !!errors.Reason ? "form-control is-invalid" : "form-control"}
                                            />
                                        </div>
                                        <div className='col-12 mt-2'>
                                            <button type="submit" className='btn font-weight-bold btn-success'>
                                                <i className="fa fa-check" /> Save
                                            </button>
                                        </div>
                                    </Form>)
                            }}
                        </Formik>
                    </div>
                </div>
            </CModal>
            <CModal alignment="center" size="lg" visible={comment}>
                <div className="modal-header">
                    <h4 className="modal-title">Add Task Comments</h4>
                    <button type="button" className="close" onClick={() => setcomment(false)}>&times;</button>
                </div>
                <div className="modal-body">
                    <div class="row">
                        <div className='col-lg-6 p-0'>
                            <Formik
                                onSubmit={commenthandleSubmit}
                                initialValues={initValueComment}
                                validationSchema={validationComment}
                                key={formkey} >
                                {({
                                    handleSubmit,
                                    handleChange,
                                    values,
                                    touched,
                                    errors,
                                    setFieldValue,
                                }) => {
                                    return (
                                        <Form
                                            onSubmit={handleSubmit}
                                            onChange={handleChange}>
                                            <div className="col-12 mb-1">
                                                <label>Comment</label>
                                                <input type="text" placeholder='Add Comment' name="Taskcomment" defaultValue={values.Taskcomment}
                                                    className={!!touched.Taskcomment && !!errors.Taskcomment ? "form-control is-invalid" : "form-control"}
                                                />
                                            </div>
                                            <div className='col-12 mt-2'>
                                                <button type="submit" className='btn font-weight-bold btn-success'>
                                                    <i className="fa fa-check" /> Save
                                                </button>
                                            </div>
                                        </Form>)
                                }}
                            </Formik>
                        </div>
                        <div className='col-lg-6 pl-0'>
                            {addtaskcomment && addtaskcomment.map((value, idx) => {
                                return (
                                    <div key={idx} className="border mb-2 py-2" style={{ borderBottom: "1px solid lightgrey" }}>
                                        <div className='d-flex mb-1 px-3'>
                                            <span><i class="fa fa-comment-o text-primary"></i></span>
                                            <span className='ml-2'>{value.TaskComment}</span>
                                        </div>
                                        <div className='d-flex mb-1 px-3'>
                                            <span><i class="fa fa-user-o text-primary"></i></span>
                                            <span className='ml-2 pl-1'>{value.addedBy.name}</span>
                                        </div>
                                        <div className='d-flex mb-1 px-3'>
                                            <span><i class="fa fa-calendar-o text-primary"></i></span>
                                            <span className='ml-2 pl-1'>{moment(value.createdAt).format('lll')}</span>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </CModal>
        </div>
    );
}

export default Tasks;