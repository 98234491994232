import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { immediateToast } from "izitoast-react";
import { Post, Get } from '../../Layout/ajax';
import { CModal } from '@coreui/react';
import * as Yup from "yup";
import Swal from "sweetalert2";

const createuservalidation = Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().email().required(),
    mobileNo: Yup.string().required('Mobile number is required'),
    password: Yup.string().required(),
    role: Yup.string().required(),
    confirmpassword: Yup.string().required()
        .oneOf([Yup.ref('password')], 'Passwords must and should match'),
});
const updateuservalidation = Yup.object().shape({
    name: Yup.string().required(),
    role: Yup.string().required(),
});
const User = () => {
    const [data, setData] = useState([]);
    const [useradd, setuseradd] = useState(false);
    const [user, setuser] = useState(0);
    const [role, setrole] = useState([]);
    const [createuserinitValue, setcreateuserinitValue] = useState({
        name: "",
        mobileNo: "",
        email: "",
        password: "",
        role: "",
        confirmpassword: ""
    });
    const [updateuserinitValue, setupdateuserinitValue] = useState({
        name: "",
        role: "",
    });
    useEffect(() => {
        getpost(true);
        Post(`master/getRole`, { active: true })
            .then((res) => {
                setrole(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }, []);
    const showTost = (msg, theme) => {
        immediateToast(theme, {
            message: msg,
            timeout: 3000
        });
    }
    const getpost = async (val) => {
        await Post(`users/getAllUser`, { active: val })
            .then((res) => {
                setData(res);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const columns = [
        {
            name: "Name",
            selector: (row) => row.name,
        },
        {
            name: "Mobile No",
            selector: (row) => row.mobileNo,
        },
        {
            name: "Email",
            selector: (row) => row.email,
        },
        {
            name: "Role",
            selector: (row) => row.role?.Name,
        },
        {
            name: "Status",
            selector: (row) => <input type='checkbox' value={row.is_active} checked={row.is_active == true ? "checked" : ""} onClick={() => { row.is_active == true ? changeStatus(row._id, false) : changeStatus(row._id, true) }} />,
        },
        {
            name: "",
            selector: (row) => row.is_active == true ? <i className='btn btn-sm btn-success fa fa-pencil' onClick={() => { get(row._id); }} /> : "",
        },
    ];
    const changeStatus = async (val, status) => {
        await Post(`users/removeUser`, { id: val, active: status })
            .then((res) => {
                if (res.success == true) {
                    if (status == true) {
                        getpost(false);
                        showTost("Your data has been successfully activated", "success");
                    }
                    else {
                        getpost(true);
                        showTost("Your data has been successfully deactivated", "success");
                    }
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const get = async (val) => {
        if (val != 0) {
            Get(`users/${val}`)
                .then((res) => {
                    setupdateuserinitValue({
                        name: res.name,
                        role: res.role,
                        mobileNo: res.mobileNo
                    });
                    setuseradd(true);
                    setuser(val);
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
    }
    const create = async (val) => {
        await Post(`users/add`, val)
            .then((res) => {
                if (res.token !== undefined) {
                    setuseradd(false);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            getpost(true);
                        }
                    });
                }
                else if (res.message = "User Already Exists!") {
                    showTost("User Already Exists!", "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const update = async (val) => {
        val.id = user;
        await Post(`users/updateUser`, val)
            .then((res) => {
                setuseradd(false);
                Swal.fire({
                    title: "Your work has been saved",
                    icon: "success",
                    confirmButtonText: "OK",
                    confirmButtonColor: "#FD711A",
                }).then(async ({ isConfirmed }) => {
                    if (isConfirmed) {
                        getpost(true);
                    }
                });
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    return (
        <div className='content-wrapper'>
            <div className="mb-2 mt-2 row">
                <div className="col-10">
                    <h2>User</h2>
                </div>
                <div className="col-2 mt-2">
                    <span className='btn btn-sm btn-warning float-right' onClick={() => { setuseradd(true); setuser(0); }}>
                        <i className="fa fa-plus mr-2" />
                        Add User
                    </span>
                </div>
                <div className="col-12">
                    <div className="custom-control custom-switch float-right">
                        <input type="checkbox" className="custom-control-input" defaultChecked id="customSwitch1" data-check_true={true} data-check_false={false} onChange={(event) => {
                            let ele = event.target;
                            let selectedId = ele.checked ? event.target.getAttribute("data-check_true") : event.target.getAttribute("data-check_false");
                            getpost(selectedId);
                        }} />
                        <label className="custom-control-label" for="customSwitch1"> Active/Inactive </label>
                    </div>
                </div>
            </div>
            <div className="row ">
                <div className="col-12 mt-3">
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                    />
                </div>
            </div>
            <CModal alignment="center" visible={useradd}>
                <div className="modal-header">
                    <h4 className="modal-title">{user == 0 ? "Add User" : "Update User"}</h4>
                    <button type="button" className="close" onClick={() => setuseradd(false)}>&times;</button>
                </div>
                <div className="modal-body">
                    <Formik
                        onSubmit={user == 0 ? create : update}
                        initialValues={user == 0 ? createuserinitValue : updateuserinitValue}
                        validationSchema={user == 0 ? createuservalidation : updateuservalidation}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            touched,
                            errors,
                            setFieldValue,
                        }) => {
                            return (
                                <Form
                                    onSubmit={handleSubmit}
                                    onChange={handleChange}>
                                    <div className='mt-2'>
                                        <label>User Name</label>
                                        <input type='text' name="name" defaultValue={values.name} placeholder='Enter User Name' className={!!touched.name && !!errors.name ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='mt-2'>
                                        <label>Mobile Number</label>
                                        <input type='text' name="mobileNo" defaultValue={values.mobileNo} placeholder='Enter User Mobile No' className={!!touched.mobileNo && !!errors.mobileNo ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    {user == 0 ? <>
                                        <div className='mt-2'>
                                            <label>User Email</label>
                                            <input type='email' name="email" placeholder='Enter User Email' className={!!touched.email && !!errors.email ? "form-control is-invalid" : "form-control"} />
                                        </div>
                                        <div className='mt-2'>
                                            <label>Password</label>
                                            <input type='password' name="password" placeholder='Enter Password' className={!!touched.password && !!errors.password ? "form-control is-invalid" : "form-control"} />
                                        </div>
                                        <div className='mt-2'>
                                            <label>Confirm Password</label>
                                            <input type='password' name="confirmpassword" placeholder='Enter Confirm Password' className={!!touched.confirmpassword && !!errors.confirmpassword ? "form-control is-invalid" : "form-control"} />
                                        </div>
                                    </> : <></>}
                                    <div className='mt-2'>
                                        <label>Role</label>
                                        <select defaultValue={values.role} className={!!touched.role && !!errors.role ? "form-select is-invalid" : "form-select"} name='role'>
                                            <option value=''>Select Role</option>
                                            {role
                                                .map((x, i) => (
                                                    <option key={i} value={x._id}>{x.Name}</option>
                                                ))}
                                        </select>
                                    </div>
                                    <div className='mt-2'>
                                        <button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </CModal>
        </div >
    );
}

export default User;