import React, { useState, useEffect } from 'react';
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { Post, Get } from '../../Layout/ajax';
import { CModal } from '@coreui/react';
import * as Yup from "yup";
import Swal from "sweetalert2";
import CKEditor from 'react-ckeditor-component';

export const SourceModal = ({ setsourceadd, sourceadd, getsourcepost, showTost, sourceval, setsourceval }) => {
    const sourcevalidation = Yup.object().shape({
        name: Yup.string().required(),
    });
    const [initsourceValue, setinitsourceValue] = useState({
        name: "",
    });
    const [formkey, setFormKey] = useState(Math.random() * 1000000);
    useEffect(() => {
        if (sourceval != 0) {
            Get(`master/source/${sourceval}`)
                .then((res) => {
                    setinitsourceValue({
                        name: res.data.Name,
                    });
                    setsourceadd(true);
                    setsourceval(sourceval);
                    setFormKey(Math.random() * 1000000);
                }).catch((err) => {
                    showTost("Failed to fetch source details", "error");
                });
        }
    }, [sourceval]);
    const handleEdit = async (val) => {
        val.id = sourceval;
        await Post(`master/editSource`, val)
            .then((res) => {
                if (res.success == true) {
                    sourceclear();
                    setsourceadd(false);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            getsourcepost(true);
                        }
                    });
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const sourceclear = () => {
        setinitsourceValue({
            name: "",
        });
    }
    const sourcehandleSubmit = async (val) => {
        await Post(`master/addSource`, val)
            .then((res) => {
                if (res.success == true) {
                    sourceclear();
                    getsourcepost();
                    setsourceadd(false);
                    showTost("Your data has been Successfully added", "success");
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    return (
        <CModal alignment="center" visible={sourceadd}>
            <div className="modal-header">
                <h4 className="modal-title">{sourceval == 0 ? "Add Source" : "Edit Source"} </h4>
                <button type="button" className="close" onClick={() => { sourceclear(); setsourceadd(false); }}>&times;</button>
            </div>
            <div className="modal-body">
                <Formik
                    onSubmit={sourceval == 0 ? sourcehandleSubmit : handleEdit}
                    initialValues={initsourceValue}
                    validationSchema={sourcevalidation}
                    key={formkey}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        values,
                        touched,
                        errors,
                        setFieldValue,
                    }) => {
                        return (
                            <Form
                                onSubmit={handleSubmit}
                                onChange={handleChange}>
                                <div className='mt-2'>
                                    <label>Name</label>
                                    <input type='text' name="name" defaultValue={values.name} placeholder='Enter Source Name' className={!!touched.name && !!errors.name ? "form-control is-invalid" : "form-control"} />
                                </div>
                                <div className='mt-2'>
                                    <button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </CModal>
    );
}
export const ProductModal = ({ setproductadd, productadd, getproductpost, showTost, productval, setproductval }) => {
    const productvalidation = Yup.object().shape({
        name: Yup.string().required(),
    });
    const [SubCategory, setSubCategory] = useState([]);
    const [Category, setCategory] = useState([]);
    const [formkey, setFormKey] = useState(Math.random() * 1000000);
    const [initproductValue, setinitproductValue] = useState({
        id: "",
        name: "",
        code: "",
        category: "",
        subCategory: "",
        purchasePrice: "",
        salePrice: "",
        tax: "",
        minStock: "",
        maxStock: "",
        availableStock: "",
        description: "",
        type: "Product",
        IGST: "0",
        SGST: "0",
        CGST: "0",
        UTGST: "0",
    });
    const productclear = () => {
        setinitproductValue({
            id: initproductValue.id == 0 ? null : 0,
            name: "",
            code: "",
            category: "",
            subCategory: "",
            purchasePrice: "",
            salePrice: "",
            tax: "",
            minStock: "",
            maxStock: "",
            availableStock: "",
            description: "",
            type: "Product",
            IGST: "0",
            SGST: "0",
            CGST: "0",
            UTGST: "0",
        });
        setFormKey(Math.random() * 1000000);
    }
    const producthandleSubmit = async (val) => {
        await Post(`master/addProduct`, val)
            .then((res) => {
                if (res.success == true) {
                    productclear();
                    getproductpost();
                    setproductadd(false);
                    showTost("Your data has been Successfully added", "success");
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    useEffect(() => {
        if (productval != 0) {
            Get(`master/product/${productval}`)
                .then((res) => {
                    setinitproductValue({
                        name: res.data.Name,
                        code: res.data.Code,
                        category: res.data.Category,
                        subCategory: res.data.SubCategory,
                        purchasePrice: res.data.PurchasePrice,
                        salePrice: res.data.SalePrice,
                        tax: res.data.Tax,
                        minStock: res.data.MinStock,
                        maxStock: res.data.MaxStock,
                        availableStock: res.data.AvailableStock,
                        description: res.data.Description,
                        type: res.data.Type,
                        IGST: res.data.IGST,
                        SGST: res.data.SGST,
                        CGST: res.data.CGST,
                        UTGST: res.data.UTGST,
                    });
                    setproductadd(true);
                    setproductval(productval);
                    setFormKey(Math.random() * 1000000);
                }).catch((err) => {
                    showTost("Failed to fetch product details", "error");
                });
        }
    }, [productval]);
    const handleEdit = async (val) => {
        val.id = productval;
        await Post(`master/editProduct`, val)
            .then((res) => {
                if (res.success == true) {
                    productclear();
                    setproductadd(false);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            getproductpost(true);
                        }
                    });
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    useEffect(() => {
        Post(`master/getCategorys`, { active: true })
            .then((res) => {
                setCategory(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
        Post(`master/getSubCategorys`, { active: true })
            .then((res) => {
                setSubCategory(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }, [])
    return (
        <CModal alignment="center" visible={productadd}>
            <div className="modal-header">
                <h4 className="modal-title">
                    {productval == 0 ? "Add Product" : "Edit Product"}
                </h4>
                <button type="button" className="close" onClick={() => { productclear(); setproductadd(false) }}>&times;</button>
            </div>
            <div className="modal-body">
                <Formik
                    onSubmit={productval == 0 ? producthandleSubmit : handleEdit}
                    initialValues={initproductValue}
                    validationSchema={productvalidation}
                    key={formkey}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        values,
                        touched,
                        errors,
                        setFieldValue,
                    }) => {
                        return (
                            <Form
                                onSubmit={handleSubmit}
                                onChange={handleChange}>
                                <div className='row'>
                                    <div className='col-12 mb-2'>
                                        <input className={`ml-2`} name="type" checked={values.type == "Product" ? true : false} value="Product" type="radio" /><span className="ml-2">Product</span>
                                        <input className={`ml-2`} name="type" checked={values.type == "Service" ? true : false} value="Service" type="radio" /><span className="ml-2">Service</span>
                                    </div>
                                    <div className='col-6 mb-2'>
                                        <label>Name</label>
                                        <input type='text' name="name" defaultValue={values.name} placeholder='Enter Name' className={!!touched.name && !!errors.name ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='col-6 mb-2'>
                                        <label>Code</label>
                                        <input type='text' name="code" defaultValue={values.code} placeholder='Enter Code' className={!!touched.code && !!errors.code ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='col-6 mb-2'>
                                        <label>Category</label>
                                        <select defaultValue={values.category} className={!!touched.category && !!errors.category ? "form-select is-invalid" : "form-select"} name='category'>
                                            <option value=''>Select Category</option>
                                            {Category
                                                .map((x, i) => (
                                                    <option key={i} value={x._id}>{x.Name}</option>
                                                ))}
                                        </select>
                                    </div>
                                    <div className='col-6 mb-2'>
                                        <label>SubCategory</label>
                                        <select defaultValue={values.subCategory} className={!!touched.subCategory && !!errors.subCategory ? "form-select is-invalid" : "form-select"} name='subCategory'>
                                            <option value=''>Select SubCategory</option>
                                            {SubCategory
                                                .filter((x) => x.Category?._id == values.category)
                                                .map((x, i) => (
                                                    <option key={i} value={x._id}>{x.Name}</option>
                                                ))}
                                        </select>
                                    </div>
                                    {values.type == "Product" ? <>
                                        <div className='col-6 mb-2'>
                                            <label>Purchase Price</label>
                                            <input type='text' defaultValue={values.purchasePrice} name="purchasePrice" placeholder='Enter Purchase Price' className={!!touched.purchasePrice && !!errors.purchasePrice ? "form-control is-invalid" : "form-control"} />
                                        </div>
                                        <div className='col-6 mb-2'>
                                            <label>Sale Price</label>
                                            <input type='text' defaultValue={values.salePrice} name="salePrice" placeholder='Enter Sale Price' className={!!touched.salePrice && !!errors.salePrice ? "form-control is-invalid" : "form-control"} />
                                        </div>
                                        <div className='col-6 mb-2'>
                                            <label>IGST</label>
                                            <input type='text' defaultValue={values.IGST} name="IGST" placeholder='Enter IGST' className={!!touched.IGST && !!errors.IGST ? "form-control is-invalid" : "form-control"} />
                                        </div>
                                        <div className='col-6 mb-2'>
                                            <label>SGST</label>
                                            <input type='text' defaultValue={values.SGST} name="SGST" placeholder='Enter SGST' className={!!touched.SGST && !!errors.SGST ? "form-control is-invalid" : "form-control"} />
                                        </div>
                                        <div className='col-6 mb-2'>
                                            <label>CGST</label>
                                            <input type='text' defaultValue={values.CGST} name="CGST" placeholder='Enter CGST' className={!!touched.CGST && !!errors.CGST ? "form-control is-invalid" : "form-control"} />
                                        </div>
                                        <div className='col-6 mb-2'>
                                            <label>UTGST</label>
                                            <input type='text' defaultValue={values.UTGST} name="UTGST" placeholder='Enter UTGST' className={!!touched.UTGST && !!errors.UTGST ? "form-control is-invalid" : "form-control"} />
                                        </div>
                                        <div className='col-6 mb-2'>
                                            <label>Tax</label>
                                            <input type='text' defaultValue={values.tax} name="tax" placeholder='Enter Tax' className={!!touched.tax && !!errors.tax ? "form-control is-invalid" : "form-control"} />
                                        </div>
                                        <div className='col-6 mb-2'>
                                            <label>Min Stock</label>
                                            <input type='text' defaultValue={values.minStock} name="minStock" placeholder='Enter Min. Stock' className={!!touched.minStock && !!errors.minStock ? "form-control is-invalid" : "form-control"} />
                                        </div>
                                        <div className='col-6 mb-2'>
                                            <label>Max Stock</label>
                                            <input type='text' defaultValue={values.maxStock} name="maxStock" placeholder='Enter Max. Stock' className={!!touched.maxStock && !!errors.maxStock ? "form-control is-invalid" : "form-control"} />
                                        </div>
                                        <div className='col-6 mb-2'>
                                            <label>Available Stock</label>
                                            <input type='text' defaultValue={values.availableStock} name="availableStock" placeholder='Enter Available Stock' className={!!touched.availableStock && !!errors.availableStock ? "form-control is-invalid" : "form-control"} />
                                        </div>
                                    </> : ""}
                                    <div className='col-12 mb-2'>
                                        <label>Description</label>
                                        <textarea type='text' defaultValue={values.description} name="description" placeholder='Enter Description' className={!!touched.description && !!errors.description ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='col-12'>
                                        <button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </CModal>
    );
}
export const UserModal = ({ useradd, setuseradd, getuserpost, showTost, userval, setuserval }) => {
    const createuservalidation = Yup.object().shape({
        userName: Yup.string().required(),
        email: Yup.string().email().required(),
        password: Yup.string().required(),
        role: Yup.string().required(),
        confirmpassword: Yup.string().required()
            .oneOf([Yup.ref('password')], 'Passwords must and should match'),
    });
    const updateuservalidation = Yup.object().shape({
        userName: Yup.string().required(),
        role: Yup.string().required(),
    });
    const [formkey, setFormKey] = useState(Math.random() * 1000000);
    const [role, setrole] = useState([]);
    useEffect(() => {
        Post(`master/getRole`, { active: true })
            .then((res) => {
                setrole(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }, []);
    const [createuserinitValue, setcreateuserinitValue] = useState({
        id: "",
        userName: "",
        email: "",
        password: "",
        role: "",
        confirmpassword: ""
    });

    const [updateuserinitValue, setupdateuserinitValue] = useState({
        userName: "",
        role: "",
    });
    useEffect(() => {
        if (userval != 0) {
            Get(`users/${userval}`)
                .then((res) => {
                    setupdateuserinitValue({
                        userName: res.name,
                        role: res.role,
                    });
                    setuseradd(true);
                    setuserval(userval);
                    setFormKey(Math.random() * 1000000);
                }).catch((err) => {
                    showTost("Failed to fetch user details", "error");
                });
        }
    }, [userval]);
    const handleEdit = async (val) => {
        val.id = userval;
        val.name = val.userName;
        await Post(`users/updateUser`, val)
            .then((res) => {
                userclear();
                setuseradd(false);
                Swal.fire({
                    title: "Your work has been saved",
                    icon: "success",
                    confirmButtonText: "OK",
                    confirmButtonColor: "#FD711A",
                }).then(async ({ isConfirmed }) => {
                    if (isConfirmed) {
                        getuserpost(true);
                    }
                });
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const userclear = () => {
        setcreateuserinitValue({
            id: createuserinitValue.id == 0 ? null : 0,
            userName: "",
            email: "",
            password: "",
            role: "",
            confirmpassword: ""
        })
    }
    const create = async (val) => {
        val.name = val.userName;
        await Post(`users/add`, val)
            .then((res) => {
                if (res.token !== undefined) {
                    setuseradd(false);
                    getuserpost();
                    userclear();
                    showTost("Your data has been Successfully added", "success");
                }
                else if (res.message = "User Already Exists!") {
                    showTost("User Already Exists!", "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };

    return (
        <CModal alignment="center" visible={useradd}>
            <div className="modal-header">
                <h4 className="modal-title"> {userval == 0 ? "Add User" : "Edit User"}</h4>
                <button type="button" className="close" onClick={() => { userclear(); setuseradd(false); }}>&times;</button>
            </div>
            <div className="modal-body">
                <Formik
                    onSubmit={userval == 0 ? create : handleEdit}
                    initialValues={userval == 0 ? createuserinitValue : updateuserinitValue}
                    validationSchema={userval == 0 ? createuservalidation : updateuservalidation}
                    key={formkey}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        values,
                        touched,
                        errors,
                        setFieldValue,
                    }) => {
                        return (
                            <Form
                                onSubmit={handleSubmit}
                                onChange={handleChange}>
                                <div className='mt-2'>
                                    <label>User Name</label>
                                    <input type='text' name="userName" defaultValue={values.userName} placeholder='Enter User Name' className={!!touched.userName && !!errors.userName ? "form-control is-invalid" : "form-control"} />
                                </div>
                                {userval == 0 ? <>
                                    <div className='mt-2'>
                                        <label>User Email</label>
                                        <input type='email' name="email" placeholder='Enter User Email' className={!!touched.email && !!errors.email ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='mt-2'>
                                        <label>Password</label>
                                        <input type='password' name="password" placeholder='Enter Password' className={!!touched.password && !!errors.password ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='mt-2'>
                                        <label>Confirm Password</label>
                                        <input type='password' name="confirmpassword" placeholder='Enter Confirm Password' className={!!touched.confirmpassword && !!errors.confirmpassword ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                </> : <></>}
                                <div className='mt-2'>
                                    <label>Role</label>
                                    <select defaultValue={values.role} className={!!touched.role && !!errors.role ? "form-select is-invalid" : "form-select"} name='role'>
                                        <option value=''>Select Role</option>
                                        {role
                                            .map((x, i) => (
                                                <option key={i} value={x._id}>{x.Name}</option>
                                            ))}
                                    </select>
                                </div>
                                <div className='mt-2'>
                                    <button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </CModal>
    );
}
export const CustomerModal = ({ setcustomeradd, customeradd, getcustomerpost, showTost, Manual, customerval, setcustomerval }) => {
    const customervalidation = Yup.object().shape({
        company: Yup.string().required(),
        branch: Yup.string().required(),
        firstname: Yup.string().required(),
        email: Yup.string().email(),
        mobile: Yup.string().max(10).min(10).matches(/^[+]?[0-9]+$/),
    });
    const [formkey, setFormKey] = useState(Math.random() * 1000000);
    const [Branch, setBranch] = useState([]);
    const [Company, setCompany] = useState([]);
    const [initcustomerValue, setinitcustomerValue] = useState({
        id: "",
        company: "",
        branch: "",
        title: "",
        firstname: "",
        lastname: "",
        designation: "",
        mobile: "",
        CustomerCode: "",
        email: "",
        notes: "",
    });
    const customerclear = () => {
        setinitcustomerValue({
            id: initcustomerValue.id == 0 ? null : 0,
            company: "",
            branch: "",
            title: "",
            firstname: "",
            lastname: "",
            designation: "",
            CustomerCode: "",
            mobile: "",
            email: "",
            notes: "",
        });
    }
    useEffect(() => {
        if (customerval != 0) {
            Get(`customer/getCustomerById/${customerval}`)
                .then((res) => {
                    setinitcustomerValue({
                        company: res.data[0].Company?._id,
                        branch: res.data[0].Branch?._id,
                        title: res.data[0].Title,
                        firstname: res.data[0].FirstName,
                        lastname: res.data[0].LastName,
                        designation: res.data[0].Designation,
                        CustomerCode: res.data[0].CustomerCode,
                        mobile: res.data[0].Mobile,
                        email: res.data[0].Email,
                        notes: res.data[0].Notes,
                    });
                    setcustomeradd(true);
                    setcustomerval(customerval);
                    setFormKey(Math.random() * 1000000);
                }).catch((err) => {
                    showTost("Failed to fetch customer details", "error");
                });
        }
    }, [customerval]);
    const handleEdit = async (val) => {
        val.id = customerval;
        await Post(`customer/editCustomer`, val)
            .then((res) => {
                if (res.success == true) {
                    customerclear();
                    setcustomeradd(false);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            getcustomerpost(true);
                        }
                    });
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const customerhandleSubmit = async (val) => {
        await Post(`customer/addCustomer`, val)
            .then((res) => {
                if (res.success == false) {
                    showTost(res.msg, "warning");
                }
                else {
                    customerclear();
                    setcustomeradd(false);
                    getcustomerpost();
                    showTost("Your data has been Successfully added", "success");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const [lastCustomerCode, setLastCustomerCode] = useState('');
    useEffect(() => {
        Post(`customer/getAllCustomer`, { active: true })
            .then((res) => {
                setLastCustomerCode(res.lastCustomerCode?.CustomerCode)
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
        Post(`customer/getCompany`, { active: true })
            .then((res) => {
                setCompany(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
        Post(`customer/getBranchs`, { active: true })
            .then((res) => {
                setBranch(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }, [])
    return (
        <CModal alignment="center" visible={customeradd}>
            <div className="modal-header">
                <h4 className="modal-title">
                    {customerval == 0 ? "Add Customer" : "Edit Customer"}
                </h4>
                <button type="button" className="close" onClick={() => { customerclear(); setcustomeradd(false); }}>&times;</button>
            </div>
            <div className="modal-body">
                <Formik
                    onSubmit={customerval == 0 ? customerhandleSubmit : handleEdit}
                    initialValues={initcustomerValue}
                    validationSchema={customervalidation}
                    key={formkey}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        values,
                        touched,
                        errors,
                        setFieldValue,
                    }) => {
                        return (
                            <Form
                                onSubmit={handleSubmit}
                                onChange={handleChange}>
                                <div className='row'>
                                    {Manual?.Customer == true ?
                                        <div className="col-6 mb-2">
                                            <label>Customer Code</label>
                                            <input type="text" placeholder="Customer Code" value={values.CustomerCode} defaultValue={values.CustomerCode} name='CustomerCode' className={!!touched.CustomerCode && !!errors.CustomerCode ? "form-control is-invalid" : "form-control"} />
                                            {customerval == 0 ?
                                                <div>
                                                    <span>Last Customer Code:</span>
                                                    <span className='fw-bold ms-1'>
                                                        {lastCustomerCode}
                                                    </span>
                                                </div> : ""}
                                        </div> : ""}
                                    <div className="col-6 mb-2">
                                        <label><i className="fa fa-building-o text-danger mr-2" />Company</label>
                                        <select disabled={customerval == 0 ? false : true} defaultValue={values.company} className={!!touched.company && !!errors.company ? "form-select is-invalid" : "form-select"} name='company'>
                                            <option>Select Company</option>
                                            {
                                                Company.map((x, i) => (
                                                    <option key={i} value={x._id}>{x.Company}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className="col-6 mb-2">
                                        <label><i className="fa fa-building-o text-danger mr-2" />Branch</label>
                                        <select defaultValue={values.branch} className={!!touched.branch && !!errors.branch ? "form-select is-invalid" : "form-select"} name='branch'>
                                            <option>Select Branch</option>
                                            {
                                                Branch.filter((x) => x.Company?._id == values.company).map((x, i) => (
                                                    <option key={i} value={x._id}>{x.Name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className='col-6 mb-2'>
                                        <label>Title</label>
                                        <select defaultValue={values.title} className={!!touched.title && !!errors.title ? "form-select is-invalid" : "form-select"} name='title'>
                                            <option value="">Select</option>
                                            <option value="Mr.">Mr.</option>
                                            <option value="Mrs.">Mrs.</option>
                                            <option value="Ms.">Ms.</option>
                                        </select>
                                    </div>
                                    <div className='col-6 mb-2'>
                                        <label>First Name</label>
                                        <input type='text' name="firstname" defaultValue={values.firstname} placeholder='Enter Firstname' className={!!touched.firstname && !!errors.firstname ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='col-6 mb-2'>
                                        <label>Last Name</label>
                                        <input type='text' name="lastname" defaultValue={values.lastname} placeholder='Enter Lastname' className={!!touched.lastname && !!errors.lastname ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='col-6 mb-2'>
                                        <label>Designation</label>
                                        <input type='text' name="designation" defaultValue={values.designation} placeholder='Enter Designation' className={!!touched.designation && !!errors.designation ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='col-6 mb-2'>
                                        <label>Mobile Number</label>
                                        <input type='text' name="mobile" defaultValue={values.mobile} placeholder='Enter Mobile' className={!!touched.mobile && !!errors.mobile ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='col-6 mb-2'>
                                        <label>Email</label>
                                        <input type='email' name="email" defaultValue={values.email} placeholder='Enter Email' className={!!touched.email && !!errors.email ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='col-12 mb-2'>
                                        <label>Notes</label>
                                        <textarea type='text' name="notes" defaultValue={values.notes} placeholder='Enter Notes' className={!!touched.notes && !!errors.notes ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='col-12'>
                                        <button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </CModal>
    );
}
export const UnitModal = ({ setunitadd, unitadd, getunitpost, showTost, unitval, setunitval }) => {
    const unitvalidation = Yup.object().shape({
        name: Yup.string().required(),
    });
    const [initunitValue, setinitunitValue] = useState({
        name: "",
    });
    const unitclear = () => {
        setinitunitValue({
            name: "",
        });
    }

    const [formkey, setFormKey] = useState(Math.random() * 1000000);
    useEffect(() => {
        if (unitval != 0) {
            Get(`master/unit/${unitval}`)
                .then((res) => {
                    setinitunitValue({
                        name: res.data.Name,
                    });
                    setunitadd(true);
                    setunitval(unitval);
                    setFormKey(Math.random() * 1000000);
                }).catch((err) => {
                    showTost("Failed to fetch unit details", "error");
                });
        }
    }, [unitval]);
    const handleEdit = async (val) => {
        val.id = unitval;
        await Post(`master/editUnit`, val)
            .then((res) => {
                if (res.success == true) {
                    unitclear();
                    setunitadd(false);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            getunitpost(true);
                        }
                    });
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const unithandleSubmit = async (val) => {
        await Post(`master/addUnit`, val)
            .then((res) => {
                if (res.success == true) {
                    unitclear();
                    getunitpost();
                    setunitadd(false);
                    showTost("Your data has been Successfully added", "success");
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    return (
        <CModal alignment="center" visible={unitadd}>
            <div className="modal-header">
                <h4 className="modal-title">{unitval == 0 ? "Add Unit" : "Edit Unit"} </h4>
                <button type="button" className="close" onClick={() => { unitclear(); setunitadd(false) }}>&times;</button>
            </div>
            <div className="modal-body">
                <Formik
                    onSubmit={unitval == 0 ? unithandleSubmit : handleEdit}
                    initialValues={initunitValue}
                    validationSchema={unitvalidation}
                    key={formkey}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        values,
                        touched,
                        errors,
                        setFieldValue,
                    }) => {
                        return (
                            <Form
                                onSubmit={handleSubmit}
                                onChange={handleChange}>
                                <div className='mt-2'>
                                    <label>Name</label>
                                    <input type='text' name="name" defaultValue={values.name} placeholder='Enter Unit Name' className={!!touched.name && !!errors.name ? "form-control is-invalid" : "form-control"} />
                                </div>
                                <div className='mt-2'>
                                    <button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </CModal>
    );
}
export const TypeModal = ({ settypeadd, typeadd, getType, showTost, typeval, settypeval }) => {
    const validationtype = Yup.object().shape({
        name: Yup.string().required(),
    });
    const [initValuetype, setinitValuetype] = useState({
        name: "",
    });
    const typeclear = () => {
        setinitValuetype({
            name: "",
        });
    }
    const [formkey, setFormKey] = useState(Math.random() * 1000000);
    const handleSubmittype = async (val) => {
        await Post(`master/addType`, val)
            .then((res) => {
                if (res.success == true) {
                    typeclear();
                    getType()
                    settypeadd(false);
                    showTost("Your data has been Successfully added", "success");
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    useEffect(() => {
        if (typeval != 0) {
            Get(`master/Type/${typeval}`)
                .then((res) => {
                    setinitValuetype({
                        name: res.data.Name,
                    });
                    settypeadd(true);
                    settypeval(typeval);
                    setFormKey(Math.random() * 1000000);
                }).catch((err) => {
                    showTost("Failed to fetch type details", "error");
                });
        }
    }, [typeval]);
    const handleEdit = async (val) => {
        val.id = typeval;
        await Post(`master/editType`, val)
            .then((res) => {
                if (res.success == true) {
                    typeclear();
                    settypeadd(false);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            getType(true);
                        }
                    });
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    return (
        <CModal alignment="center" visible={typeadd}>
            <div className="modal-header">
                <h4 className="modal-title">{typeval == 0 ? "Add Type" : "Edit Type"} </h4>
                <button type="button" className="close" onClick={() => { typeclear(); settypeadd(false) }}>&times;</button>
            </div>
            <div className="modal-body">
                <Formik
                    onSubmit={typeval == 0 ? handleSubmittype : handleEdit}
                    initialValues={initValuetype}
                    validationSchema={validationtype}
                    key={formkey}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        values,
                        touched,
                        errors,
                        setFieldValue,
                    }) => {
                        return (
                            <Form
                                onSubmit={handleSubmit}
                                onChange={handleChange}>
                                <div className='row'>
                                    <div className='col-12 mb-2'>
                                        <label>Name</label>
                                        <input type='text' name="name" defaultValue={values.name} placeholder='Enter Name' className={!!touched.name && !!errors.name ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='col-12'>
                                        <button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </CModal>
    )
}
export const IconModal = ({ seticonadd, iconadd, geticonpost, showTost, iconval, seticonval }) => {
    const iconvalidation = Yup.object().shape({
        name: Yup.string().required(),
        color: Yup.string().required(),
    });
    const [initiconValue, setiniticonValue] = useState({
        name: "",
        color: ""
    });
    const iconclear = () => {
        setiniticonValue({
            name: "",
            color: ""
        });
    }
    const [formkey, setFormKey] = useState(Math.random() * 1000000);
    const iconhandleSubmit = async (val) => {
        await Post(`master/addIcon`, val)
            .then((res) => {
                if (res.success == true) {
                    iconclear();
                    geticonpost();
                    seticonadd(false);
                    showTost("Your data has been Successfully added", "success");
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    useEffect(() => {
        if (iconval != 0) {
            Get(`master/icon/${iconval}`)
                .then((res) => {
                    setiniticonValue({
                        name: res.data.Name,
                        color: res.data.Color
                    });
                    seticonadd(true);
                    seticonval(iconval);
                    setFormKey(Math.random() * 1000000);
                }).catch((err) => {
                    showTost("Failed to fetch icon details", "error");
                });
        }
    }, [iconval]);
    const handleEdit = async (val) => {
        val.id = iconval;
        await Post(`master/editIcon`, val)
            .then((res) => {
                if (res.success == true) {
                    iconclear();
                    seticonadd(false);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            geticonpost(true);
                        }
                    });
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    return (
        <CModal alignment="center" visible={iconadd}>
            <div className="modal-header">
                <h4 className="modal-title">{iconval == 0 ? "Add Icon" : "Edit Icon"} </h4>
                <button type="button" className="close" onClick={() => { iconclear(); seticonadd(false) }}>&times;</button>
            </div>
            <div className="modal-body">
                <Formik
                    onSubmit={iconval == 0 ? iconhandleSubmit : handleEdit}
                    initialValues={initiconValue}
                    validationSchema={iconvalidation}
                    key={formkey}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        values,
                        touched,
                        errors,
                        setFieldValue,
                    }) => {
                        return (
                            <Form
                                onSubmit={handleSubmit}
                                onChange={handleChange}>
                                <div className='mt-2'>
                                    <label>Icon Name</label>
                                    <input type='text' name="name" defaultValue={values.name} placeholder='Enter Icon Name' className={!!touched.name && !!errors.name ? "form-control is-invalid" : "form-control"} />
                                </div>
                                <div className='mt-2'>
                                    <label>Color</label>
                                    <input type='color' name="color" defaultValue={values.color} placeholder='Enter Color' className={!!touched.color && !!errors.color ? "form-control is-invalid" : "form-control"} />
                                </div>
                                <div className='mt-2'>
                                    <button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </CModal>
    );
}
export const RoleModal = ({ setRoleadd, roleAdd, getRole, showTost, roleVal, setRoleval }) => {
    const validationRole = Yup.object().shape({
        roleName: Yup.string().required(),
    });
    const [initValueRole, setinitValueRole] = useState({
        roleName: "",
    });
    const roleClear = () => {
        setinitValueRole({
            roleName: "",
        });
    }
    const [formkey, setFormKey] = useState(Math.random() * 1000000);
    const handleSubmitRole = async (val) => {
        val.name = val.roleName;
        await Post(`master/addRole`, val)
            .then((res) => {
                if (res.success == true) {
                    roleClear();
                    getRole()
                    setRoleadd(false);
                    showTost("Your data has been Successfully added", "success");
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    useEffect(() => {
        if (roleVal != 0) {
            Get(`master/role/${roleVal}`)
                .then((res) => {
                    setinitValueRole({
                        roleName: res.data.Name,
                    });
                    setRoleadd(true);
                    setRoleval(roleVal);
                    setFormKey(Math.random() * 1000000);
                }).catch((err) => {
                    showTost("Failed to fetch type details", "error");
                });
        }
    }, [roleVal]);
    const handleEdit = async (val) => {
        val.id = roleVal;
        val.name = val.roleName;
        await Post(`master/editRole`, val)
            .then((res) => {
                if (res.success == true) {
                    roleClear();
                    setRoleadd(false);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            getRole(true);
                        }
                    });
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    return (
        <CModal alignment="center" visible={roleAdd}>
            <div className="modal-header">
                <h4 className="modal-title">{roleVal == 0 ? "Add Role" : "Edit Role"} </h4>
                <button type="button" className="close" onClick={() => { roleClear(); setRoleadd(false) }}>&times;</button>
            </div>
            <div className="modal-body">
                <Formik
                    onSubmit={roleVal == 0 ? handleSubmitRole : handleEdit}
                    initialValues={initValueRole}
                    validationSchema={validationRole}
                    key={formkey}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        values,
                        touched,
                        errors,
                        setFieldValue,
                    }) => {
                        return (
                            <Form
                                onSubmit={handleSubmit}
                                onChange={handleChange}>
                                <div className='mt-2'>
                                    <label>Name</label>
                                    <input type='text' name="roleName" defaultValue={values.roleName} placeholder='Enter Role Name' className={!!touched.roleName && !!errors.roleName ? "form-control is-invalid" : "form-control"} />
                                </div>
                                <div className='mt-2'>
                                    <button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </CModal>
    )
}

export const CountryModal = ({ setcountryadd, countryadd, getcountrypost, showTost, setcountryval, countryval }) => {
    const countryValidation = Yup.object().shape({
        countryName: Yup.string().required()
    })
    const [formkey, setFormKey] = useState(Math.random() * 1000000);
    const [initCountryValue, setInitCountryValue] = useState({
        countryName: ""
    })
    const countryCleare = () => {
        setInitCountryValue({
            countryName: ""
        })
    }
    useEffect(() => {
        if (countryval != 0) {
            Get(`master/country/${countryval}`)
                .then((res) => {
                    setInitCountryValue({
                        countryName: res.data.Name
                    })
                    setcountryval(countryval);
                    setcountryadd(true);
                    setFormKey(Math.random() * 1000000);
                }).catch((err) => {
                    showTost("Failed to fetch icon details", "error");
                })
        }
    }, [countryval])
    const countryhandleSubmit = async (val) => {
        val.name = val.countryName;
        await Post(`master/addCountry`, val)
            .then((res) => {
                if (res.success == true) {
                    countryCleare();
                    getcountrypost();
                    setcountryadd(false);
                    showTost("Your data has been Successfully added", "success");
                } else {
                    showTost(res.message, "warning");
                }
            }).catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            })
    }
    const handleEdit = async (val) => {
        val.id = countryval;
        val.name = val.countryName;
        await Post(`master/editCountry`, val)
            .then((res) => {
                if (res.success == true) {
                    countryCleare();
                    setcountryadd(false);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            getcountrypost(true);
                        }
                    });
                } else {
                    showTost(res.message, "warning");
                }
            }).catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            })
    }
    return (
        <CModal alignment="center" visible={countryadd}>
            <div className="modal-header">
                <h4 className="modal-title">{countryval == 0 ? "Add Country" : "Edit Country"} </h4>
                <button type="button" className="close" onClick={() => { countryCleare(); setcountryadd(false) }}>&times;</button>
            </div>
            <div className="modal-body">
                <Formik
                    onSubmit={countryval == 0 ? countryhandleSubmit : handleEdit}
                    initialValues={initCountryValue}
                    validationSchema={countryValidation}
                    key={formkey} >
                    {
                        ({
                            handleSubmit,
                            handleChange,
                            values,
                            touched,
                            errors,
                            setFieldValue,
                        }) => {
                            return (
                                <Form
                                    onSubmit={handleSubmit}
                                    onChange={handleChange}>
                                    <div className='mt-2'>
                                        <label>Country Name</label>
                                        <input type='text' name="countryName" defaultValue={values.countryName} placeholder='Enter Country Name' className={!!touched.countryName && !!errors.countryName ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='mt-2'>
                                        <button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                    </div>
                                </Form>
                            )
                        }
                    }
                </Formik>
            </div>
        </CModal>
    )
}
export const StateModal = ({ setstateadd, stateadd, getstatepost, setstateval, stateval, showTost }) => {
    const stateValidation = Yup.object().shape({
        stateName: Yup.string().required(),
        country: Yup.string().required()
    })
    const [formkey, setFormKey] = useState(Math.random() * 1000000);
    const [Country, setCountry] = useState([]);
    const [initStateValue, setInitStateValue] = useState({
        stateName: "",
        country: ""
    })
    const stateCleare = () => {
        setInitStateValue({
            stateName: "",
            country: ""
        })
    }
    useEffect(() => {
        if (stateval != 0) {
            Get(`master/state/${stateval}`)
                .then((res) => {
                    setInitStateValue({
                        stateName: res.data.Name,
                        country: res.data.Country
                    })
                    setstateval(stateval);
                    setstateadd(true);
                    setFormKey(Math.random() * 1000000);
                }).catch((err) => {
                    showTost("Failed to fetch icon details", "error");
                })
        }
    }, [stateval])
    useEffect(() => {
        Post(`master/getCountry`, { active: true })
            .then((res) => {
                setCountry(res.data);
                setFormKey(Math.random() * 1000000);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }, []);
    const statehandleSubmit = async (val) => {
        val.name = val.stateName;
        await Post(`master/addState`, val)
            .then((res) => {
                if (res.success == true) {
                    stateCleare();
                    getstatepost();
                    setstateadd(false);
                    showTost("Your data has been Successfully added", "success");
                } else {
                    showTost(res.message, "warning");
                }
            }).catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            })
    }
    const handleEdit = async (val) => {
        val.id = stateval;
        val.name = val.stateName;
        val.country = val.country;
        await Post(`master/editState`, val)
            .then((res) => {
                if (res.success == true) {
                    stateCleare();
                    setstateadd(false);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            getstatepost(true);
                        }
                    });
                } else {
                    showTost(res.message, "warning");
                }
            }).catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            })
    }
    return (
        <CModal alignment="center" visible={stateadd}>
            <div className="modal-header">
                <h4 className="modal-title">{stateval == 0 ? "Add State" : "Edit State"} </h4>
                <button type="button" className="close" onClick={() => { stateCleare(); setstateadd(false) }}>&times;</button>
            </div>
            <div className="modal-body">
                <Formik
                    onSubmit={stateval == 0 ? statehandleSubmit : handleEdit}
                    initialValues={initStateValue}
                    validationSchema={stateValidation}
                    key={formkey} >
                    {
                        ({
                            handleSubmit,
                            handleChange,
                            values,
                            touched,
                            errors,
                            setFieldValue,
                        }) => {
                            return (
                                <Form
                                    onSubmit={handleSubmit}
                                    onChange={handleChange}>
                                    <div className='mt-2'>
                                        <label>Country</label>
                                        <select disabled={stateval == 0 ? false : true} name="country" defaultValue={values.country} className={!!touched.country && !!errors.country ? "form-select is-invalid" : "form-select"}>
                                            <option value="">Select Country</option>
                                            {Country
                                                .map((x, i) => (
                                                    <option key={i} value={x._id}>{x.Name}</option>
                                                ))}
                                        </select>
                                    </div>
                                    <div className='mt-2'>
                                        <label>State Name</label>
                                        <input type='text' name="stateName" defaultValue={values.stateName} placeholder='Enter State Name' className={!!touched.stateName && !!errors.stateName ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='mt-2'>
                                        <button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                    </div>
                                </Form>
                            )
                        }
                    }
                </Formik>
            </div>
        </CModal>
    )
}
export const CityModal = ({ cityval, setcityval, setcityadd, cityadd, getcitypost, showTost }) => {
    const cityValidation = Yup.object().shape({
        cityName: Yup.string().required(),
        state: Yup.string().required()
    })
    const [formkey, setFormKey] = useState(Math.random() * 1000000);
    const [State, setState] = useState([]);
    const [initCityValue, setInitCityValue] = useState({
        cityName: "",
        state: ""
    })
    const cityCleare = () => {
        setInitCityValue({
            cityName: "",
            state: ""
        })
    }
    useEffect(() => {
        Post(`master/getState`, { active: true })
            .then((res) => {
                setState(res.data);
                setFormKey(Math.random() * 1000000);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }, [])
    useEffect(() => {
        if (cityval != 0) {
            Get(`master/city/${cityval}`)
                .then((res) => {
                    setInitCityValue({
                        cityName: res.data.Name,
                        state: res.data.State
                    })
                    setcityval(cityval);
                    setcityadd(true);
                    setFormKey(Math.random() * 1000000);
                }).catch((err) => {
                    showTost("Failed to fetch City details", "error");
                })
        }
    }, [cityval])
    const cityhandleSubmit = async (val) => {
        val.name = val.cityName;
        await Post(`master/addCity`, val)
            .then((res) => {
                if (res.success == true) {
                    cityCleare();
                    getcitypost();
                    setcityadd(false);
                    showTost("Your data has been Successfully added", "success");
                } else {
                    showTost(res.message, "warning");
                }
            }).catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            })
    }
    const handleEdit = async (val) => {
        val.id = cityval;
        val.name = val.cityName;
        val.state = val.state;
        await Post(`master/editCity`, val)
            .then((res) => {
                if (res.success == true) {
                    cityCleare();
                    setcityadd(false);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            getcitypost(true);
                        }
                    });
                } else {
                    showTost(res.message, "warning");
                }
            }).catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            })
    }

    return (
        <CModal alignment="center" visible={cityadd}>
            <div className="modal-header">
                <h4 className="modal-title">{cityval == 0 ? "Add City" : "Edit City"} </h4>
                <button type="button" className="close" onClick={() => { cityCleare(); setcityadd(false) }}>&times;</button>
            </div>
            <div className="modal-body">
                <Formik
                    onSubmit={cityval == 0 ? cityhandleSubmit : handleEdit}
                    initialValues={initCityValue}
                    validationSchema={cityValidation}
                    key={formkey} >
                    {
                        ({
                            handleSubmit,
                            handleChange,
                            values,
                            touched,
                            errors,
                            setFieldValue,
                        }) => {
                            return (
                                <Form
                                    onSubmit={handleSubmit}
                                    onChange={handleChange}>
                                    <div className='mt-2'>
                                        <label>State</label>
                                        <select name="state" disabled={cityval == 0 ? false : true} defaultValue={values.state} className={!!touched.state && !!errors.state ? "form-select is-invalid" : "form-select"}>
                                            <option value="">Select State</option>
                                            {State
                                                .map((x, i) => (
                                                    <option key={i} value={x._id}>{x.Name}</option>
                                                ))}
                                        </select>
                                    </div>
                                    <div className='mt-2'>
                                        <label>City Name</label>
                                        <input type='text' name="cityName" defaultValue={values.cityName} placeholder='Enter City Name' className={!!touched.cityName && !!errors.cityName ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='mt-2'>
                                        <button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                    </div>
                                </Form>
                            )
                        }
                    }
                </Formik>
            </div>
        </CModal>
    )

}
export const StatusModal = ({ setstatusadd, statusadd, getStatus, showTost, setstatusval, statusval }) => {
    const statusValidation = Yup.object().shape({
        name: Yup.string().required(),
        groupname: Yup.string().required(),
    })
    const [formkey, setFormKey] = useState(Math.random() * 1000000);
    const [Role, setRole] = useState([]);
    const [User, setUser] = useState([]);
    const [initstatusValue, setInitstatusValue] = useState({
        name: "",
        groupname: "",
        role: "",
        assign: "",
        color: "",
    })
    const statusCleare = () => {
        setInitstatusValue({
            name: "",
            groupname: "",
            role: "",
            assign: "",
            color: "",
        })
    }
    useEffect(() => {
        if (statusval != 0) {
            Get(`master/Status/${statusval}`)
                .then((res) => {
                    setInitstatusValue({
                        name: res.data.Name,
                        groupname: res.data.GroupName,
                        role: res.data.Role,
                        assign: res.data.Assign,
                        color: res.data.Color,
                    })
                    setstatusval(statusval);
                    setstatusadd(true);
                    setFormKey(Math.random() * 1000000);
                }).catch((err) => {
                    showTost("Failed to fetch icon details", "error");
                })
        }
    }, [statusval])
    const statushandleSubmit = async (val) => {
        await Post(`master/addStatus`, val)
            .then((res) => {
                if (res.success == true) {
                    statusCleare();
                    getStatus();
                    setstatusadd(false);
                    showTost("Your data has been Successfully added", "success");
                } else {
                    showTost(res.message, "warning");
                }
            }).catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            })
    }
    const handleEdit = async (val) => {
        val.id = statusval;
        await Post(`master/editStatus`, val)
            .then((res) => {
                if (res.success == true) {
                    statusCleare();
                    setstatusadd(false);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            getStatus(true);
                        }
                    });
                } else {
                    showTost(res.message, "warning");
                }
            }).catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            })
    }
    useEffect(() => {
        Post(`users/getAllUser`, { active: true })
            .then((res) => {
                setUser(res);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
        Post(`master/getRole`, { active: true })
            .then((res) => {
                setRole(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }, []);
    return (
        <CModal alignment="center" visible={statusadd}>
            <div className="modal-header">
                <h4 className="modal-title">{statusval == 0 ? "Add Status" : "Edit Status"} </h4>
                <button type="button" className="close" onClick={() => { statusCleare(); setstatusadd(false) }}>&times;</button>
            </div>
            <div className="modal-body">
                <Formik
                    onSubmit={statusval == 0 ? statushandleSubmit : handleEdit}
                    initialValues={initstatusValue}
                    validationSchema={statusValidation}
                    key={formkey} >
                    {
                        ({
                            handleSubmit,
                            handleChange,
                            values,
                            touched,
                            errors,
                            setFieldValue,
                        }) => {
                            return (
                                <Form
                                    onSubmit={handleSubmit}
                                    onChange={handleChange}>
                                    <div className='mt-2'>
                                        <label>Status Group</label>
                                        <select name="groupname" disabled={statusval == 0 ? false : true} defaultValue={values.groupname} className={!!touched.groupname && !!errors.groupname ? "form-select is-invalid" : "form-select"}>
                                            <option value="">Select Status Group</option>
                                            <option>Leads</option>
                                            <option>Prospects</option>
                                            <option>Orders</option>
                                            <option>Support</option>
                                            <option>Tasks</option>
                                        </select>
                                    </div>
                                    <div className='mt-2'>
                                        <label>Name</label>
                                        <input type='text' name="name" defaultValue={values.name} placeholder='Enter Status Name' className={!!touched.name && !!errors.name ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='mt-2'>
                                        <label>Assign</label>
                                        <select name="assign" defaultValue={values.assign} className={!!touched.assign && !!errors.assign ? "form-select is-invalid" : "form-select"}>
                                            <option value="">Select Assign</option>
                                            {User
                                                .map((x, i) => (
                                                    <option key={i} value={x._id}>{x.name}</option>
                                                ))}
                                        </select>
                                    </div>
                                    <div className='mt-2'>
                                        <label>Role</label>
                                        <select name="role" defaultValue={values.role} className={!!touched.role && !!errors.role ? "form-select is-invalid" : "form-select"}>
                                            <option value="">Select Role</option>
                                            {Role
                                                .map((x, i) => (
                                                    <option key={i} value={x._id}>{x.Name}</option>
                                                ))}
                                        </select>
                                    </div>
                                    <div className='mt-2'>
                                        <label>Color</label>
                                        <input type='color' name="color" defaultValue={values.color} placeholder='Enter Status Name' className={!!touched.color && !!errors.color ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='mt-2'>
                                        <button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                    </div>
                                </Form>
                            )
                        }
                    }
                </Formik>
            </div>
        </CModal>
    )
}
export const QuotaionNameModal = ({ setQuotationNameadd, QuotationNameadd, getQuatationNamepost, setQuotationNameval, QuotationNameval, showTost }) => {
    const QuotaionNameValidation = Yup.object().shape({
        quoatationName: Yup.string().required(),
        workDescription: Yup.string().required(),
        shortCode: Yup.string().required(),
    })
    const [formkey, setFormKey] = useState(Math.random() * 1000000);
    const [initQuotaionNameValue, setInitQuotaionNameValue] = useState({
        quoatationName: "",
        TermsAndCondition: "",
        workDescription: "",
        shortCode: ""
    })
    const QuotaionNameCleare = () => {
        setInitQuotaionNameValue({
            quoatationName: "",
            TermsAndCondition: "",
            workDescription: "",
            shortCode: ""
        })
    }
    useEffect(() => {
        if (QuotationNameval != 0) {
            Get(`master/QuatationName/${QuotationNameval}`)
                .then((res) => {
                    setInitQuotaionNameValue({
                        quoatationName: res.data.Name,
                        TermsAndCondition: res.data.TermsAndCondition,
                        workDescription: res.data.WorkDescription,
                        shortCode: res.data.ShortCode
                    })
                    setQuotationNameval(QuotationNameval);
                    setQuotationNameadd(true);
                    setFormKey(Math.random() * 1000000);
                }).catch((err) => {
                    showTost("Failed to fetch icon details", "error");
                })
        }
    }, [QuotationNameval])
    const quotationNamehandleSubmit = async (val) => {
        val.name = val.quoatationName;
        await Post(`master/addQuatationName`, val)
            .then((res) => {
                if (res.success == true) {
                    QuotaionNameCleare();
                    getQuatationNamepost();
                    setQuotationNameadd(false);
                    showTost("Your data has been Successfully added", "success");
                } else {
                    showTost(res.message, "warning");
                }
            }).catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            })
    }
    const handleEdit = async (val) => {
        val.id = QuotationNameval;
        val.name = val.quoatationName;
        await Post(`master/editQuatationName`, val)
            .then((res) => {
                if (res.success == true) {
                    QuotaionNameCleare();
                    setQuotationNameadd(false);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            getQuatationNamepost(true);
                        }
                    });
                } else {
                    showTost(res.message, "warning");
                }
            }).catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            })
    }
    return (
        <CModal alignment="center" visible={QuotationNameadd}>
            <div className="modal-header">
                <h4 className="modal-title">{QuotationNameval == 0 ? "Add Quotation Name" : "Edit Quotation Name"} </h4>
                <button type="button" className="close" onClick={() => { QuotaionNameCleare(); setQuotationNameadd(false) }}>&times;</button>
            </div>
            <div className="modal-body">
                <Formik
                    onSubmit={QuotationNameval == 0 ? quotationNamehandleSubmit : handleEdit}
                    initialValues={initQuotaionNameValue}
                    validationSchema={QuotaionNameValidation}
                    key={formkey} >
                    {
                        ({
                            handleSubmit,
                            handleChange,
                            values,
                            touched,
                            errors,
                            setFieldValue,
                        }) => {
                            return (
                                <Form
                                    onSubmit={handleSubmit}
                                    onChange={handleChange}>
                                    <div className='mt-2'>
                                        <label>Name</label>
                                        <input type='text' name="quoatationName" defaultValue={values.quoatationName} placeholder='Enter Quatation Name' className={!!touched.quoatationName && !!errors.quoatationName ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='mt-2'>
                                        <label>Description of Work</label>
                                        <input type='text' name="workDescription" defaultValue={values.workDescription} placeholder='Enter Description of work' className={!!touched.workDescription && !!errors.workDescription ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='mt-2'>
                                        <label>Short Code</label>
                                        <input type='text' name="shortCode" defaultValue={values.shortCode} placeholder='Enter short Code' className={!!touched.shortCode && !!errors.shortCode ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='mt-2'>
                                        <label>Terms And Conditions</label>
                                        <CKEditor
                                            name="TermsAndCondition"
                                            id="TermsAndCondition"
                                            content={values.TermsAndCondition}
                                            events={{
                                                change: (eve) => {
                                                    const data = eve.editor.getData();
                                                    setFieldValue("TermsAndCondition", data);
                                                }
                                            }} />
                                    </div>
                                    <div className='mt-2'>
                                        <button type="submit" className='btn  btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                    </div>
                                </Form>
                            )
                        }
                    }
                </Formik>
            </div>
        </CModal>
    )
}
export const CompanyModal = ({ setcompanyadd, companyadd, setcompanyval, companyval, getcompanypost, showTost }) => {
    const companyValidation = Yup.object().shape({
        company: Yup.string().required()
    })
    const [formkey, setFormKey] = useState(Math.random() * 1000000);
    const [initCompanyValue, setInitCompanyValue] = useState({
        company: "",
        gstno: ""
    })
    const ComapnyCleare = () => {
        setInitCompanyValue({
            company: "",
            gstno: ""
        })
    }
    useEffect(() => {
        if (companyval != 0) {
            Get(`customer/Company/${companyval}`)
                .then((res) => {
                    setInitCompanyValue({
                        company: res.data.Company,
                        gstno: res.data.GSTNo
                    })
                    setcompanyval(companyval);
                    setcompanyadd(true);
                    setFormKey(Math.random() * 1000000);
                }).catch((err) => {
                    showTost("Failed to fetch icon details", "error");
                })
        }
    }, [companyval])
    const companyhandleSubmit = async (val) => {
        await Post(`customer/addCompany`, val)
            .then((res) => {
                if (res.success == true) {
                    ComapnyCleare();
                    getcompanypost();
                    setcompanyadd(false);
                    showTost("Your data has been Successfully added", "success");
                } else {
                    showTost(res.message, "warning");
                }
            }).catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            })
    }
    const handleEdit = async (val) => {
        val.id = companyval;
        await Post(`customer/editCompany`, val)
            .then((res) => {
                if (res.success == true) {
                    ComapnyCleare();
                    setcompanyadd(false);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            getcompanypost(true);
                        }
                    });
                } else {
                    showTost(res.message, "warning");
                }
            }).catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            })
    }
    return (
        <CModal alignment="center" visible={companyadd}>
            <div className="modal-header">
                <h4 className="modal-title">{companyval == 0 ? "Add Company" : "Edit Company"} </h4>
                <button type="button" className="close" onClick={() => { ComapnyCleare(); setcompanyadd(false) }}>&times;</button>
            </div>
            <div className="modal-body">
                <Formik
                    onSubmit={companyval == 0 ? companyhandleSubmit : handleEdit}
                    initialValues={initCompanyValue}
                    validationSchema={companyValidation}
                    key={formkey} >
                    {
                        ({
                            handleSubmit,
                            handleChange,
                            values,
                            touched,
                            errors,
                            setFieldValue,
                        }) => {
                            return (
                                <Form
                                    onSubmit={handleSubmit}
                                    onChange={handleChange}>
                                    <div className='mb-2'>
                                        <label>Company Name</label>
                                        <input type='text' defaultValue={values.company} name="company" placeholder='Enter Company' className={!!touched.company && !!errors.company ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='mb-2'>
                                        <label>GST No.</label>
                                        <input type="text" defaultValue={values.gstno} name='gstno' placeholder="GST No." className={!!touched.gstno && !!errors.gstno ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='mt-2'>
                                        <button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                    </div>
                                </Form>
                            )
                        }
                    }
                </Formik>
            </div>
        </CModal>
    )
}
export const BranchModal = ({ setbranchadd, branchadd, getbranchpost, showTost, setbranchval, branchval }) => {
    const branchValidation = Yup.object().shape({
        company: Yup.string().required(),
        branchName: Yup.string().required(),
    })
    const [formkey, setFormKey] = useState(Math.random() * 1000000);
    const [Company, setCompany] = useState([]);
    const [Country, setCountry] = useState([]);
    const [State, setState] = useState([]);
    const [City, setCity] = useState([]);
    const [initBranchValue, setInitBranchValue] = useState({
        branchName: "",
        address: "",
        company: "",
        country: "",
        state: "",
        city: "",
    })
    const branchCleare = () => {
        setInitBranchValue({
            branchName: "",
            address: "",
            company: "",
            country: "",
            state: "",
            city: "",
        })
    }
    useEffect(() => {
        if (branchval != 0) {
            Get(`customer/getBranchById/${branchval}`)
                .then((res) => {
                    setInitBranchValue({
                        branchName: res.data.Name,
                        address: res.data.Address,
                        company: res.data.Company,
                        country: res.data.Country,
                        state: res.data.State,
                        city: res.data.City,
                    })
                    setbranchval(branchval);
                    setbranchadd(true);
                    setFormKey(Math.random() * 1000000);
                }).catch((err) => {
                    showTost("Failed to fetch icon details", "error");
                })
        }
    }, [branchval])
    const branchHandleSubmit = async (val) => {
        val.name = val.branchName;
        await Post(`customer/addBranch`, val)
            .then((res) => {
                if (res.success == true) {
                    branchCleare();
                    getbranchpost();
                    setbranchadd(false);
                    showTost("Your data has been Successfully added", "success");
                } else {
                    showTost(res.message, "warning");
                }
            }).catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            })
    }
    const handleEdit = async (val) => {
        val.id = branchval;
        val.name = val.branchName;
        await Post(`customer/editBranch`, val)
            .then((res) => {
                if (res.success == true) {
                    branchCleare();
                    setbranchadd(false);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            getbranchpost(true);
                        }
                    });
                } else {
                    showTost(res.message, "warning");
                }
            }).catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            })
    }
    useEffect(() => {
        Post(`customer/getCompany`, { active: true })
            .then((res) => {
                setCompany(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
        Post(`master/getCountry`, { active: true })
            .then((res) => {
                setCountry(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
        Post(`master/getState`, { active: true })
            .then((res) => {
                setState(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
        Post(`master/getCity`, { active: true })
            .then((res) => {
                setCity(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }, []);
    return (
        <CModal alignment="center" visible={branchadd}>
            <div className="modal-header">
                <h4 className="modal-title">{branchval == 0 ? "Add Branch" : "Edit Branch"} </h4>
                <button type="button" className="close" onClick={() => { branchCleare(); setbranchadd(false) }}>&times;</button>
            </div>
            <div className="modal-body">
                <Formik
                    onSubmit={branchval == 0 ? branchHandleSubmit : handleEdit}
                    initialValues={initBranchValue}
                    validationSchema={branchValidation}
                    key={formkey} >
                    {
                        ({
                            handleSubmit,
                            handleChange,
                            values,
                            touched,
                            errors,
                            setFieldValue,
                        }) => {
                            return (
                                <Form
                                    onSubmit={handleSubmit}
                                    onChange={handleChange}>
                                    <div className="form-group">
                                        <label><i className="fa fa-building-o text-danger mr-2" />Company</label>
                                        <select defaultValue={values.company} disabled={branchval == 0 ? false : true} className={!!touched.company && !!errors.company ? "form-select is-invalid" : "form-select"} name='company'>
                                            <option>Select Company</option>
                                            {
                                                Company.map((x, i) => (
                                                    <option key={i} value={x._id}>{x.Company}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>Branch Name</label>
                                        <input type='text' name="branchName" defaultValue={values.branchName} placeholder='Enter Branch Name' className={!!touched.branchName && !!errors.branchName ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className="form-group">
                                        <label>Country</label>
                                        <select
                                            name="country"
                                            value={values.country}
                                            onChange={(e) => {
                                                setFieldValue("country", e.target.value);
                                                setFieldValue("state", "");
                                                setFieldValue("city", "");
                                            }}
                                            className="form-select" >
                                            <option>Select Country</option>
                                            {Country.map((item, index) => (
                                                <option key={index} value={item._id}>
                                                    {item.Name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>State</label>
                                        <select
                                            name="state"
                                            value={values.state}
                                            onChange={(e) => {
                                                setFieldValue("state", e.target.value);
                                                setFieldValue("city", "");
                                            }}
                                            className="form-select" >
                                            <option>Select State</option>
                                            {State.filter((x) => x.Country?._id === values.country).map((x, i) => (
                                                <option key={i} value={x._id}>{x.Name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>City</label>
                                        <select
                                            name="city"
                                            value={values.city}
                                            onChange={handleChange}
                                            className="form-select">
                                            <option>Select City</option>
                                            {City.filter((x) => x.State?._id === values.state).map((x, i) => (
                                                <option key={i} value={x._id}>{x.Name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>Address</label>
                                        <input type='text' name="address" defaultValue={values.address} placeholder='Enter Branch Address' className={!!touched.address && !!errors.address ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='col-12 mt-2'>
                                        <button type="submit" className='btn btn-sm font-weight-bold btn-success mt-2'>
                                            <i className="fa fa-check" /> Save
                                        </button>
                                    </div>
                                </Form>
                            )
                        }
                    }
                </Formik>
            </div>
        </CModal>
    )
}
export const CategoryModal = ({ setcategoryadd, categoryadd, getcategorypost, showTost, setcategoryval, categoryval }) => {
    const categoryValidation = Yup.object().shape({
        categoryName: Yup.string().required()
    })
    const [formkey, setFormKey] = useState(Math.random() * 1000000);
    const [initCategoryValue, setInitCategoryValue] = useState({
        categoryName: "",
    })
    const categoryCleare = () => {
        setInitCategoryValue({
            categoryName: "",
        })
    }
    useEffect(() => {
        if (categoryval != 0) {
            Get(`master/category/${categoryval}`)
                .then((res) => {
                    setInitCategoryValue({
                        categoryName: res.data.Name
                    })
                    setcategoryval(categoryval);
                    setcategoryadd(true);
                    setFormKey(Math.random() * 1000000);
                }).catch((err) => {
                    showTost("Failed to fetch icon details", "error");
                })
        }
    }, [categoryval])
    const categoryhandleSubmit = async (val) => {
        val.name = val.categoryName;
        await Post(`master/addCategory`, val)
            .then((res) => {
                if (res.success == true) {
                    categoryCleare();
                    getcategorypost();
                    setcategoryadd(false);
                    showTost("Your data has been Successfully added", "success");
                } else {
                    showTost(res.message, "warning");
                }
            }).catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            })
    }
    const handleEdit = async (val) => {
        val.id = categoryval;
        val.name = val.categoryName;
        await Post(`master/editCategory`, val)
            .then((res) => {
                if (res.success == true) {
                    categoryCleare();
                    setcategoryadd(false);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            getcategorypost(true);
                        }
                    });
                } else {
                    showTost(res.message, "warning");
                }
            }).catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            })
    }
    return (
        <CModal alignment="center" visible={categoryadd}>
            <div className="modal-header">
                <h4 className="modal-title">{categoryval == 0 ? "Add Category" : "Edit Category"} </h4>
                <button type="button" className="close" onClick={() => { categoryCleare(); setcategoryadd(false) }}>&times;</button>
            </div>
            <div className="modal-body">
                <Formik
                    onSubmit={categoryval == 0 ? categoryhandleSubmit : handleEdit}
                    initialValues={initCategoryValue}
                    validationSchema={categoryValidation}
                    key={formkey} >
                    {
                        ({
                            handleSubmit,
                            handleChange,
                            values,
                            touched,
                            errors,
                            setFieldValue,
                        }) => {
                            return (
                                <Form
                                    onSubmit={handleSubmit}
                                    onChange={handleChange}>
                                    <div className='mt-2'>
                                        <label>Name</label>
                                        <input type='text' name="categoryName" defaultValue={values.categoryName} placeholder='Enter Category Name' className={!!touched.categoryName && !!errors.categoryName ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='mt-2'>
                                        <button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                    </div>
                                </Form>
                            )
                        }
                    }
                </Formik>
            </div>
        </CModal>
    )
}
export const SubcategoryModal = ({ setsubcategoryadd, subcategoryadd, getsubcategorypost, showTost, setsubcategoryval, subcategoryval }) => {
    const subcategoryValidation = Yup.object().shape({
        subcategoryName: Yup.string().required(),
        category: Yup.string().required(),
    })
    const [formkey, setFormKey] = useState(Math.random() * 1000000);
    const [Category, setCategory] = useState([]);
    const [initSubcategoryValue, setInitSubcategoryValue] = useState({
        subcategoryName: "",
        category: "",
    })
    const subcategoryCleare = () => {
        setInitSubcategoryValue({
            subcategoryName: "",
            category: "",
        })
    }
    useEffect(() => {
        if (subcategoryval != 0) {
            Get(`master/subcategory/${subcategoryval}`)
                .then((res) => {
                    setInitSubcategoryValue({
                        subcategoryName: res.data.Name,
                        category: res.data.Category,
                    })
                    setsubcategoryval(subcategoryval);
                    setsubcategoryadd(true);
                    setFormKey(Math.random() * 1000000);
                }).catch((err) => {
                    showTost("Failed to fetch icon details", "error");
                })
        }
    }, [subcategoryval])
    const subcategoryhandleSubmit = async (val) => {
        val.name = val.subcategoryName;
        await Post(`master/addSubCategory`, val)
            .then((res) => {
                if (res.success == true) {
                    subcategoryCleare();
                    getsubcategorypost();
                    setsubcategoryadd(false);
                    showTost("Your data has been Successfully added", "success");
                } else {
                    showTost(res.message, "warning");
                }
            }).catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            })
    }
    const handleEdit = async (val) => {
        val.id = subcategoryval;
        val.name = val.subcategoryName;
        await Post(`master/editSubCategory`, val)
            .then((res) => {
                if (res.success == true) {
                    subcategoryCleare();
                    setsubcategoryadd(false);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            getsubcategorypost(true);
                        }
                    });
                } else {
                    showTost(res.message, "warning");
                }
            }).catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            })
    }
    useEffect(() => {
        Post(`master/getCategorys`, { active: true })
            .then((res) => {
                setCategory(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }, []);
    return (
        <CModal alignment="center" visible={subcategoryadd}>
            <div className="modal-header">
                <h4 className="modal-title">{subcategoryval == 0 ? "Add Subcategory" : "Edit Subcategory"} </h4>
                <button type="button" className="close" onClick={() => { subcategoryCleare(); setsubcategoryadd(false) }}>&times;</button>
            </div>
            <div className="modal-body">
                <Formik
                    onSubmit={subcategoryval == 0 ? subcategoryhandleSubmit : handleEdit}
                    initialValues={initSubcategoryValue}
                    validationSchema={subcategoryValidation}
                    key={formkey} >
                    {
                        ({
                            handleSubmit,
                            handleChange,
                            values,
                            touched,
                            errors,
                            setFieldValue,
                        }) => {
                            return (
                                <Form
                                    onSubmit={handleSubmit}
                                    onChange={handleChange}>
                                    <div className='mt-2'>
                                        <label>Category</label>
                                        <select name="category" disabled={subcategoryval == 0 ? false : true} defaultValue={values.category} className={!!touched.category && !!errors.category ? "form-select is-invalid" : "form-select"}>
                                            <option value="">Select Category</option>
                                            {Category
                                                .map((x, i) => (
                                                    <option key={i} value={x._id}>{x.Name}</option>
                                                ))}
                                        </select>
                                    </div>
                                    <div className='mt-2'>
                                        <label>SubCategory</label>
                                        <input type='text' name="subcategoryName" defaultValue={values.subcategoryName} placeholder='Enter SubCategory Name' className={!!touched.subcategoryName && !!errors.subcategoryName ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='mt-2'>
                                        <button type="submit" className='btn  btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                    </div>
                                </Form>
                            )
                        }
                    }
                </Formik>
            </div>
        </CModal>
    )
}
export const ShipAddModal = ({ setShipadd, Shipadd, getcustomer, showTost, ShipAddval, setShipAddval, selectedCustomerId }) => {
    const shipAddValidation = Yup.object().shape({
        address: Yup.string().required(),
        city: Yup.string().required(),
        state: Yup.string().required(),
        country: Yup.string().required(),
    });
    const [ShippingAddress, setShippingAddress] = useState([]);
    const [State, setState] = useState([]);
    const [Country, setCountry] = useState([]);
    const [City, setCity] = useState([]);
    const [initiShipAddValue, setInitiShipAddValue] = useState({
        customerId: selectedCustomerId,
        address: "",
        city: "",
        state: "",
        country: "",
    });
    const shipaddclear = () => {
        setInitiShipAddValue({
            customerId: selectedCustomerId,
            address: "",
            city: "",
            state: "",
            country: "",
        });
    }
    useEffect(() => {
        getCustomerPost();
        Post(`master/getCountry`, { active: true })
            .then((res) => {
                setCountry(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
        Post(`master/getState`, { active: true })
            .then((res) => {
                setState(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
        Post(`master/getCity`, { active: true })
            .then((res) => {
                setCity(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }, []);
    const [formkey, setFormKey] = useState(Math.random() * 1000000);
    const shipaddhandleSubmit = async (val) => {
        val.customerId = selectedCustomerId;
        await Post(`customer/addShippingAddress`, val)
            .then((res) => {
                if (res.success == true) {
                    shipaddclear();
                    setShipadd(false);
                    showTost("Your data has been Successfully added", "success");
                    getcustomer(selectedCustomerId);
                    setFormKey(Math.random() * 1000000);
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };

    const getCustomerPost = async () => {
        Get(`customer/getCustomerById/${selectedCustomerId}`)
            .then((res) => {
                setShippingAddress(res.data[0]?.ShippingAddress)
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    useEffect(() => {
        if (ShipAddval != 0) {
            let Shippingobj = ShippingAddress.find(f => f._id == ShipAddval);
            if (Shippingobj) {
                setInitiShipAddValue({
                    customerId: Shippingobj.Customer,
                    address: Shippingobj.Address,
                    city: Shippingobj.City?._id,
                    state: Shippingobj.State?._id,
                    country: Shippingobj.Country?._id,
                });
                setShipadd(true);
                setShipAddval(ShipAddval);
                setFormKey(Math.random() * 1000000);
            }
        }
    }, [ShipAddval, ShippingAddress]);
    const handleEdit = async (val) => {
        val.id = ShipAddval;
        val.customerId = selectedCustomerId;
        await Post(`customer/editShippingAddress`, val)
            .then((res) => {
                if (res.success == true) {
                    shipaddclear();
                    setShipadd(false);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            getcustomer(selectedCustomerId);
                        }
                    });
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    return (
        <CModal alignment="center" visible={Shipadd}>
            <div className="modal-header">
                <h4 className="modal-title">{ShipAddval == 0 ? "Add Shipping Address" : "Edit Shipping Address"} </h4>
                <button type="button" className="close" onClick={() => { shipaddclear(); setShipadd(false) }}>&times;</button>
            </div>
            <div className="modal-body">
                <Formik
                    onSubmit={ShipAddval == 0 ? shipaddhandleSubmit : handleEdit}
                    initialValues={initiShipAddValue}
                    validationSchema={shipAddValidation}
                    key={formkey}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        values,
                        touched,
                        errors,
                        setFieldValue,
                    }) => {
                        return (
                            <Form
                                onSubmit={handleSubmit}
                                onChange={handleChange}>
                                <div className='mb-2'>
                                    <label>Address</label>
                                    <input type='text' name="address" defaultValue={values.address} placeholder='Enter Address' className={!!touched.address && !!errors.address ? "form-control is-invalid" : "form-control"} />
                                </div>
                                <div className="mb-2">
                                    <label>Country</label>
                                    <select defaultValue={values.country} className={!!touched.country && !!errors.country ? "form-select is-invalid" : "form-select"} name='country'>
                                        <option>Select Country</option>
                                        {
                                            Country.map((x, i) => (
                                                <option key={i} value={x._id}>{x.Name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="mb-2">
                                    <label>State</label>
                                    <select defaultValue={values.state} className={!!touched.state && !!errors.state ? "form-select is-invalid" : "form-select"} name='state'>
                                        <option>Select State</option>
                                        {State.filter((x) => x.Country?._id == values.country).map((x, i) => (
                                            <option key={i} value={x._id}>{x.Name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="mb-2">
                                    <label>City</label>
                                    <select name='city' defaultValue={values.city} className={!!touched.city && !!errors.city ? "form-control is-invalid" : "form-control"}>
                                        <option>Select City</option>
                                        {
                                            City.filter((x) => x.State?._id == values.state).map((x, i) => (
                                                <option key={i} value={x._id}>{x.Name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className='mt-2'>
                                    <button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </CModal>
    );
}
export const BillAddModal = ({ setBillAdd, BillAdd, getcustomer, showTost, BillAddval, setBillAddval, selectedCustomerId }) => {
    const billAddValidation = Yup.object().shape({
        address: Yup.string().required(),
        city: Yup.string().required(),
        state: Yup.string().required(),
        country: Yup.string().required(),
    });
    const [BillingAddress, setBillingAddress] = useState([]);
    const [State, setState] = useState([]);
    const [Country, setCountry] = useState([]);
    const [City, setCity] = useState([]);
    const [initiBillAddValue, setInitiBillAddValue] = useState({
        customerId: selectedCustomerId,
        address: "",
        city: "",
        state: "",
        country: "",
    });
    const billAddclear = () => {
        setInitiBillAddValue({
            customerId: selectedCustomerId,
            address: "",
            city: "",
            state: "",
            country: "",
        });
    }
    useEffect(() => {
        getCustomerPost();
        Post(`master/getCountry`, { active: true })
            .then((res) => {
                setCountry(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
        Post(`master/getState`, { active: true })
            .then((res) => {
                setState(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
        Post(`master/getCity`, { active: true })
            .then((res) => {
                setCity(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }, []);
    const [formkey, setFormKey] = useState(Math.random() * 1000000);
    const getCustomerPost = async () => {
        Get(`customer/getCustomerById/${selectedCustomerId}`)
            .then((res) => {
                setBillingAddress(res.data[0]?.BillingAddress)
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const billAddhandleSubmit = async (val) => {
        val.customerId = selectedCustomerId;
        await Post(`customer/addBillingAddress`, val)
            .then((res) => {
                if (res.success == true) {
                    billAddclear();
                    getcustomer(selectedCustomerId);
                    setBillAdd(false);
                    showTost("Your data has been Successfully added", "success");
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    useEffect(() => {
        if (BillAddval != 0) {
            let Billingobj = BillingAddress.find(f => f._id == BillAddval);
            if (Billingobj) {
                setInitiBillAddValue({
                    customerId: Billingobj.Customer,
                    address: Billingobj.Address,
                    city: Billingobj.City?._id,
                    state: Billingobj.State?._id,
                    country: Billingobj.Country?._id,
                });
                setBillAdd(true);
                setBillAddval(BillAddval);
                setFormKey(Math.random() * 1000000);
            }
        }
    }, [BillAddval, BillingAddress]);

    const handleEdit = async (val) => {
        val.id = BillAddval;
        val.customerId = selectedCustomerId;
        await Post(`customer/editBillingAddress`, val)
            .then((res) => {
                if (res.success == true) {
                    billAddclear();
                    setBillAdd(false);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            getcustomer(selectedCustomerId);
                        }
                    });
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    return (
        <CModal alignment="center" visible={BillAdd}>
            <div className="modal-header">
                <h4 className="modal-title">{BillAddval == 0 ? "Add Billing Address" : "Edit Billing Address"} </h4>
                <button type="button" className="close" onClick={() => { billAddclear(); setBillAdd(false) }}>&times;</button>
            </div>
            <div className="modal-body">
                <Formik
                    onSubmit={BillAddval == 0 ? billAddhandleSubmit : handleEdit}
                    initialValues={initiBillAddValue}
                    validationSchema={billAddValidation}
                    key={formkey}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        values,
                        touched,
                        errors,
                        setFieldValue,
                    }) => {
                        return (
                            <Form
                                onSubmit={handleSubmit}
                                onChange={handleChange}>
                                <div className='mb-2'>
                                    <label>Address</label>
                                    <input type='text' name="address" defaultValue={values.address} placeholder='Enter Address' className={!!touched.address && !!errors.address ? "form-control is-invalid" : "form-control"} />
                                </div>
                                <div className="mb-2">
                                    <label>Country</label>
                                    <select defaultValue={values.country} className={!!touched.country && !!errors.country ? "form-select is-invalid" : "form-select"} name='country'>
                                        <option>Select Country</option>
                                        {
                                            Country.map((x, i) => (
                                                <option key={i} value={x._id}>{x.Name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="mb-2">
                                    <label>State</label>
                                    <select defaultValue={values.state} className={!!touched.state && !!errors.state ? "form-select is-invalid" : "form-select"} name='state'>
                                        <option>Select State</option>
                                        {State.filter((x) => x.Country?._id == values.country).map((x, i) => (
                                            <option key={i} value={x._id}>{x.Name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="mb-2">
                                    <label>City</label>
                                    <select name='city' defaultValue={values.city} className={!!touched.city && !!errors.city ? "form-control is-invalid" : "form-control"}>
                                        <option>Select City</option>
                                        {
                                            City.filter((x) => x.State?._id == values.state).map((x, i) => (
                                                <option key={i} value={x._id}>{x.Name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className='mt-2'>
                                    <button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </CModal>
    );
}
export const TaskTypeModal = ({ setTaskTypeAdd, TaskTypeadd, getTaskType, showTost, TaskTypeVal, setTasktypeVal }) => {
    const validationTaskType = Yup.object().shape({
        taskTypeName: Yup.string().required(),
    });
    const [initValueTaskType, setinitValueTaskType] = useState({
        taskTypeName: "",
    });
    const taskTypeclear = () => {
        setinitValueTaskType({
            taskTypeName: "",
        });
    }
    const [formkey, setFormKey] = useState(Math.random() * 1000000);
    const handleSubmitTaskType = async (val) => {
        val.name = val.taskTypeName;
        await Post(`master/addTaskType`, val)
            .then((res) => {
                if (res.success == true) {
                    taskTypeclear();
                    getTaskType()
                    setTaskTypeAdd(false);
                    showTost("Your data has been Successfully added", "success");
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    useEffect(() => {
        if (TaskTypeVal != 0) {
            Get(`master/TaskType/${TaskTypeVal}`)
                .then((res) => {
                    setinitValueTaskType({
                        taskTypeName: res.data.Name,
                    });
                    setTaskTypeAdd(true);
                    setTasktypeVal(TaskTypeVal);
                    setFormKey(Math.random() * 1000000);
                }).catch((err) => {
                    showTost("Failed to fetch type details", "error");
                });
        }
    }, [TaskTypeVal]);
    const handleEdit = async (val) => {
        val.id = TaskTypeVal;
        val.name = val.taskTypeName;
        await Post(`master/editTaskType`, val)
            .then((res) => {
                if (res.success == true) {
                    taskTypeclear();
                    setTaskTypeAdd(false);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            getTaskType(true);
                        }
                    });
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    return (
        <CModal alignment="center" visible={TaskTypeadd}>
            <div className="modal-header">
                <h4 className="modal-title">{TaskTypeVal == 0 ? "Add Task Type" : "Edit Task Type"} </h4>
                <button type="button" className="close" onClick={() => { taskTypeclear(); setTaskTypeAdd(false) }}>&times;</button>
            </div>
            <div className="modal-body">
                <Formik
                    onSubmit={TaskTypeVal == 0 ? handleSubmitTaskType : handleEdit}
                    initialValues={initValueTaskType}
                    validationSchema={validationTaskType}
                    key={formkey}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        values,
                        touched,
                        errors,
                        setFieldValue,
                    }) => {
                        return (
                            <Form
                                onSubmit={handleSubmit}
                                onChange={handleChange}>
                                <div className='row'>
                                    <div className='col-12 mb-2'>
                                        <label>Name</label>
                                        <input type='text' name="taskTypeName" defaultValue={values.taskTypeName} placeholder='Enter Name' className={!!touched.taskTypeName && !!errors.taskTypeName ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='col-12'>
                                        <button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </CModal>
    )
}
