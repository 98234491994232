import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { Post, Get } from '../../Layout/ajax';
import { CModal } from '@coreui/react';
import { immediateToast } from "izitoast-react";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { RoleModal, UserModal } from '../CRM/Modal';

const validation = Yup.object().shape({
    name: Yup.string().required(),
    groupname: Yup.string().required(),
});
const Status = () => {
    const [initValue, setinitValue] = useState({
        name: "",
        groupname: "",
        role: "",
        assign: "",
        color: "",
    });
    const [data, setData] = useState([]);
    const [statusadd, setstatusadd] = useState(false);
    const [status, setstatus] = useState(0);
    const [Role, setRole] = useState([]);
    const [User, setUser] = useState([]);
    const [filter, setfilter] = useState("");
    const [useradd, setuseradd] = useState(false);
    const [userval, setuserval] = useState(0);
    const [roleAdd, setRoleadd] = useState(false);
    const [roleVal, setRoleval] = useState(0);
    const showTost = (msg, theme) => {
        immediateToast(theme, {
            message: msg,
            timeout: 3000
        });
    }
    useEffect(() => {
        getuserpost();
        getRole();
    }, []);
    useEffect(() => {
        getpost(true);
    }, [filter]);
    const getpost = async (val) => {
        await Post(`master/getStatus`, { active: val, GroupName: filter })
            .then((res) => {
                setData(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const getuserpost = async (val) => {
        Post(`users/getAllUser`, { active: true })
            .then((res) => {
                setUser(res);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const getRole = async (val) => {
        Post(`master/getRole`, { active: true })
            .then((res) => {
                setRole(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const handleSubmit = async (val) => {
        await Post(`master/addStatus`, val)
            .then((res) => {
                if (res.success == true) {
                    clear();
                    setstatusadd(false);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            getpost(true);
                        }
                    });
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const handleEdit = async (val) => {
        val.id = status;
        await Post(`master/editStatus`, val)
            .then((res) => {
                if (res.success == true) {
                    clear();
                    setstatusadd(false);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            getpost(true);
                        }
                    });
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const clear = () => {
        setinitValue({
            name: "",
            groupname: "",
            role: "",
            assign: "",
            color: "",
        });
    }
    const get = async (val) => {
        if (val != 0) {
            Get(`master/Status/${val}`)
                .then((res) => {
                    setinitValue({
                        name: res.data.Name,
                        groupname: res.data.GroupName,
                        role: res.data.Role,
                        assign: res.data.Assign,
                        color: res.data.Color,
                    });
                    setstatusadd(true);
                    setstatus(val);
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
    }
    const changeStatus = async (val, status) => {
        if (status == false) {
            Swal.fire({
                title: "Are you sure want to Inactive ?",
                icon: "warning",
                confirmButtonText: "Inactive",
                confirmButtonColor: "#FD711A",
                showCancelButton: true,
                cancelButtonColor: "#0F3E6A",
            }).then(async ({ isConfirmed }) => {
                if (isConfirmed) {
                    await Post(`master/changeStatus`, { id: val, active: status })
                        .then((res) => {
                            if (res.success == true) {
                                getpost(true);
                            }
                            else {
                                showTost(res.message, "warning");
                            }
                        })
                        .catch((err) => {
                            showTost("SOMETHING WENT WRONG ON SERVER", "error");
                        });
                }
            });
        }
        else {
            await Post(`master/changeStatus`, { id: val, active: status })
                .then((res) => {
                    if (res.success == true) {
                        getpost(false);
                        showTost("Your data has been successfully activated", "success");
                    }
                    else {
                        showTost(res.message, "warning");
                    }
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
    };

    const deleteStatus = async (val) => {
        Swal.fire({
            title: "Are you sure want to delete ?",
            icon: "error",
            confirmButtonText: "Delete",
            confirmButtonColor: "#FD711A",
            showCancelButton: true,
            cancelButtonColor: "#0F3E6A",
        }).then(async ({ isConfirmed }) => {
            if (isConfirmed) {
                await Get(
                    `master/deleteStatus/${val}`
                )
                    .then(res => {
                        if (res.success == false) {
                            showTost(res.msg, "warning");
                        } else {
                            getpost(true);
                        }
                    })
                    .catch((err) => {
                        showTost("SOMETHING WENT WRONG ON SERVER", "error");
                    });
            }
        });
    };
    const columns = [
        {
            name: "Status Group",
            selector: (row) => row.GroupName,
        },
        {
            name: "Name",
            selector: (row) => row.Name,
        },
        {
            name: "Color",
            selector: (row) => <div class="btn btn-square btn-lg" style={{ backgroundColor: row.Color }}></div>,
        },
        {
            name: "Status",
            selector: (row) => <input type='checkbox' value={row.is_active} checked={row.is_active == true ? "checked" : ""} onClick={() => { row.is_active == true ? changeStatus(row._id, false) : changeStatus(row._id, true) }} />,
        },
        {
            name: "",
            selector: (row) => row.is_active == true ? <><i className='btn btn-sm btn-success fa fa-pencil mr-1' onClick={() => { get(row._id); }} /><i className='btn btn-sm btn-danger fa fa-trash' onClick={() => { deleteStatus(row._id); }} /></> : "",
        }
    ];

    return (
        <div className='content-wrapper'>
            <div className="">
                <div className="mb-2 mt-2 row">
                    <div className="col-lg-7">
                        <h2>Status</h2>
                    </div>
                    <div className="col-5 mt-2">
                        <span className='btn btn-sm btn-warning float-right' onClick={() => { setstatusadd(true); setstatus(0); }}>
                            <i className="fa fa-plus mr-2" />
                            Add Status
                        </span>
                    </div>
                    <div className="col-12">
                        <div className="float-right">
                            <div className="input-group">
                                <div className="mr-2">
                                    <select className="form-select" onChange={(e) => setfilter(e.target.value)}>
                                        <option value="">Select Status Group</option>
                                        <option>Leads</option>
                                        <option>Prospects</option>
                                        <option>Orders</option>
                                        {/* <option>Recovery</option> */}
                                        <option>Support</option>
                                        <option>Tasks</option>
                                        {/* <option>Quotations</option> */}
                                    </select>
                                </div>
                                <div className="custom-control custom-switch mt-2">
                                    <input type="checkbox" className="custom-control-input" defaultChecked id="customSwitch1" data-check_true={true} data-check_false={false} onChange={(event) => {
                                        let ele = event.target;
                                        let selectedId = ele.checked ? event.target.getAttribute("data-check_true") : event.target.getAttribute("data-check_false");
                                        getpost(selectedId)
                                    }} />
                                    <label className="custom-control-label" for="customSwitch1"> Active/Inactive </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-3">
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                    />
                </div>
            </div>
            <CModal alignment="center" visible={statusadd}>
                <div className="modal-header">
                    <h4 className="modal-title">{status == 0 ? "Add Status" : "Update Status"}</h4>
                    <button type="button" className="close" onClick={() => { clear(); setstatusadd(false) }}>&times;</button>
                </div>
                <div className="modal-body">
                    <Formik
                        onSubmit={status == 0 ? handleSubmit : handleEdit}
                        initialValues={initValue}
                        validationSchema={validation}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            touched,
                            errors,
                            setFieldValue,
                        }) => {
                            return (
                                <Form
                                    onSubmit={handleSubmit}
                                    onChange={handleChange}>
                                    <div className='mt-2'>
                                        <label>Status Group</label>
                                        <select name="groupname" disabled={status == 0 ? false : true} defaultValue={values.groupname} className={!!touched.groupname && !!errors.groupname ? "form-select is-invalid" : "form-select"}>
                                            <option value="">Select Status Group</option>
                                            <option>Leads</option>
                                            <option>Prospects</option>
                                            <option>Orders</option>
                                            {/* <option>Recovery</option> */}
                                            <option>Support</option>
                                            <option>Tasks</option>
                                            {/* <option>Quotations</option> */}
                                        </select>
                                    </div>
                                    <div className='mt-2'>
                                        <label>Name</label>
                                        <input type='text' name="name" defaultValue={values.name} placeholder='Enter Status Name' className={!!touched.name && !!errors.name ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='mt-2'>
                                        <label><i className="fa fa-user text-secondary mr-2" /> Assign</label>
                                        <div className='input-group'>
                                            <select name="assign" defaultValue={values.assign} className={!!touched.assign && !!errors.assign ? "form-select is-invalid" : "form-select"}>
                                                <option value="">Select Assign</option>
                                                {User
                                                    .map((x, i) => (
                                                        <option key={i} value={x._id}>{x.name}</option>
                                                    ))}
                                            </select>
                                            <span className="input-group-text">
                                                {values.assign ? (
                                                    <i className='fa fa-pencil' onClick={() => { setuserval(values.assign); setuseradd(true) }} />
                                                ) : (
                                                    <i className='fa fa-plus' onClick={() => { setuserval(0); setuseradd(true) }} />
                                                )}
                                                {useradd && (
                                                    <UserModal
                                                        useradd={useradd}
                                                        setuseradd={setuseradd}
                                                        getuserpost={getuserpost}
                                                        showTost={showTost}
                                                        userval={userval}
                                                        setuserval={setuserval}
                                                    />
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                    <div className='mt-2'>
                                        <label>Role</label>
                                        <div className='input-group'>
                                            <select name="role" defaultValue={values.role} className={!!touched.role && !!errors.role ? "form-select is-invalid" : "form-select"}>
                                                <option>Select Role</option>
                                                {Role
                                                    .map((x, i) => (
                                                        <option key={i} value={x._id}>{x.Name}</option>
                                                    ))}
                                            </select>
                                            <span className="input-group-text">
                                                {values.role ? (
                                                    <i className='fa fa-pencil' onClick={() => { setRoleval(values.role); setRoleadd(true) }} />
                                                ) : (
                                                    <i className='fa fa-plus' onClick={() => { setRoleval(0); setRoleadd(true) }} />
                                                )}
                                                {roleAdd && (
                                                    <RoleModal
                                                        roleAdd={roleAdd}
                                                        setRoleadd={setRoleadd}
                                                        getRole={getRole}
                                                        showTost={showTost}
                                                        roleVal={roleVal}
                                                        setRoleval={setRoleval}
                                                    />
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                    <div className='mt-2'>
                                        <label>Color</label>
                                        <input type='color' name="color" defaultValue={values.color} placeholder='Enter Status Name' className={!!touched.color && !!errors.color ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='mt-2'>
                                        <button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </CModal>
        </div >
    );
}

export default Status;