import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { Post, Get, File, FileBlob } from '../../Layout/ajax';
import { CModal } from '@coreui/react';
import * as Yup from "yup";
import moment from "moment";
import { ButtonGroup } from 'reactstrap';
import Select from "react-select";
import { Btn, Spinner } from '../../AbstractElements';
import { immediateToast } from "izitoast-react";
import { Link, useNavigate } from 'react-router-dom';
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Swal from "sweetalert2";
import Pagination from "react-js-pagination";
import PDFViewer from '../../Components/PDFViewer';
import styles from './Quotes.module.scss';

const templatevalidation = Yup.object().shape({
    template: Yup.string().required(),
});
const statusvalidation = Yup.object().shape({
    status: Yup.string().required(),
});
const Support = () => {
    const showTost = (msg, theme) => {
        immediateToast(theme, {
            message: msg,
            timeout: 3000
        });
    }
    const $ = window.$;
    let navigate = useNavigate();
    const [data, setData] = useState([]);
    const [sales, setsales] = useState([]);
    const [support, setsupport] = useState(0);
    const [filtersales, setfiltersales] = useState("");
    const [filterstatus, setfilterstatus] = useState("");
    const [changestatus, setchangestatus] = useState(false);
    const [productadd, setproductadd] = useState(false);
    const [Status, setStatus] = useState([]);
    const [useradd, setuseradd] = useState(false);
    const [isGridView, setIsGridView] = useState(false);
    const [customeradd, setcustomeradd] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [pageNo, setPageNo] = useState(1);
    const [perPage, setPerPage] = useState(6);
    const [filtername, setfiltername] = useState("");
    const [lastTicketCode, setLastTicketCode] = useState('');
    useEffect(() => {
        getuserpost();
        Post(`template/getAll`, { active: true })
            .then((res) => {
                settemplate(res.filter(
                    (x) => x.TemplateFor == "Support"
                ))
                var set = res.filter(x => x.TemplateFor == "Support" && x.is_default == true).map(x => x._id)
                setinittemplateValue({
                    template: set[0]
                });
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
        Post(`master/getStatus`, { active: true })
            .then((res) => {
                setStatus(res.data?.filter(f => f.GroupName == "Support"));
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }, []);
    const NexPage = (NexPage) => {
        setPageNo(NexPage);
    };
    const getuserpost = async (val) => {
        Post(`users/getAllUser`, { active: true })
            .then((res) => {
                setsales(res)
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }

    useEffect(() => {
        getpost(true);
    }, [filtersales, filterstatus, pageNo, perPage, filtername]);
    const getpost = async (val) => {
        let skipa = perPage * (pageNo - 1);
        await Post(`support/getAllSupport`, { active: val, sales: filtersales, status: filterstatus, skip: skipa, per_page: perPage, filter: filtername })
            .then((res) => {
                setData(res.data.data)
                setTotalCount(res.data.count);
                setLastTicketCode(res.lastTicketCode?.TicketCode);
            })
            .catch((err) => {
                setTotalCount(0);
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const [initststusValue, setinitststusValue] = useState({
        status: "",
    });
    const getbyid = async (val) => {
        if (val != 0) {
            Get(`support/getSupportById/${val}`)
                .then((res) => {
                    setinitststusValue({
                        status: res.data[0].Status,
                    });
                    setchangestatus(true);
                    setsupport(val);
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
    }
    const statuschange = async (val) => {
        var newval = {};
        newval.id = support;
        newval.status = val.status;
        await Post(`support/updateStatus`, newval)
            .then((res) => {
                getpost(true);
                setchangestatus(false);
                showTost("Status has been successfully Changed", "success");

            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const deleteSupport = async (val) => {
        Swal.fire({
            title: "Are you sure want to delete ?",
            icon: "error",
            confirmButtonText: "Delete",
            confirmButtonColor: "#FD711A",
            showCancelButton: true,
            cancelButtonColor: "#0F3E6A",
        }).then(async ({ isConfirmed }) => {
            if (isConfirmed) {
                await Get(
                    `support/deleteSupport/${val}`
                )
                    .then(res => {
                        if (res.success == false) {
                            showTost(res.msg, "warning");
                        } else {
                            getpost(true);
                        }
                    })
                    .catch((err) => {
                        showTost("SOMETHING WENT WRONG ON SERVER", "error");
                    });
            }
        });
    };
    const [displayuser, setdisplayuser] = useState("");
    const [nextaction, setnextaction] = useState(false);
    const [formkey, setFormKey] = useState(Math.random() * 1000000);
    const [Next, setNext] = useState([]);
    const [initValuenext, setinitValuenext] = useState({
        date: "",
        note: "",
    });
    const genrateKeys = () => {
        setFormKey(Math.random() * 1000000);
    }
    const savetonext = async (val) => {
        setsupport(val);
        Get(`support/getNext/${val}`)
            .then((res) => {
                setNext(res.data);
                getpost(true);
                genrateKeys();
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }

    const [pdfBlob, setPdfBlob] = useState(null);
    const [pdfmodal, setPdfModal] = useState(false);
    const [downloadSpinner, setDownloadSpinner] = useState(false);
    const [print, setprint] = useState(false);
    const [template1, settemplate] = useState([]);
    const [inittemplateValue, setinittemplateValue] = useState({
        template: "",
    });
    const download = async (val) => {
        setDownloadSpinner(true);
        await FileBlob(`support/Supportpdfcreate`, { id: support, template_id: val.template }, "Support.pdf")
            .then((resblob) => {
                setDownloadSpinner(false);
                setPdfModal(true);
                setprint(false);
                setTimeout(() => {
                    setPdfBlob(resblob);
                }, 2000);
            })
            .catch((err) => {
                setDownloadSpinner(false);
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    return (
        <div className='content-wrapper'>
            <div>
                <div className="my-2 row">
                    <div className="col-2">
                        <h2>Support</h2>
                    </div>
                    <div className="col-10">
                        <div className="float-right">
                            <div className='d-flex mb-2'>


                                <ReactHTMLTableToExcel
                                    id="test-table-xls-button"
                                    className="download-table-xls-button btn btn-sm btn-warning mr-2"
                                    table="table-to-xls"
                                    title="Export to Excel"
                                    filename="Support Tickets"
                                    sheet="tablexls"
                                    buttonText={<i className="fa fa-file-excel-o" />}
                                />
                                <div onClick={() => navigate(`/Supportadd/0`, { state: lastTicketCode })}>
                                    {/* <Link to={`/Supportadd/0`}> */}
                                    <span className='btn btn-sm btn-primary mr-2'>
                                        {/* <span className='btn btn-sm btn-primary mr-2' onClick={() => { setsupport(0); }}> */}
                                        <i className="fa fa-plus mr-2" /> Add Support
                                    </span>
                                    {/* </Link> */}
                                </div>
                                <span>
                                    <ButtonGroup>
                                        <Btn size="sm" color={isGridView ? "outline-info" : "info"} onClick={() => setIsGridView(false)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-grid"><rect x="3" y="3" width="7" height="7"></rect><rect x="14" y="3" width="7" height="7"></rect><rect x="14" y="14" width="7" height="7"></rect><rect x="3" y="14" width="7" height="7"></rect></svg>
                                        </Btn>
                                        <Btn size="sm" color={!isGridView ? "outline-info" : "info"} onClick={() => setIsGridView(true)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-list"><line x1="8" y1="6" x2="21" y2="6"></line><line x1="8" y1="12" x2="21" y2="12"></line><line x1="8" y1="18" x2="21" y2="18"></line><line x1="3" y1="6" x2="3" y2="6"></line><line x1="3" y1="12" x2="3" y2="12"></line><line x1="3" y1="18" x2="3" y2="18"></line></svg>
                                        </Btn>
                                    </ButtonGroup>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="float-right">
                            <div className="d-flex">
                                <div className='mr-2'>
                                    <input type="text" placeholder="Search Company Name" onKeyPress={(event) => {
                                        if (event.nativeEvent.key === 'Enter') {
                                            setPageNo(1);
                                            setfiltername(event.target.value)
                                        }
                                    }} className="form-control mr-2 w-auto" />
                                </div>
                                <div className='mr-2'>
                                    <select className="form-select" onChange={(e) => setfilterstatus(e.target.value)}>
                                    <option value="">All</option>
                                        {Status
                                            .map((x, i) => (
                                                <option key={i} value={x._id}>{x.Name}</option>
                                            ))}
                                    </select>
                                </div>
                                <div>
                                    <select className="form-select" onChange={(e) => setfiltersales(e.target.value)}>
                                        <option value="">Select Sales</option>
                                        {sales
                                            .map((x, i) => (
                                                <option key={i} value={x._id}>{x.name}</option>
                                            ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='bookmark-tabcontent mt-4'>
                    <div className={"details-bookmark text-center " + (isGridView ? "list-bookmark" : '')} id="list-bookmark1">
                        <div className="row" id="bookmarkData">
                            {data.length == 0 ? <div className="mt-5">
                                No Record Found
                            </div> : data.map(item => (
                                <div className="col-xl-3 col-md-4 xl-50">
                                <div className="card card-with-border bookmark-card o-hidden">
                                        <div className="details-website">
                                            <div className="desciption-data">
                                                <div className="title-bookmark">
                                                    <div className='row mb-3'>
                                                        <div className='col-md-8'>
                                                            <h6><i className="fa fa-building mr-2" />{item.Customer?.Company?.Company}({item.Customer?.Branch?.Name})</h6>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <div className="d-flex justify-content-end">
                                                                <div>
                                                                    <span className='ml-auto'>{item.is_active == false ? "" :
                                                                        <>
                                                                            <i className='fa fa-print btn btn-success btn-sm rounded-circle mr-1' onClick={() => { setsupport(item._id); setprint(true); }} />
                                                                            <Link to={`/Supportadd/${item._id}`} >
                                                                                <i className='btn btn-sm btn-success fa fa-pencil mr-1'></i>
                                                                            </Link>
                                                                            <i className='btn btn-sm btn-danger fa fa-trash' onClick={() => { deleteSupport(item._id); }} />
                                                                        </>
                                                                    }</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-1">
                                                        <h6 className='col-4 font-weight-normal f-13 text-secondary'>Name </h6>
                                                        <h6 className="col-8 font-weight-normal f-13 text-capitalize text-dark">{item.Customer?.FirstName} {item.Customer?.LastName} </h6>
                                                    </div>
                                                    <div className="row mb-1">
                                                        <h6 className='col-4 font-weight-normal f-13 text-secondary'>Product Name </h6>
                                                        <h6 className="col-8 font-weight-normal f-13 text-capitalize text-dark">{item.Products?.Name} </h6>
                                                    </div>
                                                    <div className="row mb-1">
                                                        {item.NextTalk !== null ?
                                                            <>
                                                                <h6 className='col-4 font-weight-normal f-13 text-secondary'>Last Comment </h6>
                                                                <h6 className="col-8 font-weight-normal f-13 text-capitalize text-dark">{item.NextTalk?.note} </h6>
                                                            </>
                                                            : ""}
                                                    </div>
                                                    <div className="row mb-1">
                                                        <h6 className='col-4 font-weight-normal f-13 text-secondary'>Ticket Code </h6>
                                                        <h6 className="col-8 font-weight-normal f-13 text-capitalize text-dark">{item.TicketCode} </h6>
                                                    </div>
                                                    <div className="row mb-1">
                                                        <h6 className='col-4 font-weight-normal f-13 text-secondary'>Status </h6>
                                                        <div className="col-8 font-weight-normal f-13 text-capitalize">
                                                            <div className='badge-light badge'>
                                                                {item.Status?.Name}
                                                                <Link className='text-success' title="Change Status" onClick={() => { { getbyid(item._id); } }}>
                                                                    <i class="fa fa-pencil ml-2"></i>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            ))}
                        </div>
                        {data.length == 0 ? "" :
                            <Pagination
                                activePage={pageNo}
                                itemsCountPerPage={perPage}
                                totalItemsCount={totalCount}
                                pageRangeDisplayed={5}
                                linkclassName={"page-link"}
                                onChange={NexPage}
                                itemClass={"page-item"}
                                linkClass={"page-link"}
                            />
                        }
                    </div>
                </div>
            </div>
            <table id="table-to-xls" style={{ display: 'none' }}>
                <thead>
                    <tr>
                        <th>TicketNo</th>
                        <th>Company Name</th>
                        <th>Branch Name</th>
                        <th>Customer Name</th>
                        <th>Sales</th>
                        <th>Status</th>
                        <th>item</th>
                        <th>rate</th>
                        <th>Order Qty</th>
                        <th>Order Date</th>
                        <th>Due Date</th>
                        <th>Seller Note</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map(item => (
                        <tr>
                            <td>{item.TicketCode}</td>
                            <td>{item.Customer?.Company?.Company}</td>
                            <td>{item.Customer?.Branch?.Name}</td>
                            <td>{item.Customer?.FirstName} {item.Customer?.LastName}</td>
                            <td>{item.Sales?.name}</td>
                            <td>{item.Status?.Name}</td>
                            <td>{item.Products?.Name}</td>
                            <td>{item.Price}</td>
                            <td>{item.Qty}</td>
                            <td>{moment(item.TicketDate).format("DD-MMM-YYYY")}</td>
                            <td>{moment(item.DueDate).format("DD-MMM-YYYY")}</td>
                            <td>{item.Note}</td>
                            <td>{item.Price * item.Qty}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <CModal alignment="center" visible={print}>
                <div className="modal-header">
                    <h4 className="modal-title">Select Template For Support</h4>
                    <button type="button" className="close" onClick={() => setprint(false)}>&times;</button>
                </div>
                <div className="modal-body">
                    <Formik
                        onSubmit={download}
                        initialValues={inittemplateValue}
                        validationSchema={templatevalidation}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            touched,
                            errors,
                            setFieldValue,
                        }) => {
                            return (
                                <Form
                                    onSubmit={handleSubmit}
                                    onChange={handleChange}>
                                    <div className="col-12 mb-1">
                                        <select
                                            defaultValue={values.template}
                                            className={!!touched.template && !!errors.template ? " form-select is-invalid" : " form-select"}
                                            name='template'>
                                            {template1.map((x, i) => {
                                                return (
                                                    <option value={x._id}>{x.Name}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    <div className='col-12 mt-2'>
                                        {downloadSpinner ?
                                            <div className={`loader-box ${styles.DownloadQuoteLoader}`}>
                                                <Spinner className={'loader-7'} />
                                            </div>
                                            :
                                            <button type="submit" className='btn btn-sm font-weight-bold btn-success'>
                                                <i className="fa fa-check" /> Save
                                            </button>
                                        }
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>
                </div>
            </CModal >
            <CModal alignment="center" visible={pdfmodal} size='lg'>
                <div className="modal-header">
                    <h4 className="modal-title">Viewer</h4>
                    <button type="button" className="close" onClick={() => { setPdfModal(false); }}>&times;</button>
                </div>
                <div className="modal-body" style={{ marginLeft: '75px' }}>
                    <div>
                        {pdfBlob && <PDFViewer blob={pdfBlob} />}
                        {!pdfBlob && <p>Loading...</p>}
                    </div>
                </div>
            </CModal>
            <CModal alignment="center" visible={changestatus}>
                <div className="modal-header">
                    <h4 className="modal-title">Change Status</h4>
                    <button type="button" className="close" onClick={() => { setchangestatus(false) }}>&times;</button>
                </div>
                <div className="modal-body">
                    <Formik
                        onSubmit={statuschange}
                        initialValues={initststusValue}
                        validationSchema={statusvalidation}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            touched,
                            errors,
                            setFieldValue,
                        }) => {
                            return (
                                <Form
                                    onSubmit={handleSubmit}
                                    onChange={handleChange}>
                                    <div className="col-12 mb-1">
                                        <select
                                            defaultValue={values.status}
                                            className={!!touched.status && !!errors.status ? " form-select is-invalid" : " form-select"}
                                            name='status'>
                                            <option value="">Select Status</option>
                                            {Status
                                                .map((x, i) => (
                                                    <option key={i} value={x._id}>{x.Name}</option>
                                                ))}
                                        </select>
                                    </div>
                                    <div className='col-12 mt-2'>
                                        <button type="submit" className='btn btn-sm font-weight-bold btn-success'>
                                            <i className="fa fa-check" /> Save
                                        </button>
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>
                </div>
            </CModal>
        </div >
    );
}

export default Support;