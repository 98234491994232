import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { Post, Get, FileUpload } from '../../Layout/ajax';
import { CModal } from '@coreui/react';
import * as Yup from "yup";
import { immediateToast } from "izitoast-react";
import moment from "moment";
import { Link, useParams } from 'react-router-dom';
import { UserModal } from './Modal.js';

const validation = Yup.object().shape({
    name: Yup.string().required(),
    executive: Yup.string().required(),
    startDate: Yup.string().required(),
    dueDate: Yup.string().required(),
    // note: Yup.string().required(),
});
const subvalidation = Yup.object().shape({
    name: Yup.string().required(),
    executive: Yup.string().required(),
    startDate: Yup.string().required(),
    dueDate: Yup.string().required(),
    // note: Yup.string().required(),
});
const Dailyvalidation = Yup.object().shape({
    Date: Yup.string().required(),
    note: Yup.string().required(),
});
const Process = () => {

    const $ = window.$;
    const [initValue, setinitValue] = useState({
        name: "",
        executive: "",
        startDate: "",
        dueDate: "",
        note: "",
    });
    const [initsubValue, setinitsubValue] = useState({
        name: "",
        executive: "",
        startDate: "",
        dueDate: "",
        note: "",
    });
    const [initDailyValue, setinitDailyValue] = useState({
        progress: 0,
        Date: "",
        note: "",
    });
    const [data, setData] = useState([]);
    const [executive, setexecutive] = useState([]);
    const [dailydata, setdailydata] = useState([]);
    const [processadd, setprocessadd] = useState(false);
    const [Dailyadd, setDailyadd] = useState(false);
    const [subprocessadd, setsubprocessadd] = useState(false);
    const [useradd, setuseradd] = useState(false);
    const [subprocess, setsubprocess] = useState(0);
    const [process, setprocess] = useState(0);
    const [lastprogress, setlastprogress] = useState(0);
    const [userval, setuserval] = useState(0);
    const { id } = useParams();
    const showTost = (msg, theme) => {
        immediateToast(theme, {
            message: msg,
            timeout: 3000
        });
    }
    useEffect(() => {
        getpost();
        getuserpost();       
    }, []);
    const getuserpost = async () => {
        Post(`users/getAllUser`, { active: true })
            .then((res) => {
                setexecutive(res)
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const getpost = async () => {
        await Post(`contract/getAllProcess`, { contractId: id })
            .then((res) => {
                setData(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const get = async (val) => {
        if (val != 0) {
            Post(`contract/getProcessById`, { processId: val })
                .then((res) => {
                    setinitValue({
                        name: res.data[0].Name,
                        executive: res.data[0].executive,
                        startDate: moment(res.data[0].startDate).format("YYYY-MM-DD"),
                        dueDate: moment(res.data[0].dueDate).format("YYYY-MM-DD"),
                        note: res.data[0].note,
                    });
                    setprocessadd(true);
                    setprocess(val);
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
    }
    const subget = async (val) => {
        if (val != 0) {
            Post(`contract/getSubProcessById`, { subProcessId: val })
                .then((res) => {
                    setinitsubValue({
                        name: res.data[0].Name,
                        executive: res.data[0].executive,
                        startDate: moment(res.data[0].startDate).format("YYYY-MM-DD"),
                        dueDate: moment(res.data[0].dueDate).format("YYYY-MM-DD"),
                        note: res.data[0].note,
                    });
                    setsubprocessadd(true);
                    setsubprocess(val);
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
    }
    const clear = () => {
        setinitValue({
            name: "",
            executive: "",
            startDate: "",
            dueDate: "",
            note: "",
        });
    }
    const handleSubmit = async (val) => {
        val.contractId = id;
        if (process == 0) {
            await Post(`contract/addProcess`, val)
                .then((res) => {
                    if (res.success == true) {
                        clear();
                        getpost();
                        setprocessadd(false);
                        showTost("Your data has been Successfully added", "success");
                    }
                    else {
                        showTost(res.message, "warning");
                    }
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
        else {
            val.id = process;
            await Post(`contract/editProcess`, val)
                .then((res) => {
                    if (res.success == true) {
                        clear();
                        getpost();
                        setprocessadd(false);
                        showTost("Your data has been successfully updated", "success");
                    }
                    else {
                        showTost(res.message, "warning");
                    }
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
    };
    const subclear = () => {
        setinitsubValue({
            name: "",
            executive: "",
            startDate: "",
            dueDate: "",
            note: "",
        });
    }
    const handlesubSubmit = async (val) => {
        val.processId = process;
        if (subprocess == 0) {
            await Post(`contract/addSubProcess`, val)
                .then((res) => {
                    if (res.success == true) {
                        subclear();
                        getpost();
                        setsubprocessadd(false);
                        showTost("Your data has been Successfully added", "success");
                    }
                    else {
                        showTost(res.message, "warning");
                    }
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
        else {
            val.id = subprocess;
            await Post(`contract/editSubProcess`, val)
                .then((res) => {
                    if (res.success == true) {
                        subclear();
                        getpost();
                        setsubprocessadd(false);
                        showTost("Your data has been successfully updated", "success");
                    }
                    else {
                        showTost(res.message, "warning");
                    }
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
    };
    const getdailypost = async (val) => {
        await Get(`contract/getAllDailyStatus/${val}`)
            .then((res) => {
                setdailydata(res.data);
                setsubprocess(val);
                if (res.data.length != 0) {
                    setlastprogress(res.data[0].progress);
                }
                setDailyadd(true);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const Dailyclear = () => {
        setinitDailyValue({
            progress: 0,
            Date: "",
            note: "",
        });
    }
    const handleDailySubmit = async (val) => {
        val.subProcessId = subprocess;
        await Post(`contract/addDailyStatus`, val)
            .then((res) => {
                if (res.success == true) {
                    Dailyclear();
                    getpost();
                    setDailyadd(false);
                    showTost("Your data has been Successfully added", "success");
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    return (
        <div className='content-wrapper'>
            <div className="row">
                <div className="mb-2 mt-2 row col-12">
                    <div className="col-10">
                        <Link to={'/Projects'}><i className="fa fa-arrow-left text-dark" /></Link>
                        <h2>Project Process</h2>
                    </div>
                    <div className="col-2 mt-2">
                        <span className='btn btn-sm btn-primary float-right' onClick={() => { setprocessadd(true); setprocess(0); }}>
                            <i className="fa fa-plus mr-2" /> Add Process
                        </span>
                    </div>
                </div>
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Executive</th>
                                    <th scope="col">Start Date</th>
                                    <th scope="col">Due Date</th>
                                    <th scope="col">Progress (%)</th>
                                    <th scope="col">Note</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((x, i) => <>
                                    <tr className='bg-light'>
                                        <td>{x.Name}</td>
                                        <td>{x.executive?.name}</td>
                                        <td>{moment(x.startDate).format("DD-MM-YYYY")}</td>
                                        <td>{moment(x.dueDate).format("DD-MM-YYYY")}</td>
                                        <td>
                                            <p className='m-0'>{x.progress}% </p>
                                            <div className="progress" style={{ height: '5px', width: "80px" }}>
                                                <div className="bg-primary progress-bar-striped" role="progressbar" aria-valuenow={x.progress} aria-valuemin={0} aria-valuemax={100} style={{ width: `${x.progress}%` }} />
                                            </div>
                                        </td>
                                        <td>{x.note}</td>
                                        <td className='input-group'>
                                            <i className='btn btn-sm btn-success fa fa-pencil mr-2' onClick={() => { get(x._id); }} />
                                            <span className='btn btn-sm btn-primary mr-2' onClick={() => { setsubprocessadd(true); setsubprocess(0);setprocess(x._id); }}>
                                                Add SubProcess
                                            </span>
                                            <div className={`hide${x._id}`}>
                                                <span className="icon icon-plus mt-1 fa fa-plus" onClick={() => { $(`.hide${x._id}`).hide(); $(`.show${x._id}`).show(); setprocess(x._id); }} />
                                            </div>
                                            <div className={`show${x._id}`} style={{ display: "none" }}>
                                                <span className="icon icon-minus mt-1 fa fa-minus" onClick={() => { $(`.hide${x._id}`).show(); $(`.show${x._id}`).hide(); }} />
                                            </div>
                                        </td>
                                    </tr>
                                    {x?.subProcess.map((f, i) =>
                                        <tr className={`show${x._id}`} style={{ display: "none" }}>
                                            <td>{f.Name}</td>
                                            <td>{f.executive?.name}</td>
                                            <td>{moment(f.startDate).format("DD-MMM")}</td>
                                            <td>{moment(f.dueDate).format("DD-MMM")}</td>
                                            <td><p className='m-0'>{f.progress}% </p>
                                                <div className="progress" style={{ height: '5px', width: "80px" }}>
                                                    <div className="bg-primary progress-bar-striped" role="progressbar" aria-valuenow={f.progress} aria-valuemin={0} aria-valuemax={100} style={{ width: `${f.progress}%` }} />
                                                </div>
                                            </td>
                                            <td>{f.note}</td>
                                            <td><i className='btn btn-sm btn-success fa fa-pencil mr-2' onClick={() => { subget(f._id); }} /><span className='btn btn-sm btn-primary' onClick={() => { getdailypost(f._id); }}>Daily Satus</span></td>
                                        </tr>
                                    )}
                                </>
                                )}
                            </tbody>
                        </table>
                    </div>
            </div>
            <CModal alignment="center" size="lg" visible={subprocessadd}>
                <div className="modal-header">
                    <h4 className="modal-title">{subprocess == 0 ? "Add SubProcess" : "Edit SubProcess"}</h4>
                    <button type="button" className="close" onClick={() => { subclear(); setsubprocessadd(false) }}>&times;</button>
                </div>
                <div className="modal-body">
                    <Formik
                        onSubmit={handlesubSubmit}
                        initialValues={initsubValue}
                        validationSchema={subvalidation}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            touched,
                            errors,
                            setFieldValue,
                        }) => {
                            return (
                                <Form
                                    onSubmit={handleSubmit}
                                    onChange={handleChange}>
                                    <div className="col-12 mb-2">
                                        <div className="row">
                                            <div className='mt-2 col-6'>
                                                <label>Name</label>
                                                <input type='text' name="name" defaultValue={values.name} placeholder='Enter Name' className={!!touched.name && !!errors.name ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className='mt-2 col-6'>
                                                <label>Executive</label>
                                                <div className='input-group'>
                                                    <select defaultValue={values.executive} className={!!touched.executive && !!errors.executive ? "form-select is-invalid" : "form-select"} name='executive'>
                                                        <option value=''>Select Executive</option>
                                                        {executive
                                                            .map((x, i) => (
                                                                <option key={i} value={x._id}>{x.name}</option>
                                                            ))}
                                                    </select>
                                                    <span className="input-group-text">
                                                        {values.executive ? (
                                                            <i className='fa fa-pencil' onClick={() => { setuserval(values.executive); setuseradd(true) }} />
                                                        ) : (
                                                            <i className='fa fa-plus' onClick={() => { setuserval(0); setuseradd(true) }} />
                                                        )}
                                                        {useradd && (
                                                            <UserModal
                                                                useradd={useradd}
                                                                setuseradd={setuseradd}
                                                                getuserpost={getuserpost}
                                                                showTost={showTost}
                                                                userval={userval}
                                                                setuserval={setuserval}
                                                            />
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-6 mt-2">
                                                <label><i className="fa fa-calendar text-warning mr-2" />Start Date</label>
                                                <input type="date" placeholder="Start Date" defaultValue={values.startDate} name='startDate' className={!!touched.startDate && !!errors.startDate ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className="col-6 mt-2">
                                                <label><i className="fa fa-calendar text-warning mr-2" />Due Date</label>
                                                <input type="date" placeholder="Expiry Date" min={values.startDate} defaultValue={values.dueDate} name='dueDate' className={!!touched.dueDate && !!errors.dueDate ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className='mt-2 col-12'>
                                                <label>Note</label>
                                                <textarea type='text' rows="3" defaultValue={values.note} name="note" placeholder='Enter Note' className={!!touched.note && !!errors.note ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className='mt-2 col-12'>
                                                <button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </CModal>
            <CModal alignment="center" size="lg" visible={processadd}>
                <div className="modal-header">
                    <h4 className="modal-title">{process == 0 ? "Add Process" : "Edit Process"}</h4>
                    <button type="button" className="close" onClick={() => { clear(); setprocessadd(false) }}>&times;</button>
                </div>
                <div className="modal-body">
                    <Formik
                        onSubmit={handleSubmit}
                        initialValues={initValue}
                        validationSchema={validation}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            touched,
                            errors,
                            setFieldValue,
                        }) => {
                            return (
                                <Form
                                    onSubmit={handleSubmit}
                                    onChange={handleChange}>
                                    <div className="col-12 mb-2">
                                        <div className="row">
                                            <div className='mt-2 col-6'>
                                                <label>Name</label>
                                                <input type='text' name="name" defaultValue={values.name} placeholder='Enter Name' className={!!touched.name && !!errors.name ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className='mt-2 col-6'>
                                                <label>Executive</label>
                                                <div className='input-group'>
                                                    <select defaultValue={values.executive} className={!!touched.executive && !!errors.executive ? "form-select is-invalid" : "form-select"} name='executive'>
                                                        <option value=''>Select Executive</option>
                                                        {executive
                                                            .map((x, i) => (
                                                                <option key={i} value={x._id}>{x.name}</option>
                                                            ))}
                                                    </select>
                                                    <span className="input-group-text">
                                                        {values.executive ? (
                                                            <i className='fa fa-pencil' onClick={() => { setuserval(values.executive); setuseradd(true) }} />
                                                        ) : (
                                                            <i className='fa fa-plus' onClick={() => { setuserval(0); setuseradd(true) }} />
                                                        )}
                                                        {useradd && (
                                                            <UserModal
                                                                useradd={useradd}
                                                                setuseradd={setuseradd}
                                                                getuserpost={getuserpost}
                                                                showTost={showTost}
                                                                userval={userval}
                                                                setuserval={setuserval}
                                                            />
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-6 mt-2">
                                                <label><i className="fa fa-calendar text-warning mr-2" />Start Date</label>
                                                <input type="date" placeholder="Start Date" defaultValue={values.startDate} name='startDate' className={!!touched.startDate && !!errors.startDate ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className="col-6 mt-2">
                                                <label><i className="fa fa-calendar text-warning mr-2" />Due Date</label>
                                                <input type="date" placeholder="Expiry Date" min={values.startDate} defaultValue={values.dueDate} name='dueDate' className={!!touched.dueDate && !!errors.dueDate ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className='mt-2 col-12'>
                                                <label>Note</label>
                                                <textarea type='text' rows="3" defaultValue={values.note} name="note" placeholder='Enter Note' className={!!touched.note && !!errors.note ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className='mt-2 col-12'>
                                                <button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </CModal>
            <CModal alignment="center" size="lg" visible={Dailyadd}>
                <div className="modal-header">
                    <h4 className="modal-title">Add Daily Status</h4>
                    <button type="button" className="close" onClick={() => { Dailyclear(); setDailyadd(false) }}>&times;</button>
                </div>
                <div className="modal-body">
                    <Formik
                        onSubmit={handleDailySubmit}
                        initialValues={initDailyValue}
                        validationSchema={Dailyvalidation}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            touched,
                            errors,
                            setFieldValue,
                        }) => {
                            return (
                                <Form
                                    onSubmit={handleSubmit}
                                    onChange={handleChange}>
                                    <div className="col-12 mb-2">
                                        <div className="row">
                                            <div className="col-6 mt-2">
                                                <label><i className="fa fa-calendar text-warning mr-2" />Date</label>
                                                <input type="date" placeholder="Start Date" defaultValue={values.Date} name='Date' className={!!touched.Date && !!errors.Date ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className='mt-2 col-12'>
                                                <label>Progress ( {values.progress == 0 ? lastprogress : values.progress} %)</label>
                                            </div>
                                            <div className='mt-2 col-12'>
                                                <input type='range' name="progress" step='2' defaultValue={lastprogress} className={!!touched.progress && !!errors.progress ? "w-100 is-invalid" : "w-100"} />
                                            </div>
                                            <div className='mt-2 col-12'>
                                                <label>Note</label>
                                                <textarea type='text' rows="3" defaultValue={values.note} name="note" placeholder='Enter Note' className={!!touched.note && !!errors.note ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className='mt-2 col-12'>
                                                <button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Date</th>
                                                    <th scope="col">Progress (%)</th>
                                                    <th scope="col">Note</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {dailydata.map((x, i) =>
                                                    <tr className='bg-light'>
                                                        <td>{moment(x.Date).format("DD-MMM-YYYY")}</td>
                                                        <td>
                                                            <p className='m-0'>{x.progress}% </p>
                                                            <div className="progress" style={{ height: '5px', width: "80px" }}>
                                                                <div className="bg-primary progress-bar-striped" role="progressbar" aria-valuenow={x.progress} aria-valuemin={0} aria-valuemax={100} style={{ width: `${x.progress}%` }} />
                                                            </div>
                                                        </td>
                                                        <td>{x.note}</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </CModal>
        </div >
    );
}

export default Process;