import { Fragment, useEffect, useState } from "react";
import LeftHeader from "./LeftHeader";
import Switch from "./Switch";
import SearchBar from "./SearchBar";
import BellCompo from "./BellCompo";
import FullScreen from "./FullScreen";
import UserProfile from "./UserProfile";
import { MoreHorizontal, Settings } from "react-feather";
import { Col } from "reactstrap";
import { LI, UL } from "../../AbstractElements";
import DarkMode from "./DarkMode";
import { useDispatch } from 'react-redux';
import { useSelector } from "react-redux";
import { Post, Get } from '../../Layout/ajax';
import { immediateToast } from "izitoast-react";


const Header = () => {
   const  dispatch =useDispatch()
  const {toggleThemeCustomizer,toggleIcon  } = useSelector((state) => state.ThemeCustomizer);
  const [Manual, setManual] = useState('');

  const [toogleNav, setToogleNav] = useState(false);

  const showTost = (msg, theme) => {
    immediateToast(theme, {
      message: msg,
      timeout: 3000
    });
  }

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth <= 768) {
        dispatch({type:"setToggleIcon",payload:true})
      } else {
        dispatch({type:"setToggleIcon",payload:false})
      }
    });


  Get(`master/getApplicationSetting`)
  .then((res) => {
      setManual(res.data.CompanyTitle);
  })
  .catch((err) => {
      showTost("SOMETHING WENT WRONG ON SERVER", "error");
  });

}, [toggleIcon]);
  

  return (
    
    <Fragment>
      <div className={`page-main-header${toggleIcon ? " close_icon" : ""}`}>
        <div className="main-header-right ">
          <div className="row w-100 m-0">
            <div className="col-lg-2 col-md-3 col-6 p-0 mt-4 pt-4 mt-sm-0 pt-sm-0 order-1 order-md-1">
              <div className="d-flex align-items-center">
                <LeftHeader />
                <Switch />
              </div>
            </div>
            <div className="col-lg-7 col-md-8 col-12 p-0 text-sm-center order-3 order-md-2">
              <h4 className="custome"> {Manual} </h4>
            </div>
            <div className="col-lg-3 col-md-1 col-6 p-0 mt-4 pt-4 mt-sm-0 pt-sm-0 order-2 order-md-3">
              <Col className="nav-right  pull-right right-menu">
                <UL className={`nav-menus flex-row simple-list ${toogleNav ? "open" : ""} `}>
                  <BellCompo />
                  <FullScreen />
                  <LI className={"theme-setting"}>
                    <Settings
                      onClick={() => {
                        dispatch({ type: "setToggleThemeCustomizer", payload: !toggleThemeCustomizer });
                      }}
                    />
                  </LI>
                  <UserProfile />
                </UL>
              </Col>
              <div className="d-lg-none mobile-toggle pull-right">
                <MoreHorizontal onClick={() => setToogleNav(!toogleNav)} />
              </div>
            </div>
          </div>
          {/* <LeftHeader />
          <Switch />
          <h4 className="mx-md-5 custome"> {Manual} </h4>
          <Col className="nav-right  pull-right right-menu">
            <UL className={`nav-menus flex-row simple-list ${toogleNav ? "open" : ""} `}> */}
          {/* <SearchBar /> */}
          {/* <DarkMode/> */}
          {/* <BellCompo />
              <FullScreen />
              <LI className={"theme-setting"}>
                <Settings
                  onClick={() => {
                    dispatch({ type: "setToggleThemeCustomizer", payload: !toggleThemeCustomizer });
                  }}
                />
              </LI>
              <UserProfile />
            </UL>
          </Col>
          <div className="d-lg-none mobile-toggle pull-right">
            <MoreHorizontal onClick={() => setToogleNav(!toogleNav)} />
          </div> */}
        </div>
      </div>
    </Fragment>
  );
};

export default Header;
