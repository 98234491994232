import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { Post, Get } from '../../Layout/ajax';
import { CModal } from '@coreui/react';
import { immediateToast } from "izitoast-react";
import * as Yup from "yup";
import Swal from "sweetalert2";

const validation = Yup.object().shape({
    name: Yup.string().required(),
    color: Yup.string().required()
});
const Icon = () => {
    const [initValue, setinitValue] = useState({
        name: "",
        color: ""
    });
    const [data, setData] = useState([]);
    const [iconadd, seticonadd] = useState(false);
    const [icon, seticon] = useState(0);
    useEffect(() => {
        getpost(true);
    }, []);
    const showTost = (msg, theme) => {
        immediateToast(theme, {
            message: msg,
            timeout: 3000
        });
    }
    const getpost = async (val) => {
        await Post(`master/getIcon`, { active: val })
            .then((res) => {
                setData(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const handleSubmit = async (val) => {
        await Post(`master/addIcon`, val)
            .then((res) => {
                if (res.success == true) {
                    clear();
                    seticonadd(false);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            getpost(true);
                        }
                    });
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const handleEdit = async (val) => {
        val.id = icon;
        await Post(`master/editIcon`, val)
            .then((res) => {
                if (res.success == true) {
                    clear();
                    seticonadd(false);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            getpost(true);
                        }
                    });
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const get = async (val) => {
        if (val != 0) {
            Get(`master/icon/${val}`)
                .then((res) => {
                    setinitValue({
                        name: res.data.Name,
                        color: res.data.Color
                    });
                    seticonadd(true);
                    seticon(val);
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
    }
    const deleteIcon = async (val) => {
        Swal.fire({
            title: "Are you sure want to delete ?",
            icon: "error",
            confirmButtonText: "Delete",
            confirmButtonColor: "#FD711A",
            showCancelButton: true,
            cancelButtonColor: "#0F3E6A",
        }).then(async ({ isConfirmed }) => {
            if (isConfirmed) {
                await Get(
                    `master/deleteIcon/${val}`
                )
                    .then(res => {
                        if (res.success == false) {
                            showTost(res.msg, "warning");
                        } else {
                            getpost(true);
                        }
                    })
                    .catch((err) => {
                        showTost("SOMETHING WENT WRONG ON SERVER", "error");
                    });
            }
        });
    };
    const changeStatus = async (val, status) => {
        await Post(`master/changeIconStatus`, { id: val, active: status })
            .then((res) => {
                if (res.success == true) {
                    if (status == true) {
                        getpost(false);
                        showTost("Your data has been successfully activated", "success");
                    }
                    else {
                        getpost(true);
                        showTost("Your data has been successfully deactivated", "success");
                    }
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const clear = () => {
        setinitValue({
            name: "",
            color: ""
        });
    }
    const columns = [
        {
            name: "Name",
            selector: (row) => row.Name,
        },
        {
            name: "Color",
            selector: (row) => <div class="btn btn-square btn-lg" style={{ backgroundColor: row.Color }}></div>,
        },
        {
            name: "Status",
            selector: (row) => <input type='checkbox' value={row.is_active} checked={row.is_active == true ? "checked" : ""} onClick={() => { row.is_active == true ? changeStatus(row._id, false) : changeStatus(row._id, true) }} />,
        },
        {
            name: "",
            selector: (row) => row.is_active == true ? <><i className='btn btn-sm btn-success fa fa-pencil mr-1' onClick={() => { get(row._id); }} /><i className='btn btn-sm btn-danger fa fa-trash' onClick={() => { deleteIcon(row._id); }} /></> : "",
        }
    ]
    return (
        <div className='content-wrapper'>
            <div className="mb-2 mt-2 row">
                <div className="col-10">
                    <h2>Icon</h2>
                </div>
                <div className="col-2 mt-2">
                    <span className='btn btn-sm btn-warning float-right' onClick={() => { seticonadd(true); seticon(0); }}>
                        <i className="fa fa-plus mr-2" />
                        Add Icon
                    </span>
                </div>
                <div className="col-12">
                    <div className="custom-control custom-switch float-right">
                        <input type="checkbox" className="custom-control-input" defaultChecked id="customSwitch1" data-check_true={true} data-check_false={false} onChange={(event) => {
                            let ele = event.target;
                            let selectedId = ele.checked ? event.target.getAttribute("data-check_true") : event.target.getAttribute("data-check_false");
                            getpost(selectedId)
                        }} />
                        <label className="custom-control-label" for="customSwitch1"> Active/Inactive </label>
                    </div>
                </div>
            </div>
            <div className="row ">
                <div className="col-12 mt-3">
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                    />
                </div>
            </div>
            <CModal alignment="center" visible={iconadd}>
                <div className="modal-header">
                    <h4 className="modal-title">{icon == 0 ? "Add Icon" : "Update Icon"}</h4>
                    <button type="button" className="close" onClick={() => { clear(); seticonadd(false) }}>&times;</button>
                </div>
                <div className="modal-body">
                    <Formik
                        onSubmit={icon == 0 ? handleSubmit : handleEdit}
                        initialValues={initValue}
                        validationSchema={validation}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            touched,
                            errors,
                            setFieldValue,
                        }) => {
                            return (
                                <Form
                                    onSubmit={handleSubmit}
                                    onChange={handleChange}>
                                    <div className='mt-2'>
                                        <label>Icon Name</label>
                                        <input type='text' name="name" defaultValue={values.name} placeholder='Enter Icon Name' className={!!touched.name && !!errors.name ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='mt-2'>
                                        <label>Color</label>
                                        <input type='color' name="color" defaultValue={values.color} placeholder='Enter Color' className={!!touched.color && !!errors.color ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='mt-2'>
                                        <button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </CModal>
        </div>
    )
}

export default Icon
